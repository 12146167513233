import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class AuthService {
  private SWNW_URL = `${environment.API_ENDPOINT}/${environment.MOBILE_PATH}`;

  //* store the URL so we can redirect after logging in
  redirectUrl: string | null = null;
  
  constructor(private http: HttpClient) {}
  
  checkDuplicatePhoneNums(payload:any):Observable<any>{//38
    return this.http.post(`${this.SWNW_URL}/checkduplicatephonenumber`,payload);
  }

  checkAgentReferralCode(payload:any):Observable<any>{//42
    return this.http.post(`${this.SWNW_URL}/checkagentreferralcodecorrect`,payload);
  }

  checkAgentReferralCodeOnProfile(payload:any):Observable<any>{//43
      return this.http.post(`${this.SWNW_URL}/checkagentreferralcodecorrectonprofile`,payload);
  }

  register(payload:any):Observable<any>{//2
    return this.http.post(`${this.SWNW_URL}/registration`, payload);
  }

  login(payload: any): Observable<any> {//1
    return this.http.post(
      `${this.SWNW_URL}/login`,
      payload,
    );
  }

  logout(): Observable<any> {//7
    return this.http.post(`${this.SWNW_URL}/logout`, null);
  }

  //
  sendOtp(payload: any):Observable<any> {//61
    return this.http.post(`${this.SWNW_URL}/sendotp`, payload);
  }

  verifyAndRegister(payload: any):Observable<any> {//62
    return this.http.post(`${this.SWNW_URL}/verifyandregister`, payload);
  }

  verifyOtp(payload: any):Observable<any> {//63
    return this.http.post(`${this.SWNW_URL}/verifyotp`, payload);
  }

  verifyOtpForgetPin(payload:any):Observable<any> {
    return this.http.post(`${this.SWNW_URL}/verifyotpforgetpassword`, payload);
  }

  checkInvitationLink(payload:any):Observable<any>{
    return this.http.post(`${this.SWNW_URL}/checkinvitationlink`, payload);
  }
  
  verifyForLogin(payload: any):Observable<any> {//79
    return this.http.post(`${this.SWNW_URL}/verifyotpforlogin`, payload);
  }

  prelogin(payload: any):Observable<any> {//80
    return this.http.post(`${this.SWNW_URL}/prelogin`, payload);
  }

  verifyPINForWithdraw(payload: any):Observable<any> {//81
    return this.http.post(`${this.SWNW_URL}/verifypinforwithdraw`, payload);
  }
}
