<div class="this-container">
    <!-- to comment when Sysytem Maintain! -->
    <ng-container *ngIf="editMode;else NotEditMode">
        <ng-container *ngIf="profileInfo;else NoProfileInfo">
            <div>
                <div class="profile-photo-container">
                    <ng-container *ngIf="profileInfo.image; else NoProfileImg">
                        <img [src]="profileInfo.image" class="my-profile-img" alt="profile-img">
                    </ng-container>

                    <ng-template #NoProfileImg>
                        <img src="../../../../assets/imgs/profile-and-language/v2/no-loggedin-def-img.svg" 
                            class="default-not-loggedin-profile-img"
                            alt="not-login-profile-image">
                    </ng-template>

                    <span *ngIf="profileInfo.isvipuser === 1" class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-danger vip-badge">
                        VIP
                    </span>
                </div>

                <div class="profile-name-and-phone-container">
                    <label class="profile-name">{{profileInfo?.username}}</label>

                    <div class="profile-phone-num-div">
                        <label class="profile-phone-num">{{onlyLast4DigitsPhoneNum}}</label>
                    </div>
                    <div class="profile-createddate">{{'created-lbl'|translate}}: {{profileInfo.createddate}}</div>
                </div>

                <div>
                    <button class="btn btn-default" (click)="onEditProfile()">{{'edit-profile-title' | translate}} >></button>
                </div>
            </div>
        </ng-container>
        <ng-template #NoProfileInfo>
            <div class="profile-panel clickable-section">
                <div class="d-flex align-items-center">
                    <div class="p-2" style="padding-left: 0!important;">
                        <img src="../../../../assets/imgs/profile-and-language/v2/no-loggedin-def-img.svg" 
                        class="default-not-loggedin-profile-img"
                        alt="not-login-profile-image">
                    </div>
                    <div class="p-2 flex-grow-1" (click)="goLogin()">
                        <div class="user-info">
                            <label>{{'login-title'|translate}}</label>
                        </div>
                    </div>

                    <div class="p-2" style="padding-right: 0!important;">
                        <img [src]="selectedLangFlag" class="profile-panel-language-flag"
                        alt="selected-language" 
                        (click)="gotoChangeLanguage()">
                    </div>
                    
                </div>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #NotEditMode>
        <ng-container *ngIf="profileInfo;else NoProfileInfoNotEditMode">
            <div class="profile-panel clickable-section">
                <div class="d-flex align-items-center cursor-pointer-el">
                    <div class="p-2" (click)="goProfile()" style="position: relative;padding-left: 0!important;">
                        
                        <ng-container *ngIf="profileInfo.image;else noProfileImgNotEditMode">
                            <img [src]="profileInfo.image" class="my-profile-img-not-edit-mode" alt="profile-img">
                        </ng-container>

                        <ng-template #noProfileImgNotEditMode>
                            <img src="../../../../assets/imgs/profile-and-language/v2/no-loggedin-def-img.svg" 
                            class="default-not-loggedin-profile-img"
                            alt="not-login-profile-image">
                        </ng-template>
                        
                        <span *ngIf="profileInfo.isvipuser === 1" class="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-danger" style="bottom: -7px !important;outline: 2px solid white;">
                            VIP
                        </span>
                    </div>

                    <div class="p-2 flex-grow-1" style="padding-left: 20px !important;" (click)="goProfile()">
                        <div class="user-info">
                            <div>{{profileInfo.username}}</div>
                            <div>{{onlyLast4DigitsPhoneNum}}</div>
                            <div style="word-break: break-word;">{{'created-lbl'|translate}}: {{profileInfo.createddate}}</div>
                        </div>
                    </div>

                    <ng-container *ngIf="!profileInfo">
                        <div class="p-2 flex-grow-1" (click)="goLogin()">
                            <div class="user-info">
                                <div>{{'login-title'|translate}}</div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="p-2" style="padding-right: 0!important;">
                        <img [src]="selectedLangFlag" class="profile-panel-language-flag"
                        alt="selected-language" 
                        (click)="gotoChangeLanguage()">
                    </div>
                </div>
            </div>
            <!-- end comment when Sysytem Maintain! -->
        </ng-container>
        <ng-template #NoProfileInfoNotEditMode>
            <ng-container *ngIf="!isSplashScreenShowing">
                <div class="profile-panel clickable-section">
                    <div class="d-flex align-items-center cursor-pointer-el">
                        <div class="p-2" (click)="goProfile()" style="position: relative;padding-left: 0!important;">
                            <img src="../../../../assets/imgs/profile-and-language/v2/no-loggedin-def-img.svg" 
                                class="default-not-loggedin-profile-img"
                                alt="not-login-profile-image">
                        </div>
    
                        <div class="p-2 flex-grow-1" (click)="goLogin()">
                            <div class="user-info">
                                <div>{{'login-title'|translate}}</div>
                            </div>
                        </div>
    
                        <div class="p-2" style="padding-right: 0!important;">
                            <img [src]="selectedLangFlag" class="profile-panel-language-flag"
                            alt="selected-language" 
                            (click)="gotoChangeLanguage()">
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </ng-template>
</div>
