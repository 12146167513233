import { 
  Component, 
  OnChanges,
  OnInit, 
  AfterViewInit,
  OnDestroy, 
  Input, 
  Output, 
  ViewChild, 
  ElementRef, 
  EventEmitter,
  SimpleChanges, 
} from "@angular/core";

import KeenSlider, { KeenSliderInstance } from "keen-slider";

import { LocalStorageService, CryptoJsService } from "src/app/@core/utils";

@Component({
    selector:'keen-slider',
    templateUrl:'./keen-slider.component.html',
    styleUrls:['./keen-slider.component.scss']
})
export class KeenSliderComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy{
  @Input() images:any;
  @Input() targetName:string|any;

  @Input() twodRounds:any;
  @Input() Is2dRounds:any;
  @Output() twoDRoundEventEmit = new EventEmitter();
  @Output() twoDRoundWinnersTableEventEmit = new EventEmitter();

  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>|any;
  slider: KeenSliderInstance|any = null;
  
  isShow:boolean=false;

  activeRoundButton:string|any;

  toid1:any;
  toid2:any;

  timeout:any;

  constructor(
    private localService:LocalStorageService, 
    private cryptoService:CryptoJsService,
  ){
    this.activeRoundButton = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.twodRounds){
      this.activeRoundButton = this.twodRounds.length;
    }
  }
  
  ngOnInit(): void {
    const t = this.localService.getHomePromoDefaultImgs();
    if(t){

      ((JSON.parse(this.cryptoService.decrypt(t)))?.filter((x:any)=>x.name===this.targetName))?.forEach((el:any) => {
        this.images.push(el)
      }); 
    }
  }
  
  ngAfterViewInit(): void {
    this.toid1 = setTimeout(() => {
      this.isShow = true;
      this.twoDRoundWinnersTableEventEmit.emit(true);
    }, 3000);

    this.toid2 = setTimeout(() => {
      if(this.twodRounds && this.twodRounds.length > 0) {
        this.slider = new KeenSlider(
          this.sliderRef.nativeElement,
          {
            loop: false,
            mode: "free-snap",
            slides: {
              origin: "center",
              perView: this.twodRounds.length > 1 ? 2.5 : 1,
              spacing: 2,
            },
            initial:(this.twodRounds.length - 1),
          },
          [
            (slider) => {
              let mouseOver = false
              let clearNextTimeout = () => {
                clearTimeout(this.timeout)
              }
              let nextTimeout = () => {
                clearTimeout(this.timeout)
                if (mouseOver) return
                this.timeout = setTimeout(() => {
                  slider.next()
                }, 2500)
              }
              slider.on("created", () => {
                slider.container.addEventListener("mouseover", () => {
                  mouseOver = true
                  clearNextTimeout()
                })
                slider.container.addEventListener("mouseout", () => {
                  mouseOver = false
                  nextTimeout()
                })
                nextTimeout()
              })
              slider.on("dragStarted", clearNextTimeout)
              slider.on("animationEnded", nextTimeout)
              slider.on("updated", nextTimeout)
            },
          ]
        );
      } else {
        this.slider = new KeenSlider(
          this.sliderRef.nativeElement,
          {
            loop: true,
          },
          [
            (slider) => {
              let mouseOver = false
              let clearNextTimeout = () => {
                clearTimeout(this.timeout)
              }
              let nextTimeout = () => {
                clearTimeout(this.timeout)
                if (mouseOver) return
                this.timeout = setTimeout(() => {
                  slider.next()
                }, 2500)
              }
              slider.on("created", () => {
                slider.container.addEventListener("mouseover", () => {
                  mouseOver = true
                  clearNextTimeout()
                })
                slider.container.addEventListener("mouseout", () => {
                  mouseOver = false
                  nextTimeout()
                })
                nextTimeout()
              })
              slider.on("dragStarted", clearNextTimeout)
              slider.on("animationEnded", nextTimeout)
              slider.on("updated", nextTimeout)
            },
          ]
        );
      }
    }, 3000);
  }

  ngOnDestroy(): void {
    if (this.slider) this.slider.destroy();

    if(this.toid1) { clearTimeout(this.toid1)}
    if(this.toid2) { clearTimeout(this.toid2)}
    if(this.timeout) { clearTimeout(this.timeout)}
  }

  onSliderClick(actionLink:string|any, is2DRound:boolean = false){
    if(is2DRound) {
      this.twoDRoundEventEmit.emit(actionLink);

      //update active statge for button
      this.activeRoundButton = actionLink;

      this.slider.moveToIdx((+actionLink)-1);
    } else {
      if(actionLink){
        window.open(actionLink, "_blank");
      }
    }
  }
}