import { Component, Input, OnInit, AfterViewInit, OnDestroy } from "@angular/core";

import { SWNWDateTimeService } from "src/app/@core/utils";

@Component({
    selector:'marqueen-component',
    templateUrl:'./marqueen.component.html',
    styleUrls:['./marqueen.component.scss', '../features-panel/features-panel.component.scss']
})
export class MarqueenComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() descriptionText:string | any;
    @Input() isShowTodayDateTimeText:boolean | any;

    deviceWidth:number | any;
    today:Date | any;

    constructor(private dateTimeService:SWNWDateTimeService) {
        this.deviceWidth = window.innerWidth;

        this.today = new Date();
    }

    ngOnInit(): void {
        
    }

    ngAfterViewInit(): void {
        this.dateTimeService.currentTimer();
    }

    ngOnDestroy(): void {
        this.dateTimeService.clearCurrentTimerTimeout();
    }
}