import { Injectable } from "@angular/core";

@Injectable({providedIn:'root'})
export class UIHandlerService {

    calMainBodyHeight() {
        const swnwHeader = <HTMLElement>document.querySelector('.swnw-header');
        const swnwBody = <HTMLElement>document.querySelector('#swnw-body');
        const swnwFooter = <HTMLElement>document.querySelector('.swnw-footer');

        if(swnwBody && swnwHeader && swnwFooter) {
            swnwBody.style.height = `${(window.innerHeight - (swnwHeader.offsetHeight + swnwFooter.offsetHeight + 1))}px`;
        }
    }

    calMainBodyHeightWithBackHeader() {
        const backHeader= <HTMLElement>document.querySelector('.back-nav-header');
        const swnwBody = <HTMLElement>document.querySelector('#swnw-body');

        if(swnwBody && backHeader) {
            swnwBody.style.height = `${window.innerHeight - (backHeader.offsetHeight/*  + 1 */)}px`;
        }
    }

    //* .body-container
    setBodyContainerPadding(paddingVal:string) {
        const BdyCtnr = <HTMLElement>document.querySelector('.body-container');
        if(BdyCtnr) {
          BdyCtnr.style.padding = paddingVal;
        }
    }

    //* .this-container-1
    calHeightOfThisContainer1() {
        const containerEl = <any>document.querySelector('.this-container-1');
        if(containerEl) containerEl.style.minHeight = `${window.innerHeight - 93}px`;
    }
    setMarginTopOfThisContainer1(val:string){
        const bdyCtnr = <HTMLElement>document.querySelector('.this-container-1');
        if(bdyCtnr) {
            bdyCtnr.style.marginTop = val;
        }
    }

    //* label-09-prefix
    calHeightOfLabel09Prefix() {
        const label09container = <HTMLElement>document.querySelector('.label-09-prefix');
        const input09prefix = <HTMLElement>document.querySelector('.input-09-prefix');
        if(label09container && input09prefix) {
            label09container.style.height = `${input09prefix.offsetHeight}px`;
        }
    }

    //* pin-llok-toggle
    calHeightOfPinLookToggleContainer() {
        const pltEl = <HTMLElement>document.querySelector('.pin-look-toggle');
        const pinputtEl = <HTMLElement>document.querySelector('.pin-input');
        if(pltEl && pinputtEl) {
            pltEl.style.height = `${pinputtEl.offsetHeight}px`;
        }
    }
}