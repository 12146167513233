import { AuthUtilsService } from './auth-utils.service';
import { AuthInterceptorService } from './auth.interceptor';
import { CryptoJsService } from './crypto-js.service';
import { DialogService } from './dialog.service';
import { LocalStorageService } from './local-storage.service'; 
import { NavigatorHelperService } from './navigator-helper.service';
import { PhoneNumDisplayService } from './ph-num-display.service';
import { RootComponentObserverService } from './root-component-observer.service';
import { RoutesGuardService } from './routes-guard.service';
import { SessionStorageService } from './session-storage.service';
import { SpinnerService } from './spinner.service';
import { SWNWDateTimeService } from './date-time.service';
import { SWNWFormService } from './swnw-form.service';
import { LotteryUtilsServices } from './lottery-utils.service';
import { OtherGamesService } from './other-games.service';
import { GFLanguageService } from './gf-language.service';
import { DeviceInfoDetectorService } from './device-info-detector.service';
import { UIHandlerService } from './ui-handler.service';
import { RemoteImageDownloadService } from './remote-image-download.service';

//import { SpinLuckyDrawUtilService } from './spin_lucky_draw.service';

export {
  AuthUtilsService,
  AuthInterceptorService,
  CryptoJsService,
  DialogService,
  LocalStorageService,
  NavigatorHelperService,
  PhoneNumDisplayService,
  RootComponentObserverService,
  RoutesGuardService,
  SessionStorageService,
  SpinnerService,
  SWNWDateTimeService,
  SWNWFormService,
  LotteryUtilsServices,
  OtherGamesService,
  GFLanguageService, 
  DeviceInfoDetectorService,
  UIHandlerService,
  RemoteImageDownloadService,

  //SpinLuckyDrawUtilService,
};
