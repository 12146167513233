<div class="result-panel-container">
    <ng-container *ngIf="!resultData.resultTarget">
        <div>
            
                <div class="result-round-title">
                    <span class="round-span">{{'round-title'|translate}} {{resultData.round}}</span>
                </div>
                <div>
                    <label class="result-num-label">
                        {{resultData.number}}
                    </label>
                </div>
            
            
        </div>
        <div [ngClass]="resultData.isHaveRound2 ? 'd-flex justify-content-between align-items-center' : 'result-date-container'">
            <div [ngClass]="resultData.isHaveRound2 ? 'p-2' : ''">
                <div class="result-date-p-2">
                    <label>{{'result-date-title'|translate}} : {{resultData.date}}</label>
                </div>
            </div>
            <ng-container *ngIf="resultData.isHaveRound2">
                <div class="p-2">
                    <button class="btn btn-default round-flip-btn"
                    (click)="onFlipClick()">
                        <ng-container *ngIf="resultData.round === '1'">
                            {{'round-title'|translate}} 2 <i class="bi bi-chevron-right"></i>
                        </ng-container>
                        <ng-container *ngIf="resultData.round === '2'">
                            <i class="bi bi-chevron-left"></i>
                            {{'round-title'|translate}} 1 
                        </ng-container>
                    </button>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="resultData.resultTarget">
        <div style="padding: 8px;margin-bottom:5%">
            
                <div style="font-size: 15px;">
                    <ng-container *ngIf="resultData.resultType === 'full-winners'">
                        <label class="result-type-label">ဒဲ့</label>
                    </ng-container>
                    <ng-container *ngIf="resultData.resultType === 'toot-winners'">
                        <label class="result-type-label">တွပ်ပတ်လည်</label>
                    </ng-container>
                </div>

                <div>
                    <label>{{resultData.number}}</label>
                </div>
            
            
        </div>
        <div [ngClass]="resultData.isHaveTootPatLae ? 'd-flex justify-content-between align-items-center' : 'result-date-container'">
            <div [ngClass]="resultData.isHaveTootPatLae ? 'p-2' : ''">
                <div class="result-date-p-2">
                    <label style="font-weight: 400;">{{'result-date-title'|translate}} : {{resultData.date}}</label>
                </div>
            </div>
            <ng-container *ngIf="resultData.isHaveTootPatLae">
                <div class="p-2">
                    <button class="btn btn-default round-flip-btn"  style="background: #FF7F79 !important;"
                    (click)="onFlipClick()">
                        <ng-container *ngIf="resultData.resultType === 'full-winners'">
                            တွပ်ပတ်လည် <i class="bi bi-chevron-right"></i>
                        </ng-container>
                        <ng-container *ngIf="resultData.resultType === 'toot-winners'">
                            <i class="bi bi-chevron-left"></i>
                            ဒဲ့ 
                        </ng-container>
                    </button>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>