import { Injectable } from "@angular/core";

@Injectable({providedIn:'root'})
export class LocalStorageService {
    //* LOCAL
    // >> Clear ONLY Logout!
    private TOKEN_KEY = 'sws1nosw-at';
    private USER_PROFILE_INFO_KEY = 'us1pi';
    private USER_PROFILE_IMG_KEY = 'upi2s1';
    private PLAYER_RECEIVE_ACC_KEY = 'rcs1k';
    private PROMOTIONS_KEY = 'mops1ros';
    private TODAY_START_END_KEY = 'tdyss1e';
    private CHOOSE_LANGUAGE_KEY = 'lgchss1e';
    private MAIN_OTHER_GAMES_KEY = 'ms1okgl';
    private HOME_POMOTION_DATA_KEY = 'ips1h';
    private T2D_HOME_POMOTION_DATA_KEY = 't2ks1md2h';
    private T3D_HOME_POMOTION_DATA_KEY = 'kmps13td2h';
    private KMQM_HOME_POMOTION_DATA_KEY = 'ipqs1km2h';
    private RT_HOME_POMOTION_DATA_KEY = 'irps1th';
    private BG_HOME_POMOTION_DATA_KEY = 'gs1biph';
    private T3D_WINNING_NUM_KEY = 's13wdnk';
    private HOME_PROMO_DEFAULT_IMGS_KEY = 'hs1mdiSk';
    private HAS_DAILY_SPIN_KEY = 'hds1sk';
    private DAILY_SPIN_DONE_KEY = 'dss1dk';
    private PGISLO_KEY = 'gfmas1vl2';
    private CHILD_GAMES_LAUNCHING_KEY = 'ligkocs1gs';
    private BTI_SPORT_GAMES_LINK_KEY = 'sgbioss1tk';
    private R12_LATEST_RESULTS_INFO_KEY = '12rlosrik';

    constructor(){}
    
    saveToken(token:string):void {
        localStorage.removeItem(this.TOKEN_KEY);
        localStorage.setItem(this.TOKEN_KEY, token);
    }
    getToken(): string | null {
        return localStorage.getItem(this.TOKEN_KEY);
    }
    clearToken(){
        localStorage.removeItem(this.TOKEN_KEY);
    }//1
    
    saveUserProfileInfo(data:string):void {
        localStorage.removeItem(this.USER_PROFILE_INFO_KEY);
        localStorage.setItem(this.USER_PROFILE_INFO_KEY, data);
    }
    getUserProfileInfo(): string | null {
        return localStorage.getItem(this.USER_PROFILE_INFO_KEY);
    }
    clearUserProfileInfo(){
        localStorage.removeItem(this.USER_PROFILE_INFO_KEY);
    }//2

    saveUserProfileImg(data:string):void {
        localStorage.removeItem(this.USER_PROFILE_IMG_KEY);
        localStorage.setItem(this.USER_PROFILE_IMG_KEY, data);
    }
    getUserProfileImg(): string | null {
        return localStorage.getItem(this.USER_PROFILE_IMG_KEY);
    }
    clearUserProfileImg(){
        localStorage.removeItem(this.USER_PROFILE_IMG_KEY);
    }//3

    saveHomePromotionData(data:string):void {
        localStorage.removeItem(this.HOME_POMOTION_DATA_KEY);
        localStorage.setItem(this.HOME_POMOTION_DATA_KEY, data);
    }
    getHomePromotionData(): string | null {
        return localStorage.getItem(this.HOME_POMOTION_DATA_KEY);
    }
    clearHomePromotionData(){
        localStorage.removeItem(this.HOME_POMOTION_DATA_KEY);
    }//4

    savePlayerReceivingAccounts(data:string):void {
        localStorage.removeItem(this.PLAYER_RECEIVE_ACC_KEY);
        localStorage.setItem(this.PLAYER_RECEIVE_ACC_KEY, data);
    }
    getPlayerReceivingAccounts(): string | null {
        return localStorage.getItem(this.PLAYER_RECEIVE_ACC_KEY);
    }
    clearPlayerReceivingAccounts(){
        localStorage.removeItem(this.PLAYER_RECEIVE_ACC_KEY);
    }//5

    savePromotions(data:string):void {
        localStorage.removeItem(this.PROMOTIONS_KEY);
        localStorage.setItem(this.PROMOTIONS_KEY, data);
    }
    getPromotions(): string | null {
        return localStorage.getItem(this.PROMOTIONS_KEY);
    }
    clearPromotions(){
        localStorage.removeItem(this.PROMOTIONS_KEY);
    }//8

    saveTodayStartEnd(data:string):void {
        localStorage.removeItem(this.TODAY_START_END_KEY);
        localStorage.setItem(this.TODAY_START_END_KEY, data);
    }
    getTodayStartEnd(): string | null {
        return localStorage.getItem(this.TODAY_START_END_KEY);
    }
    clearTodayStartEnd(){
        localStorage.removeItem(this.TODAY_START_END_KEY);
    }//9

    saveChooseLangugae(data:string):void {
        localStorage.removeItem(this.CHOOSE_LANGUAGE_KEY);
        localStorage.setItem(this.CHOOSE_LANGUAGE_KEY, data);
    }
    getChooseLangugae(): string | null {
        return localStorage.getItem(this.CHOOSE_LANGUAGE_KEY);
    }
    clearChooseLangugae(){
        localStorage.removeItem(this.CHOOSE_LANGUAGE_KEY);
    }//10

    saveMainOtherGames(data:string):void {
        localStorage.removeItem(this.MAIN_OTHER_GAMES_KEY);
        localStorage.setItem(this.MAIN_OTHER_GAMES_KEY, data);
    }
    getMainOtherGames(): string | null {
        return localStorage.getItem(this.MAIN_OTHER_GAMES_KEY);
    }
    clearMainOtherGames(){
        localStorage.removeItem(this.MAIN_OTHER_GAMES_KEY);
    }//11

    saveKMQMHomePromoData(data:string):void {
        localStorage.removeItem(this.KMQM_HOME_POMOTION_DATA_KEY);
        localStorage.setItem(this.KMQM_HOME_POMOTION_DATA_KEY, data);
    }
    getKMQMHomePromoData(): string | null {
        return localStorage.getItem(this.KMQM_HOME_POMOTION_DATA_KEY);
    }
    clearKMQMHomePromoData(){
        localStorage.removeItem(this.KMQM_HOME_POMOTION_DATA_KEY);
    }//12

    saveRTHomePromoData(data:string):void {
        localStorage.removeItem(this.RT_HOME_POMOTION_DATA_KEY);
        localStorage.setItem(this.RT_HOME_POMOTION_DATA_KEY, data);
    }
    getRTHomePromoData(): string | null {
        return localStorage.getItem(this.RT_HOME_POMOTION_DATA_KEY);
    }
    clearRTHomePromoData(){
        localStorage.removeItem(this.RT_HOME_POMOTION_DATA_KEY);
    }//13

    saveBGHomePromoData(data:string):void {
        localStorage.removeItem(this.BG_HOME_POMOTION_DATA_KEY);
        localStorage.setItem(this.BG_HOME_POMOTION_DATA_KEY, data);
    }
    getBGHomePromoData(): string | null {
        return localStorage.getItem(this.BG_HOME_POMOTION_DATA_KEY);
    }
    clearBGHomePromoData(){
        localStorage.removeItem(this.BG_HOME_POMOTION_DATA_KEY);
    }//14

    save2DHomePromoData(data:string):void {
        localStorage.removeItem(this.T2D_HOME_POMOTION_DATA_KEY);
        localStorage.setItem(this.T2D_HOME_POMOTION_DATA_KEY, data);
    }
    get2DHomePromoData(): string | null {
        return localStorage.getItem(this.T2D_HOME_POMOTION_DATA_KEY);
    }
    clear2DHomePromoData(){
        localStorage.removeItem(this.T2D_HOME_POMOTION_DATA_KEY);
    }//15

    save3DHomePromoData(data:string):void {
        localStorage.removeItem(this.T3D_HOME_POMOTION_DATA_KEY);
        localStorage.setItem(this.T3D_HOME_POMOTION_DATA_KEY, data);
    }
    get3DHomePromoData(): string | null {
        return localStorage.getItem(this.T3D_HOME_POMOTION_DATA_KEY);
    }
    clear3DHomePromoData(){
        localStorage.removeItem(this.T3D_HOME_POMOTION_DATA_KEY);
    }//16

    save3DWinningNum(data:string):void {
        localStorage.removeItem(this.T3D_WINNING_NUM_KEY);
        localStorage.setItem(this.T3D_WINNING_NUM_KEY, data);
    }
    get3DWinningNum(): string | null {
        return localStorage.getItem(this.T3D_WINNING_NUM_KEY);
    }
    clear3DWinningNum(){
        localStorage.removeItem(this.T3D_WINNING_NUM_KEY);
    }//16

    saveBTISportGamesLink(data:string):void {
        localStorage.removeItem(this.BTI_SPORT_GAMES_LINK_KEY);
        localStorage.setItem(this.BTI_SPORT_GAMES_LINK_KEY, data);
    }
    getBTISportGamesLink(): string | null {
        return localStorage.getItem(this.BTI_SPORT_GAMES_LINK_KEY);
    }
    clearBTISportGamesLink() {
        localStorage.removeItem(this.BTI_SPORT_GAMES_LINK_KEY);
    }//25

    saveChildGamesLaunchingStage(data:string):void {
        localStorage.removeItem(this.CHILD_GAMES_LAUNCHING_KEY);
        localStorage.setItem(this.CHILD_GAMES_LAUNCHING_KEY, data);
    }
    getChildGamesLaunchingStage(): string | null {
        return localStorage.getItem(this.CHILD_GAMES_LAUNCHING_KEY);
    }
    clearChildGamesLaunchingStage() {
        localStorage.removeItem(this.CHILD_GAMES_LAUNCHING_KEY);
    }//28
    
    saveHomePromoDefaultImgs(data:string):void {
        localStorage.removeItem(this.HOME_PROMO_DEFAULT_IMGS_KEY);
        localStorage.setItem(this.HOME_PROMO_DEFAULT_IMGS_KEY, data);
    }
    getHomePromoDefaultImgs(): string | null {
        return localStorage.getItem(this.HOME_PROMO_DEFAULT_IMGS_KEY);
    }
    clearHomePromoDefaultImgs() {
        localStorage.removeItem(this.HOME_PROMO_DEFAULT_IMGS_KEY);
    }//29

    saveHasDailySpin(data:string):void {
        localStorage.removeItem(this.HAS_DAILY_SPIN_KEY);
        localStorage.setItem(this.HAS_DAILY_SPIN_KEY, data);
    }
    getHasDailySpin(): string | null {
        return localStorage.getItem(this.HAS_DAILY_SPIN_KEY);
    }
    clearHasDailySpin() {
        localStorage.removeItem(this.HAS_DAILY_SPIN_KEY);
    }//30

    saveDailySpinDone(data:string):void {
        localStorage.removeItem(this.DAILY_SPIN_DONE_KEY);
        localStorage.setItem(this.DAILY_SPIN_DONE_KEY, data);
    }
    getDailySpinDone(): string | null {
        return localStorage.getItem(this.DAILY_SPIN_DONE_KEY);
    }//31

    saveGamePageInitStageLoading(data:string):void {
        localStorage.removeItem(this.PGISLO_KEY);
        localStorage.setItem(this.PGISLO_KEY, data);
    }
    getGamePageInitStageLoading(): string | null {
        return localStorage.getItem(this.PGISLO_KEY);
    }
    clearGamePageInitStageLoading() {
        localStorage.removeItem(this.PGISLO_KEY);
    }

    saveR12LatestResultsInfo(data:string):void {
        localStorage.removeItem(this.R12_LATEST_RESULTS_INFO_KEY);
        localStorage.setItem(this.R12_LATEST_RESULTS_INFO_KEY, data);
    }
    getR12LatestResultsInfo(): string | null {
        return localStorage.getItem(this.R12_LATEST_RESULTS_INFO_KEY);
    }
    clearR12LatestResultsInfo() {
        localStorage.removeItem(this.R12_LATEST_RESULTS_INFO_KEY);
    }
    
    clearAuth() {
        this.clearToken();
        this.clearUserProfileInfo();
        this.clearUserProfileImg();
        this.clearPlayerReceivingAccounts();
        //this.clearSWNWReceivingAccountsKPay();
        //this.clearSWNWReceivingAccountsWave();
        this.clearPromotions();
        this.clearTodayStartEnd();
        this.clearMainOtherGames();
        this.clearHomePromotionData();
        this.clear2DHomePromoData();
        this.clear3DHomePromoData();
        this.clearKMQMHomePromoData();
        this.clearRTHomePromoData();
        this.clearBGHomePromoData();
        this.clear3DWinningNum();
        this.clearHomePromoDefaultImgs();
        this.clearHasDailySpin();
        this.clearGamePageInitStageLoading();
        this.clearChooseLangugae();
        this.clearR12LatestResultsInfo();
    }

    clearLocalSession() {
        //this.clearPlayerReceivingAccounts();
        this.clearPromotions();
        //this.clearTodayStartEnd();
        this.clearMainOtherGames();
        this.clearHomePromotionData();
        this.clear2DHomePromoData();
        this.clear3DHomePromoData();
        this.clearKMQMHomePromoData();
        this.clearRTHomePromoData();
        this.clearBGHomePromoData();
        this.clear3DWinningNum();
        this.clearHomePromoDefaultImgs();
        //this.clearHasDailySpin();
        //this.clearGamePageInitStageLoading();
        //this.clearChooseLangugae();
        //this.clearR12LatestResultsInfo();
    }
}