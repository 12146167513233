const Languages = [
    {
        id:1,
        flag:'../../../assets/imgs/profile-and-language/v2/flags/myanmar-flag.svg',
        name:'မြန်မာ',
        value:'my',
    },
    {
        id:2,
        flag:'../../../assets/imgs/profile-and-language/v2/flags/english-flag.svg',
        name:'English',
        value:'en',
    },
    {
        id:3,
        flag:'../../../assets/imgs/profile-and-language/v2/flags/china-flag.svg',
        name:'中文',
        value:'zh',
    },
];

export default Languages;