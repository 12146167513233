import { Injectable } from "@angular/core";

@Injectable({providedIn:'root'})
export class SessionStorageService {
    private TWO_D_BET_WILL_CLOSE_TIME_KEY = 'tcs1wb';
    private TWO_D_ROUNDS_RESULT_KEY = 'tdrss1rlt';
    private THREE_D_BET_WILL_CLOSE_TIME_KEY = 'tcws1btreed';
    private THREE_D_RESULT_KEY = 'tdrss1rlttreed';
    private THREE_D_LATEST_3_MONTHS_RESULT_KEY = 'tdrss1rlttreedl3';
    private TWO_D_RESULT_HISTORY_KEY = 'rhks12td';
    private THREE_D_WINNERS_KEY = 'tds13kwsl';
    private THREE_D_RESULT_HISTORY_KEY = 'rhk3s1td';
    private THREE_D_BET_HISTORY_KEY = 'bhk3ts1d';
    private THREE_D_DREAM_BET_HISTORY_KEY = 'bds1hk3td';
    private KING_MAKER_CHILD_GAMES_KEY = 'kmkcs1g';
    private RED_TIGER_CHILD_GAMES_KEY = 'cgs1lrtk';
    private BIG_CHILD_GAMES_KEY = 'bcs1gsl4k';
    private ALL_HOME_PROMO_TYPES_KEY = 'ahs1ptk';
    private SELECTED_DATES_ON_WALLET_HISTORY = 'sds1owh';
    private REGIST_DATA_KEY = 'swnwtms1pRgd';
    private FORGET_PIN_KEY = 'fs1ps';
    private SAVE_PHONE_FOR_FORGET_PIN_KEY = 'sps1nfps';
    private WALLET_FILL_CLICK_VIA_HOME = 'wfcs1vh';
    private VERIFY_OTP_VIA = 'vs1ov';
    private VERIFY_OTP_RESULT_FOR_WITHDRAW = 'vos1rfw';
    private WALLET_DETAIL_KEY = 'wds1yk';
    private IS_DISPLAYED_GAME_IFRAME_KEY = 'ids1gik'; 
    private T23D_FIRST_RELOAD_KEY = 'rl1ftts1tds';
    private TEMP_WALLET_ID_KEY = 'kiwds1tmp';
    private TEMP_RECEIVE_ACC_ID_KEY = 'ras1ikdftp';
    private LIN_KEY = 'kfls1d';
    private TWOD_BET_HISTORY_DETAIL_KEY = 'a3bhd2ds1k';
    private QUICK_SELECTED_NUMBERS_KEY = 'sq2nuds1k0';
    private THREED_QUICK_SELECTED_NUMBERS_KEY = 'ss1q3nudk0';
    private BETTING_CONFIRM_KEY = 'a3s1bck';
    private THREED_BET_HISTORY_DETAIL_KEY = 'a3dk2s1bhd';
    private TWOD_SOURCE_NUMBERS_KEY = 't2dsns1umk';
    private CURRENT_ROUTE_KEY = 'a2d3s1crk';
    private THREED_BET_HISTORY_FILTER_DATA = 'd3fbhs1txn1s';
    private TWOD_BET_HISTORY_FILTER_DATA = 'd21sfbs1htxn';
    private WALLET_TXN_HISTORY_FILTER_DATA = 'swths1fd1';
    private TUTORIAL_DETAIL_LINK_DATA = 'tdlds1';
    private TWOTHREED_TITLE_IMGS_DATA = 'osti1ssd3';
    private BETTING_LIMIT_ERROR_DATA = 'sds1kq2nu01s';
    private INVALID_BETTING_NUMS_ON_BETTING_LIMIT_ERROR_PG = 'klkjk1sjdkjsd';
    private ERR_512_STATUS = 'stfo1stgf';

    constructor(){}
    
    saveRegisterData(data:string):void {
        sessionStorage.removeItem(this.REGIST_DATA_KEY);
        sessionStorage.setItem(this.REGIST_DATA_KEY, data);
    }
    getRegisterData(): string | null {
        return sessionStorage.getItem(this.REGIST_DATA_KEY);
    }
    clearRegisterData(){
        sessionStorage.removeItem(this.REGIST_DATA_KEY);
    }//2
    
    saveForgetPinStatus(data:string):void {
        sessionStorage.removeItem(this.FORGET_PIN_KEY);
        sessionStorage.setItem(this.FORGET_PIN_KEY, data);
    }
    getForgetPinStatus(): string | null {
        return sessionStorage.getItem(this.FORGET_PIN_KEY);
    }
    clearForgetPinStatus(){
        sessionStorage.removeItem(this.FORGET_PIN_KEY);
    }//3

    savePhoneNumForForgetPin(data:string):void {
        sessionStorage.removeItem(this.SAVE_PHONE_FOR_FORGET_PIN_KEY);
        sessionStorage.setItem(this.SAVE_PHONE_FOR_FORGET_PIN_KEY, data);
    }
    getPhoneNumForForgetPin(): string | null {
        return sessionStorage.getItem(this.SAVE_PHONE_FOR_FORGET_PIN_KEY);
    }
    clearPhoneNumForForgetPin(){
        sessionStorage.removeItem(this.SAVE_PHONE_FOR_FORGET_PIN_KEY);
    }//4
    
    saveWalletFillClickViaHome(data:string):void {
        sessionStorage.removeItem(this.WALLET_FILL_CLICK_VIA_HOME);
        sessionStorage.setItem(this.WALLET_FILL_CLICK_VIA_HOME, data);
    }
    getWalletFillClickViaHome(): string | null {
        return sessionStorage.getItem(this.WALLET_FILL_CLICK_VIA_HOME);
    }
    clearWalletFillClickViaHome(){
        sessionStorage.removeItem(this.WALLET_FILL_CLICK_VIA_HOME);
    }//5

    saveVerifyOTPVia(data:string):void {
        sessionStorage.removeItem(this.VERIFY_OTP_VIA);
        sessionStorage.setItem(this.VERIFY_OTP_VIA, data);
    }
    getVerifyOTPVia(): string | null {
        return sessionStorage.getItem(this.VERIFY_OTP_VIA);
    }
    clearVerifyOTPVia(){
        sessionStorage.removeItem(this.VERIFY_OTP_VIA);
    }//8

    saveVerifyOTPResultForWithdraw(data:string):void {
        sessionStorage.removeItem(this.VERIFY_OTP_RESULT_FOR_WITHDRAW);
        sessionStorage.setItem(this.VERIFY_OTP_RESULT_FOR_WITHDRAW, data);
    }
    getVerifyOTPResultForWithdraw(): string | null {
        return sessionStorage.getItem(this.VERIFY_OTP_RESULT_FOR_WITHDRAW);
    }
    clearVerifyOTPResultForWithdraw(){
        sessionStorage.removeItem(this.VERIFY_OTP_RESULT_FOR_WITHDRAW);
    }//9
    
    saveWalletDetail(data:string):void {
        sessionStorage.removeItem(this.WALLET_DETAIL_KEY);
        sessionStorage.setItem(this.WALLET_DETAIL_KEY, data);
    }
    getWalletDetail(): string | null {
        return sessionStorage.getItem(this.WALLET_DETAIL_KEY);
    }
    clearWalletDetail(){
        sessionStorage.removeItem(this.WALLET_DETAIL_KEY);
    }//10

    saveBetWillCloseTime(data:string):void {
        sessionStorage.removeItem(this.TWO_D_BET_WILL_CLOSE_TIME_KEY);
        sessionStorage.setItem(this.TWO_D_BET_WILL_CLOSE_TIME_KEY, data);
    }
    getBetWillCloseTime(): string | null {
        return sessionStorage.getItem(this.TWO_D_BET_WILL_CLOSE_TIME_KEY);
    }
    clearBetWillCloseTime() {
        sessionStorage.removeItem(this.TWO_D_BET_WILL_CLOSE_TIME_KEY);
    }//11

    save2DRoundsResult(data:string):void {
        sessionStorage.removeItem(this.TWO_D_ROUNDS_RESULT_KEY);
        sessionStorage.setItem(this.TWO_D_ROUNDS_RESULT_KEY, data);
    }
    get2DRoundsResult(): string | null {
        return sessionStorage.getItem(this.TWO_D_ROUNDS_RESULT_KEY);
    }
    clear2DRoundsResult() {
        sessionStorage.removeItem(this.TWO_D_ROUNDS_RESULT_KEY);
    }//12

    save3DBetWillCloseTime(data:string):void {
        sessionStorage.removeItem(this.THREE_D_BET_WILL_CLOSE_TIME_KEY);
        sessionStorage.setItem(this.THREE_D_BET_WILL_CLOSE_TIME_KEY, data);
    }
    get3DBetWillCloseTime(): string | null {
        return sessionStorage.getItem(this.THREE_D_BET_WILL_CLOSE_TIME_KEY);
    }
    clear3DBetWillCloseTime() {
        sessionStorage.removeItem(this.THREE_D_BET_WILL_CLOSE_TIME_KEY);
    }//14

    save3DRoundsResult(data:string):void {
        sessionStorage.removeItem(this.THREE_D_RESULT_KEY);
        sessionStorage.setItem(this.THREE_D_RESULT_KEY, data);
    }
    get3DRoundsResult(): string | null {
        return sessionStorage.getItem(this.THREE_D_RESULT_KEY);
    }
    clear3DRoundsResult() {
        sessionStorage.removeItem(this.THREE_D_RESULT_KEY);
    }//15

    save3DLatest3MonthsResult(data:string):void {
        sessionStorage.removeItem(this.THREE_D_LATEST_3_MONTHS_RESULT_KEY);
        sessionStorage.setItem(this.THREE_D_LATEST_3_MONTHS_RESULT_KEY, data);
    }
    get3DLatest3MonthsResult(): string | null {
        return sessionStorage.getItem(this.THREE_D_LATEST_3_MONTHS_RESULT_KEY);
    }
    clear3DLatest3MonthsResult() {
        sessionStorage.removeItem(this.THREE_D_LATEST_3_MONTHS_RESULT_KEY);
    }//16

    save2DResultHistory(data:string):void {
        sessionStorage.removeItem(this.TWO_D_RESULT_HISTORY_KEY);
        sessionStorage.setItem(this.TWO_D_RESULT_HISTORY_KEY, data);
    }
    get2DResultHistory(): string | null {
        return sessionStorage.getItem(this.TWO_D_RESULT_HISTORY_KEY);
    }
    clear2DResultHistory() {
        sessionStorage.removeItem(this.TWO_D_RESULT_HISTORY_KEY);
    }//19
    
    save3DWinners(data:string):void {
        sessionStorage.removeItem(this.THREE_D_WINNERS_KEY);
        sessionStorage.setItem(this.THREE_D_WINNERS_KEY, data);
    }
    get3DWinners(): string | null {
        return sessionStorage.getItem(this.THREE_D_WINNERS_KEY);
    }
    clear3DWinners() {
        sessionStorage.removeItem(this.THREE_D_WINNERS_KEY);
    }//21

    save3DResultHistory(data:string):void {
        sessionStorage.removeItem(this.THREE_D_RESULT_HISTORY_KEY);
        sessionStorage.setItem(this.THREE_D_RESULT_HISTORY_KEY, data);
    }
    get3DResultHistory(): string | null {
        return sessionStorage.getItem(this.THREE_D_RESULT_HISTORY_KEY);
    }
    clear3DResultHistory() {
        sessionStorage.removeItem(this.THREE_D_RESULT_HISTORY_KEY);
    }//22

    save3DBetHistory(data:string):void {
        sessionStorage.removeItem(this.THREE_D_BET_HISTORY_KEY);
        sessionStorage.setItem(this.THREE_D_BET_HISTORY_KEY, data);
    }
    get3DBetHistory(): string | null {
        return sessionStorage.getItem(this.THREE_D_BET_HISTORY_KEY);
    }
    clear3DBetHistory() {
        sessionStorage.removeItem(this.THREE_D_BET_HISTORY_KEY);
    }//23

    save3DDreamBetHistory(data:string):void {
        sessionStorage.removeItem(this.THREE_D_DREAM_BET_HISTORY_KEY);
        sessionStorage.setItem(this.THREE_D_DREAM_BET_HISTORY_KEY, data);
    }
    get3DDreamBetHistory(): string | null {
        return sessionStorage.getItem(this.THREE_D_DREAM_BET_HISTORY_KEY);
    }
    clear3DDreamBetHistory() {
        sessionStorage.removeItem(this.THREE_D_DREAM_BET_HISTORY_KEY);
    }//24

    saveKingMakerChildGames(data:string):void {
        sessionStorage.removeItem(this.KING_MAKER_CHILD_GAMES_KEY);
        sessionStorage.setItem(this.KING_MAKER_CHILD_GAMES_KEY, data);
    }
    getKingMakerChildGames(): string | null {
        return sessionStorage.getItem(this.KING_MAKER_CHILD_GAMES_KEY);
    }
    clearKingMakerChildGames() {
        sessionStorage.removeItem(this.KING_MAKER_CHILD_GAMES_KEY);
    }//26

    saveRedTigerChildGames(data:string):void {
        sessionStorage.removeItem(this.RED_TIGER_CHILD_GAMES_KEY);
        sessionStorage.setItem(this.RED_TIGER_CHILD_GAMES_KEY, data);
    }
    getRedTigerChildGames(): string | null {
        return sessionStorage.getItem(this.RED_TIGER_CHILD_GAMES_KEY);
    }
    clearRedTigerChildGames() {
        sessionStorage.removeItem(this.RED_TIGER_CHILD_GAMES_KEY);
    }//27

    saveBigChildGames(data:string):void {
        sessionStorage.removeItem(this.BIG_CHILD_GAMES_KEY);
        sessionStorage.setItem(this.BIG_CHILD_GAMES_KEY, data);
    }
    getBigChildGames(): string | null {
        return sessionStorage.getItem(this.BIG_CHILD_GAMES_KEY);
    }
    clearBigChildGames() {
        sessionStorage.removeItem(this.BIG_CHILD_GAMES_KEY);
    }//29

    saveIsDisplayedGameIFrame(data:string):void {
        sessionStorage.removeItem(this.IS_DISPLAYED_GAME_IFRAME_KEY);
        sessionStorage.setItem(this.IS_DISPLAYED_GAME_IFRAME_KEY, data);
    }
    getIsDisplayedGameIFrame(): string | null {
        return sessionStorage.getItem(this.IS_DISPLAYED_GAME_IFRAME_KEY);
    }
    clearIsDisplayedGameIFrame() {
        sessionStorage.removeItem(this.IS_DISPLAYED_GAME_IFRAME_KEY);
    }//30

    saveAllHomePromoTypes(data:string):void {
        sessionStorage.removeItem(this.ALL_HOME_PROMO_TYPES_KEY);
        sessionStorage.setItem(this.ALL_HOME_PROMO_TYPES_KEY, data);
    }
    getAllHomePromoTypes(): string | null {
        return sessionStorage.getItem(this.ALL_HOME_PROMO_TYPES_KEY);
    }
    clearAllHomePromoTypes() {
        sessionStorage.removeItem(this.ALL_HOME_PROMO_TYPES_KEY);
    }//31

    save23DFirstTimeLoad(data:string):void {
        sessionStorage.removeItem(this.T23D_FIRST_RELOAD_KEY);
        sessionStorage.setItem(this.T23D_FIRST_RELOAD_KEY, data);
    }
    get23DFirstTimeLoad(): string | null {
        return sessionStorage.getItem(this.T23D_FIRST_RELOAD_KEY);
    }
    clear23DFirstTimeLoad() {
        sessionStorage.removeItem(this.T23D_FIRST_RELOAD_KEY);
    }//32

    saveTempWalletId(data:string):void {
        sessionStorage.removeItem(this.TEMP_WALLET_ID_KEY);
        sessionStorage.setItem(this.TEMP_WALLET_ID_KEY, data);
    }
    getTempWalletId(): string | null {
        return sessionStorage.getItem(this.TEMP_WALLET_ID_KEY);
    }
    clearTempWalletId() {
        sessionStorage.removeItem(this.TEMP_WALLET_ID_KEY);
    }//33

    saveTempReceiveAccId(data:string):void {
        sessionStorage.removeItem(this.TEMP_RECEIVE_ACC_ID_KEY);
        sessionStorage.setItem(this.TEMP_RECEIVE_ACC_ID_KEY, data);
    }
    getTempReceiveAccId(): string | null {
        return sessionStorage.getItem(this.TEMP_RECEIVE_ACC_ID_KEY);
    }
    clearTempReceiveAccId() {
        sessionStorage.removeItem(this.TEMP_RECEIVE_ACC_ID_KEY);
    }//34

    saveSelectedDatesOnWalletHostory(data:string):void {
        sessionStorage.removeItem(this.SELECTED_DATES_ON_WALLET_HISTORY);
        sessionStorage.setItem(this.SELECTED_DATES_ON_WALLET_HISTORY, data);
    }
    getSelectedDatesOnWalletHostory(): string | null {
        return sessionStorage.getItem(this.SELECTED_DATES_ON_WALLET_HISTORY);
    }
    clearSelectedDatesOnWalletHistory(){
        sessionStorage.removeItem(this.SELECTED_DATES_ON_WALLET_HISTORY);
    }//35

    saveLinData(data:string):void {
        sessionStorage.removeItem(this.LIN_KEY);
        sessionStorage.setItem(this.LIN_KEY, data);
    }
    getLinData(): string | null {
        return sessionStorage.getItem(this.LIN_KEY);
    }
    clearLinData() {
        sessionStorage.removeItem(this.LIN_KEY);
    }//36

    save2DBetHistoryDetailData(data:string):void {
        sessionStorage.removeItem(this.TWOD_BET_HISTORY_DETAIL_KEY);
        sessionStorage.setItem(this.TWOD_BET_HISTORY_DETAIL_KEY, data);
    }
    get2DBetHistoryDetailData(): string | null {
        return sessionStorage.getItem(this.TWOD_BET_HISTORY_DETAIL_KEY);
    }
    clear2DBetHistoryDetailData() {
        sessionStorage.removeItem(this.TWOD_BET_HISTORY_DETAIL_KEY);
    }

    //2D
    save2DQuickSelectedNums(data:string):void {
        sessionStorage.removeItem(this.QUICK_SELECTED_NUMBERS_KEY);
        sessionStorage.setItem(this.QUICK_SELECTED_NUMBERS_KEY, data);
    }
    get2DQuickSelectedNums(): string | null {
        return sessionStorage.getItem(this.QUICK_SELECTED_NUMBERS_KEY);
    }
    clear2DQuickSelectedNums() {
        sessionStorage.removeItem(this.QUICK_SELECTED_NUMBERS_KEY);
    }

    //3D
    save3DQuickSelectedNums(data:string):void {
        sessionStorage.removeItem(this.THREED_QUICK_SELECTED_NUMBERS_KEY);
        sessionStorage.setItem(this.THREED_QUICK_SELECTED_NUMBERS_KEY, data);
    }
    get3DQuickSelectedNums(): string | null {
        return sessionStorage.getItem(this.THREED_QUICK_SELECTED_NUMBERS_KEY);
    }
    clear3DQuickSelectedNums() {
        sessionStorage.removeItem(this.THREED_QUICK_SELECTED_NUMBERS_KEY);
    }

    saveBettingConfirmData(data:string):void {
        sessionStorage.removeItem(this.BETTING_CONFIRM_KEY);
        sessionStorage.setItem(this.BETTING_CONFIRM_KEY, data);
    }
    getBettingConfirmData(): string | null {
        return sessionStorage.getItem(this.BETTING_CONFIRM_KEY);
    }
    clearBettingConfirmData() {
        sessionStorage.removeItem(this.BETTING_CONFIRM_KEY);
    }

    save3DBetHistoryDetailData(data:string):void {
        sessionStorage.removeItem(this.THREED_BET_HISTORY_DETAIL_KEY);
        sessionStorage.setItem(this.THREED_BET_HISTORY_DETAIL_KEY, data);
    }
    get3DBetHistoryDetailData(): string | null {
        return sessionStorage.getItem(this.THREED_BET_HISTORY_DETAIL_KEY);
    }
    clear3DBetHistoryDetailData() {
        sessionStorage.removeItem(this.THREED_BET_HISTORY_DETAIL_KEY);
    }

    save2DSourceNums(data:string):void {
        sessionStorage.removeItem(this.TWOD_SOURCE_NUMBERS_KEY);
        sessionStorage.setItem(this.TWOD_SOURCE_NUMBERS_KEY, data);
    }
    get2DSourceNums(): string | null {
        return sessionStorage.getItem(this.TWOD_SOURCE_NUMBERS_KEY);
    }
    clear2DSourceNums() {
        sessionStorage.removeItem(this.TWOD_SOURCE_NUMBERS_KEY);
    }

    saveCurrentRoute(data:string):void {
        sessionStorage.removeItem(this.CURRENT_ROUTE_KEY);
        sessionStorage.setItem(this.CURRENT_ROUTE_KEY, data);
    }
    getCurrentRoute(): string | null {
        return sessionStorage.getItem(this.CURRENT_ROUTE_KEY);
    }
    clearCurrentRoute() {
        sessionStorage.removeItem(this.CURRENT_ROUTE_KEY);
    }

    save3DBetHistoryFilterData(data:string):void {
        sessionStorage.removeItem(this.THREED_BET_HISTORY_FILTER_DATA);
        sessionStorage.setItem(this.THREED_BET_HISTORY_FILTER_DATA, data);
    }
    get3DBetHistoryFilterData(): string | null {
        return sessionStorage.getItem(this.THREED_BET_HISTORY_FILTER_DATA);
    }
    clear3DBetHistoryFilterData() {
        sessionStorage.removeItem(this.THREED_BET_HISTORY_FILTER_DATA);
    }

    save2DBetHistoryFilterData(data:string):void {
        sessionStorage.removeItem(this.TWOD_BET_HISTORY_FILTER_DATA);
        sessionStorage.setItem(this.TWOD_BET_HISTORY_FILTER_DATA, data);
    }
    get2DBetHistoryFilterData(): string | null {
        return sessionStorage.getItem(this.TWOD_BET_HISTORY_FILTER_DATA);
    }
    clear2DBetHistoryFilterData() {
        sessionStorage.removeItem(this.TWOD_BET_HISTORY_FILTER_DATA);
    }

    saveWalletTxnHistoryFilterData(data:string):void {
        sessionStorage.removeItem(this.WALLET_TXN_HISTORY_FILTER_DATA);
        sessionStorage.setItem(this.WALLET_TXN_HISTORY_FILTER_DATA, data);
    }
    getWalletTxnHistoryFilterData(): string | null {
        return sessionStorage.getItem(this.WALLET_TXN_HISTORY_FILTER_DATA);
    }
    clearWalletTxnHistoryFilterData() {
        sessionStorage.removeItem(this.WALLET_TXN_HISTORY_FILTER_DATA);
    }

    saveTutorialDetailLinkData(data:string):void {
        sessionStorage.removeItem(this.TUTORIAL_DETAIL_LINK_DATA);
        sessionStorage.setItem(this.TUTORIAL_DETAIL_LINK_DATA, data);
    }
    getTutorialDetailLinkData(): string | null {
        return sessionStorage.getItem(this.TUTORIAL_DETAIL_LINK_DATA);
    }
    clearTutorialDetailLinkData() {
        sessionStorage.removeItem(this.TUTORIAL_DETAIL_LINK_DATA);
    }

    save23DsTitleImgsData(data:string):void {
        sessionStorage.removeItem(this.TWOTHREED_TITLE_IMGS_DATA);
        sessionStorage.setItem(this.TWOTHREED_TITLE_IMGS_DATA, data);
    }
    get23DsTitleImgsData(): string | null {
        return sessionStorage.getItem(this.TWOTHREED_TITLE_IMGS_DATA);
    }
    clear23DsTitleImgsData() {
        sessionStorage.removeItem(this.TWOTHREED_TITLE_IMGS_DATA);
    }

    //add new
    saveBettingErrorData(data:string):void {
        sessionStorage.removeItem(this.BETTING_LIMIT_ERROR_DATA);
        sessionStorage.setItem(this.BETTING_LIMIT_ERROR_DATA, data);
    }
    getBettingErrorData(): string | null {
        return sessionStorage.getItem(this.BETTING_LIMIT_ERROR_DATA);
    }
    clearBettingErrorData() {
        sessionStorage.removeItem(this.BETTING_LIMIT_ERROR_DATA);
    }

    saveInvalidBetNumsInBettingLimitErrorPage(data:string):void {
        sessionStorage.removeItem(this.INVALID_BETTING_NUMS_ON_BETTING_LIMIT_ERROR_PG);
        sessionStorage.setItem(this.INVALID_BETTING_NUMS_ON_BETTING_LIMIT_ERROR_PG, data);
    }
    getInvalidBetNumsInBettingLimitErrorPage(): string | null {
        return sessionStorage.getItem(this.INVALID_BETTING_NUMS_ON_BETTING_LIMIT_ERROR_PG);
    }
    clearInvalidBetNumsInBettingLimitErrorPage() {
        sessionStorage.removeItem(this.INVALID_BETTING_NUMS_ON_BETTING_LIMIT_ERROR_PG);
    }

    saveERR512Status(data:string):void {
        sessionStorage.removeItem(this.ERR_512_STATUS);
        sessionStorage.setItem(this.ERR_512_STATUS, data);
    }
    getERR512Status(): string | null {
        return sessionStorage.getItem(this.ERR_512_STATUS);
    }
    clearERR512Status() {
        sessionStorage.removeItem(this.ERR_512_STATUS);
    }

    clearSession(){
        this.clearBetWillCloseTime();
        this.clear2DResultHistory();
        this.clear2DRoundsResult();
        this.clear3DBetWillCloseTime();
        this.clear3DBetHistory();
        this.clear3DDreamBetHistory();
        this.clear3DLatest3MonthsResult();
        this.clear3DResultHistory();
        this.clear3DRoundsResult();
        this.clear3DWinners();
        this.clearKingMakerChildGames();
        this.clearRedTigerChildGames();
        this.clearBigChildGames();
        this.clearSelectedDatesOnWalletHistory();
        this.clear2DBetHistoryDetailData();
        this.clear3DBetHistoryDetailData();
        this.clear2DQuickSelectedNums();
        this.clear3DQuickSelectedNums();
        this.clear2DSourceNums();
        this.clearBettingConfirmData();
        this.clearCurrentRoute();
        this.clear3DBetHistoryFilterData();
        this.clear2DBetHistoryFilterData();
        this.clearWalletTxnHistoryFilterData();
        this.clearTutorialDetailLinkData();
        this.clear23DsTitleImgsData();
        this.clearBettingErrorData();
        this.clearInvalidBetNumsInBettingLimitErrorPage();
        this.clearERR512Status();
    }
}