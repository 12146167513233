import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({providedIn:'root'})
export class DialogService {
    isShow = new Subject();
    isShowWalletAvailable = new Subject();
    
    constructor(){}

    showError(error:any, _duration:number=2500){
        this.isShow.next(
            JSON.stringify(
                {
                    title:'error-title',
                    description:`${error.message==='undefined'?'something-wrong-lbl':error.message}`,
                    status:'error',
                    duration:_duration,
                }
            )
        );
    }

    showSuccess(_description:string, _duration:number=2000){
        this.isShow.next(
            JSON.stringify(
                {
                    title:'success-title',
                    description:_description,
                    status:'success',
                    duration:_duration,
                }
            )
        );
    }

    showWarning(_description:string, _duration:number=2000){
        this.isShow.next(
            JSON.stringify(
                {
                    title:'warning-title',
                    description:_description,
                    status:'warning',
                    duration:_duration,
                }
            )
        );
    }

    showInfo(_description:string, _duration:number=2000){
        this.isShow.next(
            JSON.stringify(
                {
                    title:'info-title',
                    description:_description,
                    status:'info',
                    duration:_duration
                }
            )
        );
    }

    showGameMaintain(_description:string, _duration:number=2500){
        this.isShow.next(
            JSON.stringify(
                {
                    title:'game-maintain-title',
                    description:_description,
                    status:'warning',
                    duration:_duration
                }
            )
        );
    }

    showGameMaintainCollectDialog(_title:string, _description:string, _duration:number=2500){
        this.isShow.next(
            JSON.stringify(
                {
                    title:_title,
                    description:_description,
                    status:'',
                    duration:_duration,
                    dialogType:'gameMaintainCollect'
                }
            )
        );
    }
}