import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import PagesRoutingNames from 'src/app/@core/helper/pages-routing-names.helper';

@Component({
    selector:'features-panel-component',
    templateUrl:'./features-panel.component.html',
    styleUrls:['./features-panel.component.scss']
})
export class FeaturesPanelComponent {
    @Input() features:Array<any>|any;
    @Input() isLoggedIn:boolean|any;

    constructor(private router:Router) {}
    
    goLogin(){
        this.router.navigate([`/${PagesRoutingNames.MY_AUTH}`]);
    }
}