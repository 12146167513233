import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector:'game-card',
    templateUrl:'./game-card.component.html',
    styleUrls:['../../../pages/home/home.component.scss']
})
export class GameCardComponent implements OnInit{
    @Input() imgsrc:any;
    @Input() imgContainerWidth:any;
    @Input() imgHeight:any;

    constructor(){}

    ngOnInit(): void {}
}