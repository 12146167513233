<div class="services-and-hotlines-panel">
    <!-- <label class="service-title-lbl">
        {{'custom-serve-hotline-title' | translate}}
    </label> -->
    <div class="service-label-24hrs-container">
        <label>
            {{'24-hrs-hl-lbl' | translate}}
        </label>
    </div>

    <div class="contact-nums-container">
        <contact-phones-component [phoneNumbers]="phoneNumbers"></contact-phones-component>
    </div>

    <div class="social-media-btns-container">
        <social-medias-component></social-medias-component>
    </div>
    
</div>