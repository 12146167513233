<div class="game-maintain-card"
*ngFor="let matAlt of maintainAlerts;let i=index;"
[id]="'game-maintain-card'+i">
    <div class="d-flex justify-content-start align-items-center flex-wrap">
        <div class="p-2 game-maintain-card-first-p-2"></div>
        <div class="p-2 flex-grow-1 game-maintain-card-title">{{'maintain-alert-title' | translate}}</div>
    </div>

    <div class="d-flex justify-content-start align-items-center">
        <div class="p-2">
            <img src="../../../assets/imgs/home/maintin_game_images/dollar.gif" style="height: 38px;">
        </div>
        <div class="p-2 flex-grow-1 game-maintain-card-content">
            <span class="game-maintain-card-content-text truncate-2"
            [id]="'game-maintain-card-content-text'+i">
            <span *ngIf="selectedLanguage==='eng'">The </span> <span style="font-weight: bold;">{{matAlt.main_game_name}}</span> {{'maintain-alert-content' | translate}}
            </span>

            <span [id]="'readmore'+i" class="read-more-span read-more-span-show" (click)="onReadMore(i)">
                {{'show-more-lbl' | translate}}
            </span>
            <span [id]="'readless'+i" class="read-more-span" (click)="onReadLess(i)">
                {{'show-less-lbl' | translate}}
            </span>
            
        </div>
    </div>

    <div class="d-flex justify-content-start align-items-center">
        <div class="p-2 game-maintain-card-first-p-2"></div>
        <div class="p-2 flex-grow-1">
            <button 
            class="btn btn-default game-maintain-card-action-btn"
            (click)="onCollect(matAlt.main_game_id)">{{'collect-lbl' | translate}}</button>
        </div>
    </div>
</div>