const PagesRoutingNames = {
    MY_AUTH:'my-auth',
    REGISTER:'register',
    DEFINE_PIN: 'define-pin',
    CHANGE_PIN:'change-pin',
    LOGIN:'login',
    LOGOUT:'logout',
    FORGET_PIN:'forget-pin',
    
    CHOOSE_LANGUAGE: 'choose-language',
    VERIFY_OTP:'verify-otp',
    NOTIFICATION:'notification',
    
    PAGES:'pages',
    HOME: 'home',
    HOME_WALLET: 'home-wallet',
    PROMOTIONS: 'promotions',
    PROMOTION_DETAIL:'promotion-detail',
    CONTACT: 'contact',
    PROFILE:'profile',
    EDIT_PROFILE:'edit-profile',
    LOTTERY_CLOSED_DAYS:'lottery-closed-days',

    WALLET_MANAGE:'wallet',
    WALLET_MANAGE_DETAIL:'detail',
    WALLET_MANAGE_HISTORY:'history',
    WALLET_MANAGE_TOPUP:'topup',
    WALLET_MANAGE_TRANSFER:'transfer',
    WALLET_MANAGE_WITHDRAW:'withdraw',

    TWO_D:'two-d', 
    TWO_D_HOME:'home',
    TWO_D_WINNERS:'winners',
    TWO_D_BET_HISTORY:'bet-history',
    TWO_D_RESULT_HISTORY:'result-history',
    TWO_D_BET_HISTORY_DETAIL:'two-d-bet-history-detail',
    NORMAL_BETTING:'normal-bet',
    MASTER_BETTING:'master-bet',
    THREE_D:'three-d', 
    THREE_D_HOME:'home',
    THREE_D_WINNERS:'winners',
    THREE_D_RESULT_HISTORY:'result-history',
    THREE_D_BET_HISTORY:'bet-history',
    THREE_D_BET_HISTORY_DETAIL:'three-d-bet-history-detail',
    NORMAL_BETTING_3D:'three-d-normal-bet',
    MASTER_BETTING_3D:'three-d-master-bet',
    OTHER_GAMES:'other-games',

    TUTORIALS:'tutorials',
    TERMS_CONS:'terms_conditions',
    DOWNLOAD_APP:'download-app',
    MLBB:'mobile-legends',
    INVITATION:'invitation',

    SPIN_LUCKY_DRAW:'spin-lucky-draw',
    SPIN_WINNERS:'spin-winners',
    SPIN_RESULT_HISTORY:'spin-result-history',
    SPIN_WHEEL:'spin-wheel',
    SPIN_RULES:'spin-rules',

    UNDER_MAINTAIN_PAGE:'under-maintain',
    MY_COMMISSION:'my-commission',
    MY_FRIEND_LIST:'my-friend-list',
    MY_TXN_HISTORY:'my-txn-history',
    NEW_OTHER_GAMES:'new-other-games',
    VIP_USER_CASHBACK:'vip-user-cashback',

    DUBAI_TWO_D:'dubai-two-d', 
    DUBAI_TWO_D_HOME:'dubai2d-home',
    DUBAI_TWO_D_WINNERS:'dubai2d-winners',
    DUBAI_TWO_D_BET_HISTORY:'dubai2d-bet-history',
    DUBAI_TWO_D_RESULT_HISTORY:'dubai2d-result-history',
    DUBAI_NORMAL_BETTING:'dubai2d-normal-bet',
    QUICK_SELECT_NUMBERS: 'quick-select-nums',
    BETTING_CONFIRM: 'betting-confirm',

    BETTING_LIMIT_ERROR_PAGE:'betting-limit-error-page', 

    SPLASH_SCREEN:'splash-screen',

    GAME_MAINTAIN_COLLECT_ALERT:'game-maintain-collect'
};

const FooterMainNavItems = [
    'home','home-wallet','promotions','contact','profile'
];

export default PagesRoutingNames;

export {
    FooterMainNavItems,
};