import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class MyTransactionHistoryService {
    private SWNW_URL = `${environment.API_ENDPOINT}/${environment.MOBILE_PATH}`;
    private DEFAULT_URL = `${environment.API_ENDPOINT}/${environment.DEFAULT_PATH}`;

    constructor(private http:HttpClient){}

    get2DWinHistory(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/twodwinninghistory`, payload);
    }
    
    get3DWinHistory(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/threedwinninghistory`, payload);
    }
    
    getTopupHistory(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/topuphistoryfortransactionpage`, payload);
    }
    
    getWithdrawHistory(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/withdrawhistoryfortransactionpage`, payload);
    }
    
    get2DDetail(payload:any):Observable<any>{
        return this.http.post(`${this.DEFAULT_URL}/lottery/twoddetailsbyinvoiceid`, payload);
    }

    get3DDetail(payload:any):Observable<any>{
        return this.http.post(`${this.DEFAULT_URL}/lottery/threeddetailsbyinvoiceid`, payload);
    }
    
    getRewardHistory(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/rewardhistory`, payload);
    }
    
    getOverAllTxnHistory(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/gettransactionhistory`, payload);
    }
}