import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class PromotionsService {
    private SWNW_URL = `${environment.API_ENDPOINT}/${environment.MOBILE_PATH}`;

    constructor(private http: HttpClient) {}

    getPromotions():Observable<any>{//28
        return this.http.post(`${this.SWNW_URL}/getpromotions`,null);
    }

    getPromotionDetail(payload:any):Observable<any>{//29
        return this.http.post(`${this.SWNW_URL}/promotiondetails`,payload);
    }
    
    getPromotionsForHome(payload:any):Observable<any>{//30
        return this.http.post(`${this.SWNW_URL}/getpromotionsforhome`,payload);
    }
    
    getPromotionDetailForHome(payload:any):Observable<any>{//31
        return this.http.post(`${this.SWNW_URL}/promotiondetailsforhome`,payload);
    }

    getAllHomePromotionTypes():Observable<any>{//50
        return this.http.post(`${this.SWNW_URL}/getallpromotiontypesforhome`,null);
    }
}