import { Component, Input, AfterViewInit } from "@angular/core";

@Component({
    selector:'utube-card-component',
    templateUrl:'./utube-card.component.html',
    styleUrls:['./utube-card.component.scss']
})
export class UTubeCardComponent implements AfterViewInit {
    @Input() backgroundImg:string | any;
    @Input() descriptionText:string | any;
    @Input() id:string | any;

    utubeIcon = '../../../../assets/imgs/wallet/v2/tutorials/youtube.svg';

    ngAfterViewInit(): void {
        const cardEl = <HTMLElement>document.querySelector(`#${this.id}`);
        
        if(cardEl) {
            cardEl.style.backgroundImage = `url(${this.backgroundImg})`;
            cardEl.style.backgroundSize = 'cover';
        }
    }
}