export const WalletTypes = {
    'mainwallet':1,
    'gamewallet':2,
    'kpay':3,
    'wavepay':4,
    1:'mainwallet',
    2:'gamewallet',
    3:'kpay',
    4:'wavepay',
}

export const WalletTypesList = [
    {label:'Main Wallet', value: 1},
    {label:'Game Wallet', value: 2},
    {label:'Kpay', value: 3},
    {label:'Wavepay/WaveMoney', value: 4},
];

export const WalletDefaultAmounts = [
    {label:'1,000', value:1000},
    {label:'2,000', value:2000},
    {label:'3,000', value:3000},

    //s
    {label:'4,000', value:4000},
    //e

    {label:'5,000', value:5000},

    //s
    {label:'6,000', value:6000},
    {label:'7,000', value:7000},
    {label:'8,000', value:8000},
    {label:'9,000', value:9000},
    //e

    {label:'10,000', value:10000},
    {label:'15,000', value:15000},
    {label:'20,000', value:20000},

    //s
    {label:'25,000', value:25000},
    //e

    {label:'30,000', value:30000},

    //s
    {label:'40,000', value:40000},
    //e

    {label:'50,000', value:50000},

    //s
    {label:'60,000', value:60000},
    {label:'70,000', value:70000},
    {label:'80,000', value:80000},
    {label:'90,000', value:90000},
    //e

    {label:'100,000', value:100000},
    //{label:'150,000', value:150000},
    {label:'200,000', value:200000},
    //s
    {label:'300,000', value:300000},
    {label:'400,000', value:400000},
    {label:'500,000', value:500000},
    {label:'600,000', value:600000},
    {label:'700,000', value:700000},
    {label:'800,000', value:800000},
    {label:'900,000', value:900000},
    {label:'1,000,000', value:1000000}
    //e
];

export const KpayToupLast6DigitsExplainImgs = [
    {
        path:'../../../assets/imgs/wallet/topup/kpay/img1.jpg',
    },
    {
        path:'../../../assets/imgs/wallet/topup/kpay/img2.jpg',
    },
    {
        path:'../../../assets/imgs/wallet/topup/kpay/img3.jpg',
    },
];
export const WavepayToupLast6DigitsExplainImgs = [
    {
        path:'../../../assets/imgs/wallet/topup/wavepay/img1.jpg',
    },
    {
        path:'../../../assets/imgs/wallet/topup/wavepay/img2.jpg',
    },
];

export const WalletList = [
    {label:'main-bal-lbl', value:1},
    {label:'game-bal-lbl', value:2},
];

const WalletAvailableImgPath_2d = '../../../assets/imgs/wallet/available_places/v2/2d.svg';
const WalletAvailableImgPath_3d = '../../../assets/imgs/wallet/available_places/v2/3d.svg';
const WalletAvailableImgPath_gameHandler = '../../../assets/imgs/wallet/available_places/v2/game-handler.svg';
const WalletAvailableImgPath_football = '../../../assets/imgs/wallet/available_places/v2/football.svg';
const WalletAvailableImgPath_topup = '../../../assets/imgs/wallet/available_places/v2/topup.svg';
const WalletAvailableImgPath_withdraw = '../../../assets/imgs/wallet/available_places/v2/withdraw.svg';
const WalletAvailableImgPath_transfer = '../../../assets/imgs/wallet/available_places/v2/up-arrow.svg';

export const MainWalletAvailablePlaces = {
    title:'main-wallet-available-title',
    description:[
        {
            id:1,
            icon:WalletAvailableImgPath_2d,
            text:'twod-title',
        },
        {
            id:2,
            icon:WalletAvailableImgPath_3d,
            text:'threed-title',
        },
        {
            id:3,
            icon:WalletAvailableImgPath_football,
            text:'football-lbl',
        },
        {
            id:4,
            icon:WalletAvailableImgPath_withdraw,
            text:'withdraw-lbl',
        },
        {
            id:5,
            icon:WalletAvailableImgPath_topup,
            text:'topup-lbl',
        },
        {
            id:6,
            icon:WalletAvailableImgPath_transfer,
            text:'transfer-2-game-wallet-lbl',
        },
    ],
}

export const GameWalletAvailablePlaces = {
    title:'game-wallet-available-title',
    description:[
        {
            id:1,
            icon:WalletAvailableImgPath_gameHandler,
            text:'kingmaker-lbl',
        },
        {
            id:2,
            icon:WalletAvailableImgPath_gameHandler,
            text:'big-games-lbl',
        },
        {
            id:4,
            icon:WalletAvailableImgPath_gameHandler,
            text:'red-tiger-lbl',
        }, 
        {
            id:3,
            icon:WalletAvailableImgPath_transfer,
            text:'transfer-2-main-wallet-lbl',
        },
    ],
}

export const MockTopupWithdrawHistories = [
    {
        "transactionid": 1349480,
        "wallet": 3,
        "amount": 10000,
        "externaltrxcode": "242219",
        "trxdecision": "Accepted",
        "createddate": "2022-10-16 14:31:23",
        "companyreceivingaccount": "09776550315"
    },
    {
        "transactionid": 1349480,
        "wallet": 4,
        "amount": 10000,
        "externaltrxcode": "242219",
        "trxdecision": "Accepted",
        "createddate": "2022-10-16 14:31:23",
        "companyreceivingaccount": "09776550315"
    },
    {
        "transactionid": 1349480,
        "wallet": 3,
        "amount": 10000,
        "externaltrxcode": "242219",
        "trxdecision": "Accepted",
        "createddate": "2022-10-16 14:31:23",
        "companyreceivingaccount": "09776550315"
    },
    {
        "transactionid": 1349480,
        "wallet": 3,
        "amount": 10000,
        "externaltrxcode": "242219",
        "trxdecision": "Accepted",
        "createddate": "2022-10-16 14:31:23",
        "companyreceivingaccount": "09776550315"
    },
];