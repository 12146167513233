import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class ClearCacheDoneService {
    private SWNW_URL = `${environment.API_ENDPOINT}/${environment.MOBILE_PATH}`;

    constructor(private http: HttpClient) {}
    
    clearCacheDone():Observable<any>{
        return this.http.post(`${this.SWNW_URL}/clearcache`,null);
    }
}