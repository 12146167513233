<div *ngIf="features" class="d-flex justify-content-between wallet-features-panel">

    <ng-container *ngIf="isLoggedIn">
        <div class="p-2 feature-p-2" *ngFor="let feature of features">
            <a [href]="feature.url">
                
                <div>
                    <img [src]="feature.image" alt="feature.name" class="feature-image">
                </div>
                <div class="w-f-p-label">
                    {{feature.localizationName | translate}}
                </div>
            </a>
        </div>
    </ng-container>

    <ng-container *ngIf="!isLoggedIn">
        <div class="p-2 feature-p-2" *ngFor="let feature of features">
            <a (click)="goLogin()">
                
                <div>
                    <img [src]="feature.image" alt="feature.name" class="feature-image">
                </div>
                <div class="w-f-p-label">
                    {{feature.localizationName | translate}}
                </div>
            </a>
        </div>
    </ng-container>
    
</div>