import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './@core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChooseLanguageComponent } from './choose-language/choose-language.component';
import { VerifyOTPComponent } from './verify-otp/verify-otp.component';
import { authInterceptorProviders } from './@core/utils/auth.interceptor';
import { TermsConsComponent } from './terms_cons/terms_cons.component';

import { CountdownModule } from 'ngx-countdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { NgxPaginationModule } from 'ngx-pagination';
import { UnderMaintainPageComponent } from './under-maintain-page/under-maintain-page.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';

import { GameMaintainCollectAlertComponent } from './game-maintain-collect-alerts/game-maintain-collect-alerts.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule, 
    CoreModule.forRoot(), 
    SharedModule, 
    HttpClientModule,
    CountdownModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader:{
        provide:TranslateLoader,
        useFactory:HttpLoaderFactory,
        deps:[HttpClient]
      }
    }),
    NgxPaginationModule,
  ],
  declarations: [
    AppComponent,
    ChooseLanguageComponent,
    VerifyOTPComponent,
    TermsConsComponent,

    UnderMaintainPageComponent, 
    SplashScreenComponent, 
    GameMaintainCollectAlertComponent
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http:HttpClient):TranslateHttpLoader{
  return new TranslateHttpLoader(http);
}
