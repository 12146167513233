import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class SpinLuckyDrawService {
    private SWNW_URL = `${environment.API_ENDPOINT}/${environment.MOBILE_PATH}`;

    constructor(private http:HttpClient){}

    getSpinValues():Observable<any>{
        return this.http.post(`${this.SWNW_URL}/getspinvalue`,null);
    }

    spin(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/spin`, payload);
    }

    getSpinResultHistory(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/spinhistory`, payload);
    }

    getSpinWinners(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/spinwinnerlist`, payload);
    }

    getSpinTimes():Observable<any>{
        return this.http.post(`${this.SWNW_URL}/getspintimes`,null);
    }

    getSpinRules(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/spinrules`, payload);
    }
}