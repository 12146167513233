<div class="d-flex justify-content-between align-items-center" style="margin-bottom: 15px;">
    
    <ng-container *ngIf="isLoggedIn">

        <!-- main wallet -->
        <div class="p-2 wallet-status-penal wallet-status-penal-main-wallet" style="padding: 11px 14px 5px 14px !important;">
            <div style="min-height:29.55px;">
                <img src="../../../../assets/imgs/home/v2/wallet-panel/bitcoin.svg" alt="main-wallet"
                class="main-wallet-info-img">
                <label class="wallet-bal-label wallet-lbl" (click)="onAvailabelPlaces('1')">
                    {{'main-bal-title' | translate}}
                </label>
            </div>
            
            <ng-container *ngIf="returnTargetComponent==='transfer';else NotFromTargetTransferMain">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="p-2 flex-grow-1 wallet-info-amt-container">
                        <label class="wallet-amt wallet-info-amt-label">{{mainWallet??0}} {{'mmk-lbl' | translate}}</label>
                    </div>
                </div>
            </ng-container>

            <ng-template #NotFromTargetTransferMain>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="p-2 flex-grow-1 wallet-info-amt-container">
                        <label class="wallet-amt wallet-info-amt-label">{{mainWallet??0}} {{'mmk-lbl' | translate}}</label>
                    </div>
                    <div class="p-2" style="padding: 0 !important;">
                        <img src="../../../../assets/imgs/home/v2/wallet-panel/add-wallet.svg" 
                        class="wallet-fill-icon" 
                        alt="add-wallet-btn"
                        (click)="onWalletFill('1')">
                    </div>
                </div>
            </ng-template>
        </div>

        <!-- game wallet -->
        <div class="p-2 wallet-status-penal wallet-status-penal-game-wallet" style="padding: 11px 14px 5px 14px !important;margin-left: 17px;">
            <div style="min-height:29.55px;">
                <img src="../../../../assets/imgs/home/v2/wallet-panel/gamepad.svg" alt="game-wallet" height="30"
                class="main-wallet-info-img">
                <label class="wallet-bal-label wallet-lbl" (click)="onAvailabelPlaces('2')">
                    {{'game-bal-title' | translate}}
                </label>
            </div>

            <ng-container *ngIf="returnTargetComponent==='transfer';else NotFromTargetTransferGame">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="p-2 flex-grow-1 wallet-info-amt-container">
                        <label class="wallet-amt wallet-info-amt-label">{{gameWallet??0}} {{'mmk-lbl' | translate}}</label>
                    </div>
                </div>
            </ng-container>

            <ng-template #NotFromTargetTransferGame>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="p-2 flex-grow-1 wallet-info-amt-container">
                        <label class="wallet-amt wallet-info-amt-label">{{gameWallet??0}} {{'mmk-lbl' | translate}}</label>
                    </div>
                    <div class="p-2" style="padding: 0 !important;">
                        <img src="../../../../assets/imgs/home/v2/wallet-panel/add-wallet.svg" 
                        class="wallet-fill-icon" 
                        alt="add-wallet-btn"
                        (click)="onWalletFill('2')">
                    </div>
                </div>
            </ng-template>
            
        </div>
    </ng-container>

    <ng-container *ngIf="!isLoggedIn">
        <div class="p-2 wallet-status-penal wallet-status-penal-main-wallet" style="padding: 11px 14px 5px 14px !important;">
            <div style="min-height:29.55px;">
                <img src="../../../../assets/imgs/home/v2/wallet-panel/bitcoin.svg" alt="main-wallet"
                class="main-wallet-info-img">
                <label class="wallet-bal-label wallet-lbl" (click)="onAvailabelPlaces('1')">
                    {{'main-bal-title' | translate}}
                </label>
            </div>
            
            <div class="d-flex justify-content-between align-items-center">
                <div class="p-2 flex-grow-1 wallet-info-amt-container">
                    <label class="wallet-amt wallet-info-amt-label">0 {{'mmk-lbl' | translate}}</label>
                </div>
                <div class="p-2" style="padding: 0 !important;">
                    <img src="../../../../assets/imgs/home/v2/wallet-panel/add-wallet.svg" 
                    class="wallet-fill-icon" 
                    alt="add-wallet-btn"
                    (click)="goLogin()">
                </div>
            </div>
        </div>

        <div class="p-2 wallet-status-penal wallet-status-penal-game-wallet" style="padding: 11px 14px 5px 14px !important;margin-left: 17px;">
            <div style="min-height:29.55px;">
                <img src="../../../../assets/imgs/home/v2/wallet-panel/gamepad.svg" alt="game-wallet"  height="30"
                class="main-wallet-info-img">
                <label class="wallet-bal-label wallet-lbl" (click)="onAvailabelPlaces('2')">
                    {{'game-bal-title' | translate}}
                </label>
            </div>

            <div class="d-flex justify-content-between align-items-center">
                <div class="p-2 flex-grow-1 wallet-info-amt-container">
                    <label class="wallet-amt wallet-info-amt-label">0 {{'mmk-lbl' | translate}}</label>
                </div>
                <div class="p-2" style="padding: 0 !important;">
                    <img src="../../../../assets/imgs/home/v2/wallet-panel/add-wallet.svg" 
                    class="wallet-fill-icon" 
                    alt="add-wallet-btn"
                    (click)="goLogin()">
                </div>
            </div>

        </div>
    </ng-container>
</div>