<div class="swnw-mobile-container">
      <swnw-header 
      [navTitleOnHiding]="titleOnHeaderHiding"
      [isHide]="isHidedHeaderFooter" 
      (onBack)="onHeaderBack()"
      [isHeaderIconsHideAll]="isHeaderIconsHideAll"
      [imgTitle]="t23DTitleImg"
      [selectedLanguage]="selectedLanguageOnSpecifiedPages"
      [isSplashScreenShowing]="isSplashScreenShowing">
      </swnw-header>
      
      <div id="swnw-body">
        <div class="body-container" 
        [ngStyle]="{'padding': isSplashScreenShowing ? '0':'4px 12px 0 12px'}">
          <ng-container *ngIf="!isSplashScreenShowing">
            <ng-container *ngIf="!isHideProfileHeaderPanel">
              <profile-header-panel
              [editMode]="isProfileEditMode" 
              [profileInfo]="userProfileInfo" 
              [selectedLanguage]="selectedLanguage"
              (onHideHeaderFooter)="onHideHeaderAndFooter($event)"
              [isSplashScreenShowing]="isSplashScreenShowing">
              </profile-header-panel>
            </ng-container>
          </ng-container>
    
          <router-outlet></router-outlet>
    
          <div id="mySpinner" class="swnw-spinner">
            <div class="spinner-border text-warning"></div>
          </div>
        </div>
      </div>
      
      <swnw-footer 
      [initSelectedItemName]="selectedFooterNavItemName" 
      [isHide]="isHidedHeaderFooter"
      [isFooterHideOnly]="isFooterHideOnly">
      </swnw-footer>
</div>
    
<!-- show message dialog -->
<div id="myModal" class="swnw-modal">
  <div class="swnw-modal-content" 
    [ngStyle]="{'max-width':deviceInnerWidth>=446?'410px': '300px'}">
      <div class="modal-header">
        <div style="width: 100%;">{{modalTitle|translate}}</div>
        <span class="swnw-close">&times;</span>
      </div>
      <div class="modal-body" [ngStyle]="{'text-align':modalDescription.length<=40?'center':'start'}">
        <label>{{modalDescription|translate}}</label>
      </div>
    </div>
</div>

<!-- main/game wallet available dialog -->
<div id="myBalModal" class="swnw-modal">
  <div class="swnw-modal-content" [ngStyle]="{'max-width':deviceInnerWidth>=446?'410px': '300px'}">
    <div class="modal-header">
      <div class="title-container">
          <label class="header-lbl">
            {{availableModalData.title | translate}}
          </label>
      </div>
    </div>
    <div class="modal-body">
      <div class="d-flex align-items-center" *ngFor="let des of availableModalData.description" [id]="des.id">
          <div class="p-2">
              <img [src]="des.icon" alt="football">
          </div>
          <div class="p-2" class="description-txt">{{des.text | translate}}</div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-default footer-btn" (click)="onCloseAvailableModal()">
          {{'ok-lbl' | translate}}
      </button>
    </div>
  </div>
</div>

<!-- show game-maintain collect message dialog -->
<div id="myGameMaintainModal" class="swnw-modal">
  <div class="swnw-modal-content" 
    [ngStyle]="{'max-width':deviceInnerWidth>=446?'410px': '300px'}">
      <div class="modal-header" 
      style="justify-content: center !important;background-color: transparent !important;color: #121212 !important;">
        <h5 style="font-weight: bold;color:#F12319">{{modalTitle|translate}}!</h5>
      </div>

      <div class="modal-body" style="text-align: center !important;font-weight: normal !important;">
        <label>{{modalDescription|translate}}</label>
      </div>

      <div class="modal-footer">
        <button class="btn btn-default game-maintain-card-action-btn" style="border-radius: 7px !important;"
        (click)="onCloseGameMaintainModal()">OK</button>
      </div>
    </div>
</div>
  
