import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { DatePipe } from "@angular/common";

@Component({
    selector:'my-filters-component',
    templateUrl:'./filters.component.html',
    styleUrls:['./filters.component.scss']
})
export class FiltersComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() startDate:Date|any;
    @Input() endDate:Date|any;

    @Output() onFiltered:EventEmitter<any>=new EventEmitter();
    @Output() onColExpFilter:EventEmitter<any>=new EventEmitter();

    isFilterLinkIconUp:boolean|any;
    start:Date|any;
    end:Date|any;

    selected_start = {
        start: new Date(new Date().setDate(new Date().getDate() - 7))
    }
    selected_end = {
        end: new Date()
    }

    //for radio filters
    @Input() radioFilterData:any;
    selectedRadioFilterValue:any;

    toid1:any;
    toid2:any;

    deviceWidth:number|any;

    @Input() isInitFilterEvent:boolean|any;

    constructor(private datePipe:DatePipe){
        this.isFilterLinkIconUp = true;

        this.deviceWidth = window.innerWidth;
    }

    ngOnInit(): void {
        if(this.startDate) this.selected_start.start = new Date(this.startDate);
        
        if(this.endDate) this.selected_end.end = new Date(this.endDate);
    }

    ngAfterViewInit(): void {
        if(this.isInitFilterEvent) {
            const filterBtn = <HTMLElement>document.querySelector('.filter-btn');
            filterBtn?.click();
        }
    }

    ngOnDestroy(): void {
        if(this.toid1) { clearTimeout(this.toid1)}
        if(this.toid2) { clearTimeout(this.toid2)}
    }
    
    onClickFilterLink(){
        this.isFilterLinkIconUp = !this.isFilterLinkIconUp;
        if(this.isFilterLinkIconUp){
            const cardHeader  = <HTMLElement>document.querySelector('.card-header');
            if(cardHeader) {
                cardHeader.style.borderTopLeftRadius = '5px';
                cardHeader.style.borderTopLeftRadius = '5px';

                cardHeader.style.borderBottomLeftRadius = '0';
                cardHeader.style.borderBottomRightRadius = '0';
            }
        }else{
            const cardHeader  = <HTMLElement>document.querySelector('.card-header');
            if(cardHeader) {
                this.toid1 = setTimeout(() => {
                    cardHeader.style.borderRadius = '5px';
                }, 300);
            }
        }

        this.onColExpFilter.emit();
    }

    onFilter(){
        const start = this.datePipe.transform(this.selected_start.start, 'yyyy-MM-dd');
        const end = this.datePipe.transform(this.selected_end.end, 'yyyy-MM-dd');

        if(this.selectedRadioFilterValue){
            this.onFiltered.emit({start:start, end:end, selected_radio_value: this.selectedRadioFilterValue});
        }else{
            this.onFiltered.emit({start:start, end:end});
        }
    }

    // for radio filters
    onChangeRadioFilter(evt:any){
        this.selectedRadioFilterValue = evt.target.value;

        const start = this.datePipe.transform(this.selected_start.start, 'yyyy-MM-dd');
        const end = this.datePipe.transform(this.selected_end.end, 'yyyy-MM-dd');
        this.toid2 = setTimeout(() => {
            this.onFiltered.emit({start:start, end:end, selected_radio_value: this.selectedRadioFilterValue});
        }, 0);
    }
}