<ng-container *ngIf="!Is2dRounds">
    <div *ngIf="isShow;else showDefImgs"
    class="keen-slider" #sliderRef
    [ngStyle]="{'height':targetName==='splashscreen' ? '100%':'', 'border-radius':targetName==='splashscreen' ? '0':''}">
        <div 
        class="keen-slider__slide" 
        *ngFor="let image of images" 
        style="cursor: pointer;" 
        (click)="onSliderClick(image.actionLink)">
            <img class="c-img" [src]="image.path" alt="home-promo-image"
            [ngStyle]="{'height':targetName==='splashscreen' ? '100%':'', 'border-radius':targetName==='splashscreen' ? '0':''}">
        </div>
    </div>

    <ng-template #showDefImgs>
        <div class="blink_me default-slider"
        [ngStyle]="{'height':targetName==='splashscreen' ? '100%':'', 'border-radius':targetName==='splashscreen' ? '0':''}"></div>
    </ng-template>
</ng-container>

<ng-container *ngIf="Is2dRounds">
    <div *ngIf="isShow;else showDefRounds" class="keen-slider" #sliderRef>
        <div 
        *ngFor="let item of twodRounds" 
        class="keen-slider__slide" 
        style="cursor: pointer;padding:5px;" 
        (click)="onSliderClick(item.value,true)">
            <button class="btn btn-default" 
            [ngClass]="item.value != activeRoundButton ? 'no-active-round-btn':''"
            [ngStyle]="{'outline' : item.value == activeRoundButton ? '2.5px solid white' : ''}">{{item.label}}</button>
        </div>
    </div>

    <ng-template #showDefRounds>
        <div class="keen-slider" #sliderRef>
            <div class="keen-slider__slide blink_me" style="padding:5px;font-size: 0 !important;">
                <button class="btn btn-default">...</button>
            </div>

            <div class="keen-slider__slide blink_me" style="padding:5px;font-size: 0 !important;">
                <button class="btn btn-default">...</button>
            </div>

            <div class="keen-slider__slide blink_me" style="padding:5px;font-size: 0 !important;">
                <button class="btn btn-default">...</button>
            </div>
        </div>
    </ng-template>
</ng-container>