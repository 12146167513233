import { Component, Input } from "@angular/core";

@Component({
    selector:'top-3-winner-card-component',
    templateUrl: './top-3-winner-card.component.html',
    styleUrls: ['./top-3-winner-card.component.scss']
})
export class Top3WinnerCardComponent {
    @Input() winner:any;
    @Input() topNumber:string|any;
}