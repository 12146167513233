import { Component, AfterViewInit, OnDestroy } from "@angular/core";

@Component({
    templateUrl:'./under-maintain-page.component.html',
    styleUrls:['./under-maintain-page.component.scss']
})
export class UnderMaintainPageComponent implements AfterViewInit, OnDestroy {

    toid1:any;

    constructor(){}

    ngAfterViewInit(): void {
        this.toid1 = setTimeout(() => {
            //text-container
            const el = <HTMLElement>document.querySelector('.text-container');
            if(el){
                el.style.height = `${window.innerHeight-130}px`;
            }
        }, 10);
        
    }

    ngOnDestroy(): void {
        if(this.toid1) { clearTimeout(this.toid1)}
      }
    
}