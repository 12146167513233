import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";

import { Router } from "@angular/router";
import { AuthUtilsService } from "src/app/@core/utils";

import PagesRoutingNames from "src/app/@core/helper/pages-routing-names.helper";

const FooterMenuIconPath = '../../../../assets/imgs/home/v2/footer';
const fileType = '.svg';
const ActiveImg = `-active${fileType}`;
const UnActiveImg = `-unactive${fileType}`;

@Component({
    selector:'swnw-footer',
    templateUrl:'./footer.component.html',
    styleUrls:['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges, OnDestroy {
    @Input() initSelectedItemName:string | any;
    @Input() isHide:boolean|any;
    @Input() isFooterHideOnly:boolean|any;

    isHome:boolean;
    isWallet:boolean;
    isPromotions:boolean;
    isSpinWheel:boolean;
    isProfile:boolean;

    isHideFooter:boolean=false;

    pageRouteNames;

    loggedinInfo:boolean|any;

    homeImg:string|any;
    walletImg:string|any;
    promotionImg:string|any;
    spinWheelImg:string|any;
    profileImg:string|any;

    toid1:any;
    
    constructor(
        private route: Router,
        private authUtilsService:AuthUtilsService,
    ){
        this.isHome=false;
        this.isWallet=false;
        this.isPromotions=false;
        this.isSpinWheel=false;
        this.isProfile=false;

        this.pageRouteNames = PagesRoutingNames;

        this.spinWheelImg = `${FooterMenuIconPath}/spinwheel${fileType}`;
        this.setActiveMenuState('home');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(this.initSelectedItemName) this.selectedItemUIUpdate(this.initSelectedItemName);
    }

    ngOnInit(): void {
        if(this.initSelectedItemName) this.selectedItemUIUpdate(this.initSelectedItemName);
    }

    ngOnDestroy(): void {
        if(this.toid1) {clearTimeout(this.toid1)}
    }

    onFooterItemSelected(selectedItemName:string){
        this.selectedItemUIUpdate(selectedItemName);
        
        this.toid1 = setTimeout(() => {
            this.route.navigate([`/pages/${selectedItemName}`]);
        }, 0);
    }

    setActiveMenuState(target:string) {
        switch(target) {
            case 'home': {
                this.homeImg = `${FooterMenuIconPath}/home${ActiveImg}`;
                
                this.walletImg = `${FooterMenuIconPath}/wallet${UnActiveImg}`;
                this.promotionImg = `${FooterMenuIconPath}/promotion${UnActiveImg}`;
                this.profileImg = `${FooterMenuIconPath}/profile${UnActiveImg}`;

                break;
            }
            case 'wallet': {
                this.walletImg = `${FooterMenuIconPath}/wallet${ActiveImg}`;

                this.homeImg = `${FooterMenuIconPath}/home${UnActiveImg}`;
                this.promotionImg = `${FooterMenuIconPath}/promotion${UnActiveImg}`;
                this.profileImg = `${FooterMenuIconPath}/profile${UnActiveImg}`;

                break;
            }
            case 'promotion': {
                this.promotionImg = `${FooterMenuIconPath}/promotion${ActiveImg}`;

                this.homeImg = `${FooterMenuIconPath}/home${UnActiveImg}`;
                this.walletImg = `${FooterMenuIconPath}/wallet${UnActiveImg}`;
                this.profileImg = `${FooterMenuIconPath}/profile${UnActiveImg}`;

                break;
            }case 'profile': {
                this.profileImg = `${FooterMenuIconPath}/profile${ActiveImg}`;

                this.promotionImg = `${FooterMenuIconPath}/promotion${UnActiveImg}`;
                this.homeImg = `${FooterMenuIconPath}/home${UnActiveImg}`;
                this.walletImg = `${FooterMenuIconPath}/wallet${UnActiveImg}`;

                break;
            }
        }
    }

    selectedItemUIUpdate(itemName:string){
        switch(itemName){
            case PagesRoutingNames.HOME:{
                this.isHome=true;

                this.isWallet=false;
                this.isPromotions=false;
                this.isSpinWheel=false;
                this.isProfile=false;

                this.setActiveMenuState('home');
                break;
            }
            case PagesRoutingNames.HOME_WALLET:{
                this.isWallet=true;

                this.isHome=false;
                this.isPromotions=false;
                this.isSpinWheel=false;
                this.isProfile=false;

                this.setActiveMenuState('wallet');
                break;
            }
            case PagesRoutingNames.PROMOTIONS:{
                this.isPromotions=true;

                this.isWallet=false;
                this.isHome=false;
                this.isSpinWheel=false;
                this.isProfile=false;

                this.setActiveMenuState('promotion');
                break;
            }
            /* case PagesRoutingNames.SPIN_LUCKY_DRAW:{
                this.isSpinWheel=true;

                this.isWallet=false;
                this.isHome=false;
                this.isPromotions=false;
                this.isProfile=false;
                break;
            } */
            case PagesRoutingNames.PROFILE:{
                this.isProfile=true;

                this.isWallet=false;
                this.isHome=false;
                this.isPromotions=false;
                this.isSpinWheel=false;

                this.setActiveMenuState('profile');
                break;
            }
        }
    }
}