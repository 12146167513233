import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class WalletService {
    private SWNW_URL = `${environment.API_ENDPOINT}/${environment.MOBILE_PATH}`;

    constructor(private http: HttpClient) {}
    
    createReceivingAccount(payload:any):Observable<any>{//12
        return this.http.post(`${this.SWNW_URL}/createreceivingaccount`,payload);
    }
    checkWithdrawPending():Observable<any>{//45
        return this.http.post(`${this.SWNW_URL}/checkwithdrawpending`,null);
    }
    checkReceivingAccountDuplicate(payload:any):Observable<any>{//47
        return this.http.post(`${this.SWNW_URL}/checkreceivingaccountisduplicate`,payload);
    }
    getSWNWReceivingAccounts(payload:any):Observable<any>{//32
        return this.http.post(`${this.SWNW_URL}/swnwreceivingaccounts`,payload);
    }
    checkTopupPending():Observable<any>{//44
        return this.http.post(`${this.SWNW_URL}/checktopuppending`,null);
    }
    topup(payload:any):Observable<any>{//9
        return this.http.post(`${this.SWNW_URL}/topup`,payload);
    }
    transfer(payload:any):Observable<any>{//10
        return this.http.post(`${this.SWNW_URL}/transfer`,payload);
    }
    getWithdrawHistory(payload:any):Observable<any>{//14
        return this.http.post(`${this.SWNW_URL}/withdrawhistory`,payload);
    }
    getTopupHistory(payload:any):Observable<any>{//15
        return this.http.post(`${this.SWNW_URL}/topuphistory`,payload);
    }
    getBalanceInfoDetail():Observable<any>{//6
        return this.http.post(`${this.SWNW_URL}/balanceinfodetails`,null);
    }
    getReceivingAccount(payload:any):Observable<any>{//13
        return this.http.post(`${this.SWNW_URL}/getreceivingaccount`,payload);
    }
    withdraw(payload:any):Observable<any>{//11
        return this.http.post(`${this.SWNW_URL}/withdraw`,payload);
    }
    updateReceiveAccount(payload:any):Observable<any>{//56
        return this.http.post(`${this.SWNW_URL}/updatereceivingaccount`, payload);
    }
    preWithdrawCheck(payload:any):Observable<any>{//57
        return this.http.post(`${this.SWNW_URL}/prewithdraw`,payload);
    }
    preUpdateReceiveAccountCheck(payload:any):Observable<any>{//58
        return this.http.post(`${this.SWNW_URL}/preupdatereceivingaccount`, payload);
    }

    getTutorialsData(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/gettutorialimages`, payload);
    }
}
