interface IBetNumberViewData{
    number:string;
    trending:number;
    trendingWidth:string;
    remainAmount:number;
    trendColor:string;
    betAmount:number;
};

const TwoDOrders = {
    EVEN_EVEN:'ee',
    EVEN_ODD:'eo',
    ODD_EVEN:'oe',
    ODD_ODD:'oo',
};

const ColorDefinition = {
    title:'color-defi-lbl',
    subTitle:'will-close-on-trend-100-lbl',
    description:[
        {
            id:1,
            color:'green',
            description:'below-50-lbl',
        },
        {
            id:2,
            color:'gold',
            description:'within-50-90-lbl',
        },
        {
            id:3,
            color:'red',
            description:'above-90-lbl',
        },
        {
            id:4,
            color:'aqua',
            description:'full-100-lbl',
        },
    ],
}

const ClosedDays = [
    {date:'2023-12-29', description:'Substitution for New Year\'s Eve (Sunday 31 December 2023) (added)'},
    {date:'2024-01-01', description:'New Year\'s Day'},
    {date:'2024-02-26', description:'Substitution for Makha Bucha Day (Saturday 24 February 2024)'},
    {date:'2024-04-08', description:'Substitution for Chakri Memorial Day (Saturday 6 April 2024)'},
    {date:'2024-04-12', description:'Additional Special Holiday (Added)'},
    {date:'2024-04-15', description:'Songkran Festival'},
    {date:'2024-04-16', description:'Substitution for Songkran Festival (Saturday 13 April 2024 and Sunday 14 April 2024)'},
    {date:'2024-05-01', description:'National Labour Day'},
    {date:'2024-05-06', description:'Substitution for Coronation Day (Saturday 4 May 2024)'},
    {date:'2024-05-22', description:'Visakha Bucha Day'},
    {date:'2024-06-03', description:'H.M. Queen Suthida Bajrasudhabimalalakshana\'s Birthday'},
    {date:'2024-07-22', description:'Substitution for Asarnha Bucha Day (Saturday 20 July 2024)'},
    {date:'2024-07-29', description:'Substitution for H.M. King Maha Vajiralongkorn Phra Vajiraklaochaoyuhua\'s Birthday (Sunday 28 July 2024)'},
    {date:'2024-08-12', description:'H.M. Queen Sirikit The Queen Mother\'s Birthday \/ Mother\'s Day'}, 
    {date:'2024-10-14', description:'Substitution for H.M. King Bhumibol Adulyadej The Great Memorial Day (Sunday 13 October 2024)'}, 
    {date:'2024-10-23', description:'Chulalongkorn Day'},
    {date:'2024-12-05', description:'H.M. King Bhumibol Adulyadej The Great\'s Birthday\/ National Day \/ Father\'s Day'},
    {date:'2024-12-10', description:'Constitution Day'},
    {date:'2024-12-31', description:'New Year\'s Eve'}
];

const SingleAndDoubleNums = [
    {label:'big-lbl', value:'big'},
    {label:'small-lbl', value:'small'},
    {label:'odd-lbl', value:'odd'},
    {label:'even-lbl', value:'even'},
    {label:'ee-lbl', value:TwoDOrders.EVEN_EVEN},
    {label:'eo-lbl', value:TwoDOrders.EVEN_ODD},
    {label:'oe-lbl', value:TwoDOrders.ODD_EVEN},
    {label:'oo-lbl', value:TwoDOrders.ODD_ODD},
    {label:'same-lbl', value:'apoo'},
];
const TwentySelectionNums = [
    '00-19','20-39','40-59','60-79','80-99'
];

const SameThreeNums = [
    {label:'000', value:0},
    {label:'111', value:1},
    {label:'222', value:2},
    {label:'333', value:3},
    {label:'444', value:4},
    {label:'555', value:5},
    {label:'666', value:6},
    {label:'777', value:7},
    {label:'888', value:8},
    {label:'999', value:9}
];
const OneHundredSelectionNums = [
    {label:'000-099', value:{from:0, to:100}},
    {label:'100-199', value:{from:100, to:200}},
    {label:'200-299', value:{from:200, to:300}},
    {label:'300-399', value:{from:300, to:400}},
    {label:'400-499', value:{from:400, to:500}},
    {label:'500-599', value:{from:500, to:600}},
    {label:'600-699', value:{from:600, to:700}},
    {label:'700-799', value:{from:700, to:800}},
    {label:'800-899', value:{from:800, to:900}},
    {label:'900-999', value:{from:900, to:1000}}
];

const SameTwoNums = [
    {label:'00', value:0},
    {label:'11', value:1},
    {label:'22', value:2},
    {label:'33', value:3},
    {label:'44', value:4},
    {label:'55', value:5},
    {label:'66', value:6},
    {label:'77', value:7},
    {label:'88', value:8},
    {label:'99', value:9}
];

export {
    TwoDOrders,
    ColorDefinition,
    ClosedDays,
    SingleAndDoubleNums,
    TwentySelectionNums,
    SameThreeNums,
    OneHundredSelectionNums,
    IBetNumberViewData,
    SameTwoNums,
}