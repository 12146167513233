import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector:'result-number-panel-component',
    templateUrl:'./result-number-panel.component.html',
    styleUrls:['./result-number-panel.component.scss']
})
export class ResultNumberPanelComponent {
    @Input() resultData:any;

    @Output() onFlipToggle = new EventEmitter();

    constructor() {}

    onFlipClick() {
        this.onFlipToggle.emit();
    }
}