import { Injectable } from "@angular/core";

import { 
    TwoDOrders, 
    ColorDefinition, 
} from "../helper/two-three-d.helper";

import PagesRoutingNames from "../helper/pages-routing-names.helper";

@Injectable({providedIn:'root'})
export class LotteryUtilsServices {
    checkEvenOddWithOrder(num:string, order:string){
        let {Fnum,Snum}=this.getFirstSecondNumStr(num);
        let result;

        switch(order){
            case TwoDOrders.EVEN_EVEN:{
                result=(+Fnum%2===0 && +Snum%2===0);
                break;
            }
            case TwoDOrders.EVEN_ODD:{
                result=(+Fnum%2===0 && +Snum%2!==0);
                break;
            }
            case TwoDOrders.ODD_EVEN:{
                result=(+Fnum%2!==0 && +Snum%2===0);
                break;
            }
            case TwoDOrders.ODD_ODD:{
                result=(+Fnum%2!==0 && +Snum%2!==0);
                break;
            }
        };
        return result;
    }

    getSingleDigitContain(num:string, singleTarget:string, position:string=''){
        if(position){
            let {Fnum,Snum}=this.getFirstSecondNumStr(num);
            return position==='head'? singleTarget===Fnum:singleTarget===Snum;
        }
        return num.includes(singleTarget);
    }

    getConstelPowerNums(num:string|any, targets:string){
        let tmp = targets.split(',');
        return tmp.indexOf(num)>=0;
    }

    getTwentySelectionNums(num:string|any, targets:string){
        const tmp = targets.split('-');
        
        return (+num>=+tmp[0] && +num<=+tmp[1]);
    }

    getSingleNum(num:string){
        if(num.length===1 && /[0-9]/.test(num)){
            return num;
        }else if(num.includes('head-') || num.includes('tail-')){
            const tmp = num.split('-');
            return tmp[1];
        }
        return '-1';
    }

    getReverse2D(num:string) {
        let {Fnum,Snum}=this.getFirstSecondNumStr(num);

        if(Fnum===Snum) return `${Fnum}${Snum}`;

        let tmp = Fnum;
        Fnum = Snum;
        Snum = tmp;
        return `${Fnum}${Snum}`;
    }

    getFirstSecondNumStr(num:string){
        let fnum = num.slice(0,1); // '01' => '0' '1'
        let snum = num.slice(1,2);
        
        return {Fnum: fnum, Snum: snum};
    }

    checkClosedNumsByAdmin(num:string, targetArr:any){
        return (<Array<any>>targetArr).find((x:any)=>x.no===num)?true:false;
    }

    getTrendColor(value:number):string|any{
        if(value < 50) return ColorDefinition.description[0].color;
        else if(value >= 50 && value <= 90) return ColorDefinition.description[1].color;
        else if(value > 90 && value < 100) return ColorDefinition.description[2].color;
        else if(value>=100) return ColorDefinition.description[3].color;
    }

    get3DigitsR(num:string){//'124'=> 142, 412, 214, 241, 421
        const t = num.split('');
        
        let result = [num];
        
        const t1 = this.getReverse2D(`${t[1]}${t[2]}`);
        result.push(`${t[0]}${t1}`);
        
        const tt1 = `${t[1]}${t[0]}${t[2]}`;
        result.push(tt1);

        const t2 = this.getReverse2D(`${t[0]}${t[2]}`);
        result.push(`${t[1]}${t2}`);
        
        const tt2 = `${t[2]}${t[0]}${t[1]}`;
        result.push(tt2);

        const t3 = this.getReverse2D(`${t[0]}${t[1]}`);
        result.push(`${t[2]}${t3}`);
        
        //remove duplicate elements from an array
        result = result.filter((v,i)=> result.indexOf(v)===i);
        return result;
    }

    getFeatureRoute(name:string, target:number|any):string|any{
        if(target===2){
            switch(name){
                case PagesRoutingNames.TWO_D_WINNERS:{
                    return `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.TWO_D}/${PagesRoutingNames.TWO_D_WINNERS}`;
                }
                case PagesRoutingNames.TWO_D_BET_HISTORY:{
                    return `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.TWO_D}/${PagesRoutingNames.TWO_D_BET_HISTORY}`;
                }
                case PagesRoutingNames.TWO_D_RESULT_HISTORY:{
                    return `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.TWO_D}/${PagesRoutingNames.TWO_D_RESULT_HISTORY}`;
                }
                case PagesRoutingNames.LOTTERY_CLOSED_DAYS:{
                    return `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.LOTTERY_CLOSED_DAYS}`;
                }
            }
        } else if(target === 'dubai2d') {
            switch(name){
                case PagesRoutingNames.DUBAI_TWO_D_WINNERS:{
                    return `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.DUBAI_TWO_D}/${PagesRoutingNames.DUBAI_TWO_D_WINNERS}`;
                }
                case PagesRoutingNames.DUBAI_TWO_D_BET_HISTORY:{
                    return `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.DUBAI_TWO_D}/${PagesRoutingNames.DUBAI_TWO_D_BET_HISTORY}`;
                }
                case PagesRoutingNames.DUBAI_TWO_D_RESULT_HISTORY:{
                    return `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.DUBAI_TWO_D}/${PagesRoutingNames.DUBAI_TWO_D_RESULT_HISTORY}`;
                }
            }

        } else{
            switch(name){
                case PagesRoutingNames.THREE_D_WINNERS:{
                    return `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.THREE_D}/${PagesRoutingNames.THREE_D_WINNERS}`;
                }
                case PagesRoutingNames.THREE_D_BET_HISTORY:{
                    return `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.THREE_D}/${PagesRoutingNames.THREE_D_BET_HISTORY}`;
                }
                case PagesRoutingNames.THREE_D_RESULT_HISTORY:{
                    return `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.THREE_D}/${PagesRoutingNames.THREE_D_RESULT_HISTORY}`;
                }
                case PagesRoutingNames.LOTTERY_CLOSED_DAYS:{
                    return `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.LOTTERY_CLOSED_DAYS}`;
                }
            }
        }
    }

    get2DigitsR(num:string){
        let result:any = [];
        const rnum = this.getReverse2D(num);
        result.push(rnum);

        //remove duplicate elements from an array
        result = result.filter((v:any,i:number)=> result.indexOf(v)===i);
        return result;
    }

    getKweNumbers(numbers:Array<string>) {
        let result:Array<any> = [];

        result = [...(<any>numbers.map((x:any) => 
            numbers.map((y:any) => x !== y && `${x}${y}`)
        )).flat().filter((z:any) => z)];
        
        return result;
    }
}