import { Injectable } from "@angular/core";

import { DeviceDetectorService } from "ngx-device-detector";

@Injectable({providedIn:'root'})
export class DeviceInfoDetectorService{
    ngxDeviceInfo:any;

    constructor(private deviceService: DeviceDetectorService){}

    getDeviceInfo(){
        this.ngxDeviceInfo = this.deviceService.getDeviceInfo();

        return {
            browser:this.ngxDeviceInfo.browser,
            browserVersion:this.ngxDeviceInfo.browser_version,
            osVersion:this.ngxDeviceInfo.os_version,
            deviceType:this.ngxDeviceInfo.deviceType,
            
            device:this.ngxDeviceInfo.device,
            orientation:this.ngxDeviceInfo.orientation,
            os:this.ngxDeviceInfo.os,
            userAgent: this.ngxDeviceInfo.userAgent
        };
    }
}