<div id="accordion">
    <div class="card">
        <div class="card-header">
          <a data-bs-toggle="collapse" href="#collapseOne" style="text-decoration: none;" (click)="onClickFilterLink()">
            {{'filter-lbl'|translate}} 

            <ng-container *ngIf="isFilterLinkIconUp">
                <i class="bi bi-caret-up-fill"></i>
            </ng-container>

            <ng-container *ngIf="!isFilterLinkIconUp">
                <i class="bi bi-caret-down-fill"></i>
            </ng-container>
            
          </a>
        </div>
        <div id="collapseOne" class="collapse show" data-bs-parent="#accordion">
          <div class="card-body" style="background: transparent !important;">
            <div class="d-flex align-items-center" style="margin-bottom: 7px;">
                <div class="p-2" 
                [ngClass]="deviceWidth <= 376 ? 'flex-fill':''"
                style="padding: 6px !important;width: 40%;">
                    <div class="d-flex align-items-center">
                      <div class="p-2" style="padding: 0 !important;">
                        <input type="text" matInput 
                        class="date-comp"
                        ngxDaterangepickerMd
                        [locale]="{applyLabel: 'ok', format: 'MMM DD YYYY'}"
                        startKey="start" 
                        [(ngModel)]="selected_start"
                        name="daterange"  
                        [singleDatePicker]="true"
                        [autoApply]='true'
                        />
                      </div>
                      <div class="p-2" style="padding: 0 !important;">
                        <img src="../../../../assets/imgs/date-filter/calendar.svg" alt="date-calender" class="date-calendar-img">
                      </div>
                    </div>
                </div>

                <div class="p-2" 
                [ngClass]="deviceWidth <= 376 ? 'flex-fill':''"
                style="padding: 6px !important;width: 40%;">
                    <div class="d-flex align-items-center">
                      <div class="p-2" 
                      style="padding: 0 !important;">
                        <input type="text" matInput 
                        class="date-comp"
                        ngxDaterangepickerMd
                        [locale]="{applyLabel: 'ok', format: 'MMM DD YYYY'}" 
                        endKey="end" 
                        [(ngModel)]="selected_end"
                        name="daterange"  
                        [singleDatePicker]="true" 
                        [autoApply]='true'
                        />
                      </div>
                      <div class="p-2" style="padding: 0 !important;">
                        <img src="../../../../assets/imgs/date-filter/calendar.svg" alt="date-calender" class="date-calendar-img">
                      </div>
                    </div>
                </div>

                <ng-container *ngIf="deviceWidth > 376">
                  <div class="p-2" style="padding: 6px !important;width: 20%;">
                    <button class="btn btn-default filter-btn" (click)="onFilter()" 
                    style="min-width: 0 !important;padding: 7px 11px !important;">
                      {{'search-lbl'|translate}}
                    </button>
                  </div>
                </ng-container>
            </div>
            
            <ng-container *ngIf="radioFilterData">
              <hr>

              <div class="row row-cols-2">
                <div class="col" *ngFor="let rfd of radioFilterData; let i=index;">
                  <div class="d-flex align-items-center">
                    <div class="p-2">
                      <input 
                      class="form-check-input" 
                      type="radio" 
                      name="radioFilters" 
                      [value]="rfd.value" 
                      [id]="rfd.value" 
                      (change)="onChangeRadioFilter($event)" 
                      [checked]="!rfd.value">
                    </div>
                    <div class="p-2">
                      <label [for]="rfd.value" class="cursor-pointer-el" style="font-weight: bold;">{{rfd.name | translate}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="deviceWidth <= 376">
              <div style="width: 100%;text-align: center;">
                <button class="btn btn-default filter-btn" (click)="onFilter()" 
                style="min-width: 0 !important;padding: 7px 11px !important;">
                  {{'search-lbl'|translate}}
                </button>
              </div>
            </ng-container>

          </div>
        </div>
    </div>
</div>