import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";

import { 
    PhoneNumDisplayService, 
} from "src/app/@core/utils";

import PagesRoutingNames from "src/app/@core/helper/pages-routing-names.helper";

@Component({
    selector:'profile-header-panel',
    templateUrl:'./profile-header-panel.component.html',
    styleUrls:['./profile-header-panel.component.scss']
})
export class ProfileHeaderPanel implements OnInit, OnChanges {
    @Input() editMode:boolean|any;
    @Input() profileInfo:any;
    @Input() selectedLanguage:any;
    @Input() isSplashScreenShowing:boolean|any;

    @Output() onHideHeaderFooter:EventEmitter<boolean> = new EventEmitter();

    onlyLast4DigitsPhoneNum:any;
    selectedLangFlag:any;

    constructor(
        private PhoneNumDisplayService: PhoneNumDisplayService, 
        private router: Router, 
    ){}

    ngOnChanges(changes: SimpleChanges): void {
        this.convertPhoneNumDisplayStr();
        this.setSelectdLanguageFlag(this.selectedLanguage);
    }

    ngOnInit(): void {
        this.convertPhoneNumDisplayStr();
        this.setSelectdLanguageFlag(this.selectedLanguage);
    }

    onEditProfile(){
        this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.EDIT_PROFILE}`]);

        this.onHideHeaderFooter.emit(true);
    }

    convertPhoneNumDisplayStr(){
        if(this.profileInfo){
            if(this.profileInfo.phonenumber){
                this.onlyLast4DigitsPhoneNum = this.PhoneNumDisplayService.showLatestFourDigits(this.profileInfo.phonenumber);
            }
        }
    }

    goProfile(){
        this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.PROFILE}`]);
    }

    goLogin(){
        this.router.navigate([`/${PagesRoutingNames.MY_AUTH}`]);
    }

    gotoChangeLanguage(){
        this.router.navigate([`/${PagesRoutingNames.CHOOSE_LANGUAGE}`]);
    }

    setSelectdLanguageFlag(_selectedLanguage:string){
        switch(_selectedLanguage){
            case 'my':{
                this.selectedLangFlag = '../assets/imgs/profile-and-language/v2/flags/myanmar-flag.svg';
                break;
            }
            case 'en':{
                this.selectedLangFlag = '../assets/imgs/profile-and-language/v2/flags/english-flag.svg';
                break;
            }
            case 'zh':{
                this.selectedLangFlag = '../assets/imgs/profile-and-language/v2/flags/china-flag.svg';
                break;
            }
            default:this.selectedLangFlag = '../assets/imgs/profile-and-language/v2/flags/english-flag.svg';
        }
    }
}