import { 
    Component, 
    Input, 
    Output, 
    EventEmitter,
    OnInit,
    AfterViewInit, 
    OnDestroy,
    OnChanges, SimpleChanges,
} from "@angular/core";

import { Router } from "@angular/router";

import { GamesService, ProfileService } from "src/app/@core/services";

import { 
    SpinnerService, 
    LocalStorageService,
    CryptoJsService,
    SessionStorageService,
    RootComponentObserverService,
    AuthUtilsService,
    NavigatorHelperService,
    OtherGamesService,
    DialogService, 
} from "src/app/@core/utils";

import PagesRoutingNames from "src/app/@core/helper/pages-routing-names.helper";

@Component({
    selector:'swnw-header',
    templateUrl:'./header.component.html',
    styleUrls:['./header.component.scss']
})
export class HeaderComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    @Input() isHide:boolean|any;
    @Input() navTitleOnHiding:string|any;
    @Input() isHeaderIconsHideAll:boolean|any;
    @Input() imgTitle:string|any;
    @Input() selectedLanguage:any;
    @Input() isSplashScreenShowing:boolean|any;

    @Output() onBack:EventEmitter<boolean> = new EventEmitter();

    isHideRefresh:boolean|any;

    toid1:any;

    selectedLangFlag:any;

    isShowHeaderLanguageIcon:boolean|any;

    constructor(
        private router: Router,
        private gamesService:GamesService,
        private spinnerService: SpinnerService,
        private sessionService:SessionStorageService,
        private profileService:ProfileService,
        private rootCompObserverService:RootComponentObserverService,
        private localService:LocalStorageService,
        private cryptoService:CryptoJsService,
        private authUtilsService:AuthUtilsService,
        private navigatorHelperService:NavigatorHelperService,
        private otherGamesService:OtherGamesService,
        private dialogService:DialogService,
    ){
        this.checkForHeaderLanguageImageStage(window.location.pathname);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.selectedLanguage && this.setSelectdLanguageFlag(this.selectedLanguage);

        this.checkForHeaderLanguageImageStage(window.location.pathname);
    }

    ngOnInit(): void {
        this.selectedLanguage && this.setSelectdLanguageFlag(this.selectedLanguage);

        this.otherGamesService.isHideHeaderRefreshIcon.subscribe(resp=>{
            this.isHideRefresh = resp?true:false;
        });
    }

    ngAfterViewInit(): void {
        
    }

    ngOnDestroy(): void {
        if(this.toid1) { clearTimeout(this.toid1)}
    }

    checkForHeaderLanguageImageStage(winLocationPath:string) {
        if(winLocationPath === `/${PagesRoutingNames.MY_AUTH}/${PagesRoutingNames.LOGIN}` || 
        winLocationPath === `/${PagesRoutingNames.MY_AUTH}/${PagesRoutingNames.REGISTER}` || 
        winLocationPath === `/${PagesRoutingNames.MY_AUTH}/${PagesRoutingNames.DEFINE_PIN}` || 
        winLocationPath === `/${PagesRoutingNames.VERIFY_OTP}`) {
            this.isShowHeaderLanguageIcon = true;
        } else this.isShowHeaderLanguageIcon = false;
    }

    onHeaderIconEvent(target:any){
        switch(target){
            case 'download-app':{
                this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.DOWNLOAD_APP}`]);
                break;
            }
            case 'refresh':{
                window.location.reload();

                break;
            }
            case 'contact':{
                this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.CONTACT}`]);
                break;
            }
            case 'choose-language':{
                this.gotoChangeLanguage();
                break;
            }
            default:{break;}
        }
    }

    onBackClick(){
        this.navigatorHelperService.deviceFullWidthUpdater(false);

        if(this.sessionService.getSelectedDatesOnWalletHostory()){
            if(window.location.pathname!=='/pages/wallet/detail') {
                this.sessionService.clearSelectedDatesOnWalletHistory();
            }
        }

        this.toid1 = setTimeout(() => {
            if(window.location.pathname.includes('pages/other-games') || window.location.pathname.includes('pages/new-other-games')){
                const isDisplayedGameIFrame = this.sessionService.getIsDisplayedGameIFrame();
                
                const err512Status = this.sessionService.getERR512Status();
                
                if(isDisplayedGameIFrame || err512Status){
                    this.dialogService.showInfo('wait-exiting-game-lbl');

                    const arry = window.location.pathname.split('/');

                    this.gscGameExit(+(arry[arry.length-1]));

                    err512Status && this.sessionService.clearERR512Status();

                }else{
                    this.router.navigate([`/${PagesRoutingNames.PAGES}`]);

                }
            } else if (this.includeBackToHomeRoutes(window.location.pathname)) {
                this.router.navigate([`/${PagesRoutingNames.PAGES}`]);

            } else if (this.includeBackTo2DHomeRoutes(window.location.pathname)) {
                this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.TWO_D}`]);

            } else if (this.includeBackTo3DHomeRoutes(window.location.pathname)) {
                this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.THREE_D}`]);

            } else if (this.includeBackToWalletHomeRoutes(window.location.pathname)) {
                this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.HOME_WALLET}`]);

            } else if (window.location.pathname.includes('/pages/promotion-detail/')) {
                this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.PROMOTIONS}`]);

            } else if (window.location.pathname.includes('/pages/betting-confirm/2')) {
                this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.TWO_D}/${PagesRoutingNames.NORMAL_BETTING}`]);

            } else if (window.location.pathname.includes('/pages/betting-confirm/3')) {
                this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.THREE_D}/${PagesRoutingNames.NORMAL_BETTING_3D}`]);
                
            } else {
                this.onBack.emit(true);
            }
        }, 10);
    }

    gscGameExit(mainGameId:number|any){
        this.spinnerService.loading.next(true);

        const pl = new URLSearchParams();
        pl.append('maingameid',`${mainGameId}`);

        this.gamesService.gscGameExits(pl).subscribe({
            next:(v)=>{
                this.spinnerService.loading.next(false);
                
                this.sessionService.clearIsDisplayedGameIFrame();

                this.isHideRefresh = false;
                
                //update UserInfo for wallets!
                this.updateUserInfo()
                .then(resp =>{
                    //then go to specified pages
                    if (mainGameId === 2 || mainGameId === 3 || mainGameId === 4 || (mainGameId >= 8 && mainGameId <= 40)) {
                        const childgameslaunching=this.localService.getChildGamesLaunchingStage();
                        
                        if (childgameslaunching) {
                            this.localService.clearChildGamesLaunchingStage();
    
                            if (mainGameId >= 8 && mainGameId <= 40) {
                                this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.NEW_OTHER_GAMES}`, mainGameId])
                                .then(r=>window.location.reload());
                            } else {
                                this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.OTHER_GAMES}`, mainGameId])
                                .then(r=>window.location.reload());
                            }
                        } else {
                            this.router.navigate([`/${PagesRoutingNames.PAGES}`]);
                        }
                    }else{
                        this.localService.clearBTISportGamesLink();

                        this.router.navigate([`/${PagesRoutingNames.PAGES}`]);
                    }
                }).catch(e=>{
                    this.authUtilsService.swnwErrorHandler(e.error);
                });
            },
            error:(e)=>{
                this.spinnerService.loading.next(false);
                this.authUtilsService.swnwErrorHandler(e.error);
            },
        });
    }

    updateUserInfo():Promise<any>{
        return new Promise((resolve, reject)=>{
            this.spinnerService.loading.next(true);

            this.profileService.getUserInfo().subscribe({
                next:(v:any)=>{
                    this.spinnerService.loading.next(false);
                    
                    if(v.status==='200'){
                        if(v.data){
                            this.localService.saveUserProfileInfo(
                                this.cryptoService.encrypt(JSON.stringify(v.data))
                            );
                            this.rootCompObserverService.loadUserProfileInfo.next(true);
                            resolve(true);
                        }else{
                            reject({
                                status:`${v.status}: `,
                                message:`no-user-profile-data-lbl`
                            });
                        }
                    }else{
                        reject(v);
                    }
                },
                error:(e)=>{
                    this.spinnerService.loading.next(false);
                    reject(e.error);
                }
            });
        });
    }
    
    includeBackToHomeRoutes(pathName:string):boolean{
        return this.navigatorHelperService.backToHomeRoutes
        .findIndex((x:string)=>pathName.includes(x)) >= 0;
    }

    includeBackToWalletHomeRoutes(pathName:string):boolean{
        return this.navigatorHelperService.backToWalletHomeRoutes
        .findIndex((x:string)=>pathName.includes(x)) >= 0;
    }
    
    includeBackTo2DHomeRoutes(pathName:string):boolean{
        return this.navigatorHelperService.backTo2DHomeRoutes
        .findIndex((x:string)=>pathName.includes(x)) >= 0;
    }

    includeBackTo3DHomeRoutes(pathName:string):boolean{
        return this.navigatorHelperService.backTo3DHomeRoutes
        .findIndex((x:string)=>pathName.includes(x)) >= 0;
    }

    gotoHome(){
        this.router.navigate([`/${PagesRoutingNames.PAGES}`]);
    }

    gotoChangeLanguage(){
        this.router.navigate([`/${PagesRoutingNames.CHOOSE_LANGUAGE}`]);
    }
    
    setSelectdLanguageFlag(_selectedLanguage:string){
        switch(_selectedLanguage){
            case 'my':{
                this.selectedLangFlag = '../assets/imgs/profile-and-language/v2/flags/myanmar-flag.svg';
                break;
            }
            case 'en':{
                this.selectedLangFlag = '../assets/imgs/profile-and-language/v2/flags/english-flag.svg';
                break;
            }
            case 'zh':{
                this.selectedLangFlag = '../assets/imgs/profile-and-language/v2/flags/china-flag.svg';
                break;
            }
            default:this.selectedLangFlag = '../assets/imgs/profile-and-language/v2/flags/english-flag.svg';
        }
    }
}