<ng-container *ngIf="!isHide && !isFooterHideOnly">
    <div class="d-flex justify-content-between swnw-footer align-items-center">
        
        <div [ngClass]="isHome? 'p-2 p-2-footer footer-item-selected':'p-2 p-2-footer'" 
        (click)="onFooterItemSelected(pageRouteNames.HOME)">
            <img [src]="homeImg" alt="home" id="home-img-el">
            
            <label>{{'home-menu-lbl' | translate}}</label>
        </div>

        <div [ngClass]="isWallet? 'p-2 p-2-footer footer-item-selected':'p-2 p-2-footer'" 
        (click)="onFooterItemSelected(pageRouteNames.HOME_WALLET)"
        style="min-width:65px;">
            <img [src]="walletImg" alt="wallet">
            <label>{{'wallet-menu-lbl' | translate}}</label>
        </div>

        <!-- <div [ngClass]="isSpinWheel? 'p-2 p-2-footer footer-item-selected':'p-2 p-2-footer'" 
        (click)="onFooterItemSelected( pageRouteNames.SPIN_LUCKY_DRAW)">
            <img [src]="spinWheelImg" alt="spin-wheel" class="spin-wheel-img">
            <label>{{'spin-wheel-title' | translate}}</label>
        </div> -->

        <div [ngClass]="isPromotions? 'p-2 p-2-footer footer-item-selected':'p-2 p-2-footer'" 
        (click)="onFooterItemSelected(pageRouteNames.PROMOTIONS)">
            <img [src]="promotionImg" alt="promotion">
            <label>{{'promotions-menu-lbl' | translate}}</label>
        </div>

        <div [ngClass]="isProfile? 'p-2 p-2-footer footer-item-selected':'p-2 p-2-footer'" 
        (click)="onFooterItemSelected(pageRouteNames.PROFILE)">
            <img [src]="profileImg" alt="profile">
            <label>{{'profile-menu-lbl' | translate}}</label>
        </div>
    </div>
</ng-container>