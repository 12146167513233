import { Component, Input } from "@angular/core";

@Component({
    selector: 'contact-phones-component',
    templateUrl:'./contact-phones.component.html',
    styleUrls:['./contact-phones.component.scss']
})
export class ContactPhonesComponent {
    @Input() phoneNumbers:Array<string> | any;

    phoneImg = '../../../../assets/imgs/wallet/v2/service-and-contact/phone.svg';
}