import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class MyCommissionFriendService {
    private SWNW_URL = `${environment.API_ENDPOINT}/${environment.MOBILE_PATH}`;

    constructor(private http: HttpClient){}

    getMyCommission(payload:any): Observable<any>{
        return this.http.post(`${this.SWNW_URL}/commission`, payload);
    }
    

    getMyFriendList(): Observable<any>{
        return this.http.post(`${this.SWNW_URL}/referfriends`, null);
    }
}