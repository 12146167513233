export const environment = {
  production: false,
  swnwsmspohkey:'J2Xzndb3PcYagOp_bTkSGpI05zQaG924RU-jQuWWEylCqhixaOeoEofRyY_5OLfz',
  mycrytok:'mttpdak147852369AQZ',
  host:'http://localhost:4200',
  API_ENDPOINT:'https://86jq6bw6bd.execute-api.ap-southeast-1.amazonaws.com',
  DEFAULT_PATH:'dev/api',
  MOBILE_PATH:'dev/api/user'

  /* API_ENDPOINT:'https://sys.onestar2d.com',
  DEFAULT_PATH:'api',
  MOBILE_PATH:'api/user' */
};