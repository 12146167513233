import { HTTP_INTERCEPTORS, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { 
    LocalStorageService, 
} from "../utils";

@Injectable({providedIn:'root'})
export class AuthInterceptorService implements HttpInterceptor {
    constructor(
        private localService: LocalStorageService
    ){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.localService.getToken();
        let authReq;

        if(token){
            authReq = req.clone({
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'x-access-token':token,
                })
            });
        }else{
            authReq = req.clone({
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                })
            });
        }
        
        return next.handle(authReq);
    }
}

export const authInterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true}
];