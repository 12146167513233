import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { 
  Router, 
  NavigationEnd, 
  NavigationError, 
} from '@angular/router';
import { Location } from "@angular/common";

import { 
  DialogService,
  SpinnerService,
  RootComponentObserverService,
  AuthUtilsService,
  LocalStorageService,
  CryptoJsService,
  SessionStorageService, 
} from './@core/utils';

import PagesRoutingNames, { FooterMainNavItems } from './@core/helper/pages-routing-names.helper';

import { TranslateService } from "@ngx-translate/core";
import defaultLanguage from '../assets/i18n/my.json';
import { MainWalletAvailablePlaces, GameWalletAvailablePlaces } from "src/app/@core/helper/wallet.helper";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedFooterNavItemName:string|any = '';

  isProfileEditMode:boolean|any;
  isHidedHeaderFooter:boolean|any;
  isHideProfileHeaderPanel:boolean|any;

  titleOnHeaderHiding:string|any;

  deviceInnerHeight:number|any;
  deviceInnerWidth:number|any;

  isShowDialog = false;
  modal:any;
  span:any;
  modalTitle:any;
  modalDescription:any;
  modalStatus:any;

  spinner:any;

  availableModal:any;
  availableModalData:any;

  //home data
  userProfileInfo:any;
  selectedLanguage:any;

  allHomePromoTypes:any;

  isShowBtns:boolean;

  isHeaderIconsHideAll:boolean;
  isFooterHideOnly:boolean;

  toid1:any;
  toid6:any;

  t23DTitleImg:string|any;

  selectedLanguageOnSpecifiedPages:string|any;

  stored23DTitleImgs:any;

  isSplashScreenShowing=true;

  constructor(
    private router: Router, 
    public location: Location,
    private dialogService: DialogService,
    private spinnerService:SpinnerService,
    private rootCompObserverService:RootComponentObserverService,
    private authUtilsService:AuthUtilsService,
    private localService:LocalStorageService,
    private translate:TranslateService,
    private cryptoService:CryptoJsService, 
    private sessionStorageService:SessionStorageService,
  ){
    this.modalTitle='';
    this.modalDescription='';
    this.modalStatus='';
    this.isShowBtns = false;

    this.isHeaderIconsHideAll=false;
    this.isFooterHideOnly=false;

    this.availableModalData={
      title:'',
      description:[{id:0,icon:'',text:''}],
    };

    this.translate.setTranslation('my',defaultLanguage);
    this.translate.setDefaultLang('my');

    let lng:any;
    const _t = this.localService.getChooseLangugae();
    if(_t){
      lng = this.cryptoService.decrypt(_t);
      this.translate.use(lng);
      this.selectedLanguage=lng;
    }
    else{//set default language
      this.translate.use('my');
      this.localService.saveChooseLangugae(this.cryptoService.encrypt('my'));
      this.selectedLanguage='my';
    }
    
    this.router.events.subscribe((event:any)=>{
      if(event instanceof NavigationEnd){

        const routes=event.urlAfterRedirects.split('/');
        
        if(routes[1]===PagesRoutingNames.SPLASH_SCREEN){
          this.isSplashScreenShowing = true;
          this.isHidedHeaderFooter = true;
          this.isHeaderIconsHideAll = true;
  
        } else if(routes[1]===PagesRoutingNames.PAGES){
          this.isSplashScreenShowing = false;

          this.getStored23DTitleImgs();

          this.routePagesInit(routes);

          if(FooterMainNavItems.includes(routes[2])) {
            this.includeFooterMenu(routes);

          } else if (routes[2] && !routes[3]) {
            this.childRoute2Not3(routes);

          } else {
            this.childRoute3Exist(routes);
          }

          this.toid1 = setTimeout(() => {
            (<any>document.querySelector('#swnw-body')).firstChild.style.padding='4px 12px 0 12px';
          }, 600);

        }else{
          this.isSplashScreenShowing = false;

          this.notRoutePage(routes);
        }
        
      } else if(event instanceof NavigationError){
        this.router.navigate([`/${PagesRoutingNames.PAGES}`]);

        this.t23DTitleImg = null;

        this.selectedLanguageOnSpecifiedPages = null;
      }
    });

    this.rootCompObserverService.loadUserProfileInfo.subscribe({
      next:(v)=>{
        this.userProfileInfo = this.authUtilsService.getLocalUserInfo();
      }
    });
    
    this.rootCompObserverService.loadSelectedLanguage.subscribe({
      next:(v)=>{
        this.selectedLanguage = this.cryptoService.decrypt(this.localService.getChooseLangugae());
      }
    });

    this.dialogService.isShow.subscribe((resp:any) => {
      if(resp){
        const {title,description,status,duration, dialogType} = JSON.parse(resp);

        this.modalTitle=title;
        this.modalDescription=description;
        this.modalStatus=status;

        if(dialogType && dialogType==='gameMaintainCollect'){
          const dialog = <HTMLElement>document.getElementById('myGameMaintainModal');
          dialog.style.display='block';

        } else {
          this.modal.style.display='block';

          if(duration){
            this.toid6 = setTimeout(() => {
              this.modal.style.display='none';
            }, duration);
          }
        }
      }
    });

    this.dialogService.isShowWalletAvailable.subscribe((resp:any)=>{
      if(resp){
        if(resp===`1`){
          this.availableModalData = MainWalletAvailablePlaces;
        }else if(resp===`2`){
          this.availableModalData = GameWalletAvailablePlaces;
        }

        this.availableModal = document.getElementById("myBalModal");
        this.availableModal.style.display='block';
      }
    });
  }

  getStored23DTitleImgs() {
    const t = this.sessionStorageService.get23DsTitleImgsData();
    if(t) {
      if(!this.stored23DTitleImgs) {
        this.stored23DTitleImgs = {
          ...JSON.parse(this.cryptoService.decrypt(t)),
        };
      }
      ////console.log('#GOT via storedsession/ this.stored23DTitleImgs',this.stored23DTitleImgs);
    }
  }

  get23DTitleImgPath(type:string) {
    return type === '2d' ? this.stored23DTitleImgs.twodTitleImg.link : this.stored23DTitleImgs.threedTitleImg.link;
  }

  routePagesInit(routes:any) {
    this.isHidedHeaderFooter=false;
    this.isHeaderIconsHideAll=false;
    this.isFooterHideOnly=false;
    
    this.t23DTitleImg = null;
    this.userProfileInfo = this.authUtilsService.getLocalUserInfo();
    this.isProfileEditMode = routes[2]===PagesRoutingNames.PROFILE;
    this.selectedFooterNavItemName = routes[2];

    this.selectedLanguageOnSpecifiedPages = null;
  }
  includeFooterMenu(routes:any){
    this.isHideProfileHeaderPanel = (routes[2]===PagesRoutingNames.CONTACT || routes[2]===PagesRoutingNames.PROMOTIONS || routes[2]===PagesRoutingNames.HOME_WALLET);
  }
  childRoute2Not3(routes:any){
    this.isHideProfileHeaderPanel=true;
    this.isHidedHeaderFooter=true;
    this.titleOnHeaderHiding = this.setHeaderTitle(routes[2]);
  }
  childRoute3Exist(routes:any){
    this.isHideProfileHeaderPanel=true;
    this.isHidedHeaderFooter=true;

    if(routes[2]===PagesRoutingNames.QUICK_SELECT_NUMBERS || routes[2]===PagesRoutingNames.BETTING_CONFIRM) {
      this.titleOnHeaderHiding = this.setHeaderTitle(routes[2]);
    } else {
      this.titleOnHeaderHiding = this.setHeaderTitleOfChildRoutes(routes[2], routes[3]);
    }

    if(this.stored23DTitleImgs && this.stored23DTitleImgs.threedTitleImg && this.stored23DTitleImgs.twodTitleImg) {
      if(window.location.pathname === '/pages/two-d/home'){
        this.t23DTitleImg = this.get23DTitleImgPath('2d');
      } else if(window.location.pathname === '/pages/three-d/home') {
        this.t23DTitleImg = this.get23DTitleImgPath('3d');
      } else {
        this.t23DTitleImg = null;
      }
    } else if(this.stored23DTitleImgs && this.stored23DTitleImgs.threedTitleImg && !this.stored23DTitleImgs.twodTitleImg) {
      if(window.location.pathname === '/pages/two-d/home'){
        this.t23DTitleImg = this.get23DTitleImgPath('2d');
      } else if(window.location.pathname === '/pages/three-d/home') {
        this.titleOnHeaderHiding = this.setHeaderTitleOfChildRoutes(routes[2], routes[3]);
        this.t23DTitleImg = null;
      }
    } else if(this.stored23DTitleImgs && !this.stored23DTitleImgs.threedTitleImg && this.stored23DTitleImgs.twodTitleImg) {
      if(window.location.pathname === '/pages/two-d/home'){
        this.titleOnHeaderHiding = this.setHeaderTitleOfChildRoutes(routes[2], routes[3]);
        this.t23DTitleImg = null;
      } else if(window.location.pathname === '/pages/three-d/home') {
        this.t23DTitleImg = this.get23DTitleImgPath('3d');
      }
    }
  }
  notRoutePage(routes:any) {
    if(
      routes[1]===PagesRoutingNames.CHOOSE_LANGUAGE || 
      routes[1]===PagesRoutingNames.MY_AUTH || 
      routes[1]===PagesRoutingNames.VERIFY_OTP || 
      routes[1]===PagesRoutingNames.GAME_MAINTAIN_COLLECT_ALERT){
        //|| r[1]===PagesRoutingNames.SPIN_LUCKY_DRAW
      
      this.isHideProfileHeaderPanel=true;
      this.isHidedHeaderFooter=true;
      
      if(routes[1]===PagesRoutingNames.CHOOSE_LANGUAGE || routes[1]===PagesRoutingNames.VERIFY_OTP || 
        routes[1]===PagesRoutingNames.GAME_MAINTAIN_COLLECT_ALERT){
        this.titleOnHeaderHiding = this.setHeaderTitle(routes[1]);
      } else if(routes[1]===PagesRoutingNames.MY_AUTH){
        //|| r[1]===PagesRoutingNames.SPIN_LUCKY_DRAW
        this.titleOnHeaderHiding = this.setHeaderTitleOfChildRoutes(routes[1], routes[2]);
      }
    }

    this.t23DTitleImg = null;

    if((routes[1]===PagesRoutingNames.MY_AUTH && (routes[2]===PagesRoutingNames.LOGIN || 
      routes[2]===PagesRoutingNames.REGISTER || 
      routes[2]===PagesRoutingNames.DEFINE_PIN)) 
      || routes[1]===PagesRoutingNames.VERIFY_OTP) {

        this.selectedLanguageOnSpecifiedPages = this.selectedLanguage;
      
    } else {
      this.selectedLanguageOnSpecifiedPages = null;
    }
  }

  ngOnInit(): void {
    this.deviceInnerHeight = window.innerHeight;
    this.deviceInnerWidth = window.innerWidth;
  }

  ngAfterViewInit(): void {
    //START: Modal-Dialog and Spinner
    this.modal = document.getElementById("myModal");
    this.span = document.getElementsByClassName("swnw-close")[0];
    
    this.span.onclick = () => {
      this.modal.style.display = "none";
    }
    
    this.spinner = document.getElementById("mySpinner");
    this.spinnerService.loading.subscribe((resp:any)=>{
      this.spinner.style.display = resp?'block':'none';
    });
    //END:
  }

  ngOnDestroy(): void {
    if(this.toid1) { clearTimeout(this.toid1)}
    if(this.toid6) { clearTimeout(this.toid6)}
  }

  onHideHeaderAndFooter(evt:boolean){
    this.isHidedHeaderFooter = evt;
  }

  setHeaderTitle(routeName:string):string{
    switch(routeName){
      case PagesRoutingNames.CHOOSE_LANGUAGE:{
        return 'change-language-menu-lbl';
      }
      case PagesRoutingNames.VERIFY_OTP:{
        return this.sessionStorageService.getVerifyOTPVia()? 'enter-pin-lbl' : 'verify-otp-title';
      }
      case PagesRoutingNames.EDIT_PROFILE:{
        return 'edit-profile-title';
      }
      case PagesRoutingNames.NOTIFICATION:{
        return 'noti-title';
      }
      case PagesRoutingNames.TWO_D:{
        return 'twod-title';
      }
      case PagesRoutingNames.DUBAI_TWO_D:{
        return 'dubai-twod-title';
      }
      case PagesRoutingNames.THREE_D:{
        return 'threed-title';
      }
      case PagesRoutingNames.LOTTERY_CLOSED_DAYS:{
        return 'lottery-closed-days-title';
      }
      case PagesRoutingNames.DOWNLOAD_APP:{
        return 'download-app-title';
      }
      case PagesRoutingNames.MLBB:{
        return 'mobile-legends-title';
      }
      case PagesRoutingNames.INVITATION:{
        return 'invite-title';
      }
      case PagesRoutingNames.MY_COMMISSION:{
        return 'my-commission-lbl';
      }
      case PagesRoutingNames.MY_FRIEND_LIST:{
        return 'my-friend-list-lbl';
      }
      case PagesRoutingNames.MY_TXN_HISTORY:{
        return 'my-txn-menu-lbl';
      }
      case PagesRoutingNames.VIP_USER_CASHBACK:{
        return 'vip-user-cashback-lbl';
      }
      case PagesRoutingNames.QUICK_SELECT_NUMBERS:{
        return 'quick-select-lbl';
      }
      case PagesRoutingNames.BETTING_CONFIRM:{
        return 'betting-confirm-lbl';
      }
      case PagesRoutingNames.TUTORIALS : {
        return 'tutorials-title';
      }
      case PagesRoutingNames.GAME_MAINTAIN_COLLECT_ALERT:{
        return 'maintenance-collect-alert-title';
      }
      
      default:{
        return '-No Title-';
      }
    }
  }

  setHeaderTitleOfChildRoutes(parentRoute:string, childRoute:string):string{
    switch(parentRoute){
      case PagesRoutingNames.PROMOTION_DETAIL:{
        return 'promo-detail-title';
      }
      case PagesRoutingNames.MY_AUTH : {
        switch(childRoute){
          case PagesRoutingNames.LOGIN:{
            return 'login-title';
          }
          case PagesRoutingNames.REGISTER:{
            return 'register-title';
          }
          case PagesRoutingNames.DEFINE_PIN:{
            return "define-pin-title";
          }
          case PagesRoutingNames.CHANGE_PIN:{
            return "change-pin-menu-lbl";
          }
          case PagesRoutingNames.FORGET_PIN:{
            return "forget-pin-lbl";
          }
          default:{
            return '-No Title-';
          }
        }
      }
      case PagesRoutingNames.TWO_D : {
        switch(childRoute){
          case PagesRoutingNames.TWO_D_HOME:{
            return 'twod-title';
          }
          case PagesRoutingNames.TWO_D_WINNERS:{
            return 'two-d-top-winners-title';
          }
          case PagesRoutingNames.TWO_D_RESULT_HISTORY:{
            return 'twod-result-title';
          }
          case PagesRoutingNames.TWO_D_BET_HISTORY:{
            return 'bet-history-title';
          }
          case PagesRoutingNames.NORMAL_BETTING:{
            return 'welcome-2d-title';
          }
          case PagesRoutingNames.TWO_D_BET_HISTORY_DETAIL:{
            return 'bet-history-detail-title';
          }
          default:{
            return '-No Title-';
          }
        }
      }
      case PagesRoutingNames.DUBAI_TWO_D : {
        switch(childRoute){
          case PagesRoutingNames.DUBAI_TWO_D_HOME:{
            return 'dubai-twod-title';
          }
          case PagesRoutingNames.DUBAI_TWO_D_WINNERS:{
            return 'dubai2d-winners-title';
          }
          case PagesRoutingNames.DUBAI_TWO_D_RESULT_HISTORY:{
            return 'dubai2d-bet-record-title';
          }
          case PagesRoutingNames.DUBAI_TWO_D_BET_HISTORY:{
            return 'dubai2d-bet-history-title';
          }
          default:{
            return '-No Title-';
          }
        }
      }
      case PagesRoutingNames.THREE_D : {
        switch(childRoute){
          case PagesRoutingNames.THREE_D_HOME:{
            return 'threed-title';
          }
          case PagesRoutingNames.THREE_D_WINNERS:{
            return 'three-d-top-winners-title';
          }
          case PagesRoutingNames.THREE_D_RESULT_HISTORY:{
            return 'threed-result-title';
          }
          case PagesRoutingNames.THREE_D_BET_HISTORY:{
            return 'bet-history-title';
          }
          case PagesRoutingNames.NORMAL_BETTING_3D:{
            return 'welcome-3d-title';
          }
          case PagesRoutingNames.THREE_D_BET_HISTORY_DETAIL:{
            return 'bet-history-detail-title';
          }
          default:{
            return '-No Title-';
          }
        }
      }
      case PagesRoutingNames.WALLET_MANAGE : {
        switch(childRoute){
          case PagesRoutingNames.WALLET_MANAGE_DETAIL:{
            return 'detail-title';
          }
          case PagesRoutingNames.WALLET_MANAGE_HISTORY:{
            return 'wallet-history-title';
          }
          case PagesRoutingNames.WALLET_MANAGE_TOPUP:{
            return 'topup-title';
          }
          case PagesRoutingNames.WALLET_MANAGE_TRANSFER:{
            return 'wallet-transfer-title';
          }
          case PagesRoutingNames.WALLET_MANAGE_WITHDRAW:{
            return 'ur-rece-accs-menu-lbl';
          }
          default:{
            return '-No Title-';
          }
        }
      }
      case PagesRoutingNames.OTHER_GAMES : {
        switch(childRoute){
          case '1':{
            return 'welcome-bti-title';
          }
          case '2':{
            return 'welcome-kmqm-title';
          }
          case '3':{
            return 'welcome-rt-title';
          }
          case '4':{
            return 'welcome-bg-title';
          }
          case '28':{
            return 'welcome-hot2d-title';
          }
          case '27':{
            return 'welcome-mingalr-bank-title';
          }
          case '31':{
            return 'welcome-dai-title';
          }
          default:{
            return '-No Title-';
          }
        }
      }
      /* case PagesRoutingNames.SPIN_LUCKY_DRAW : {
        switch(childRoute){
          case PagesRoutingNames.SPIN_WHEEL:{
            return 'spin-wheel-title';
          }
          case PagesRoutingNames.SPIN_WINNERS:{
            return 'spin-winners-title';
          }
          case PagesRoutingNames.SPIN_RESULT_HISTORY:{
            return "spin-result-history-title";
          }
          case PagesRoutingNames.SPIN_RULES:{
            return "rules-lbl";
          }
          default:{
            return '-No Title-';
          }
        }
      } */
      case PagesRoutingNames.NEW_OTHER_GAMES : {
        return 'welcome-new-games';
      }
      case PagesRoutingNames.BETTING_LIMIT_ERROR_PAGE : {
        return 'betting-limit-error-title';
      }

      default:{
        return '-No Title-';
      }
    }
  }

  onHeaderBack(){
    this.location.back();
  }

  onCloseAvailableModal(){
    this.availableModal.style.display='none';
    this.availableModalData={
      title:'',
      description:[{id:0,icon:'',text:''}],
    };
  }

  onCloseGameMaintainModal() {
    const dialog = <HTMLElement>document.getElementById('myGameMaintainModal');
    dialog.style.display='none';
  }
}
