<div class="withdraw-term-cond-container">  
    <label class="t-and-c-title">
        {{'caution-title'|translate}}
    </label>

    <div class="txt-container">
        <ul>
            <li>
                {{'dont-dob-lbl'|translate}}
            </li>
            <li>
                {{'dont-nrc-lbl'|translate}}
            </li>
            <li>
                {{'dont-easy-nums-lbl'|translate}}
            </li>
        </ul>
    </div>
</div>