<div class="card-container">
    <div class="outer-profile-img-container">
        <div class="profile-img-container">
            <img class="winner-crown" src="../../../../assets/imgs/games/winners/v2/crown.png" alt="crown">
            <img 
            class="winner-profile-img" 
            [ngClass]="topNumber=='1' ? 'winner-profile-img-top-1' : (topNumber=='2' ? 'winner-profile-img-top-2' : 'winner-profile-img-top-3')"
            src="../../../../assets/imgs/profile-and-language/no-profile-picture-icon.svg">
        </div>
    </div>

    <div class="top-3-winner-card-body-container">
        <div class="name-phone-div">
            <label class="truncate">{{winner.username}}</label>
            <div>
                <label class="truncate">{{winner.phonenumber}}</label>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-center flex-wrap">
            <div class="p-2">{{'bet-amount-on-card-lbl'|translate}}</div>

            <div class="p-2">{{winner.bet}}</div>
        </div>

        <div class="d-flex justify-content-between align-items-center flex-wrap">
            <div class="p-2">{{'win-amount-on-card-lbl'|translate}}</div>

            <div class="p-2">{{winner.win}}</div>
        </div>
    </div>
</div>