import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class ProfileService {
    private SWNW_URL = `${environment.API_ENDPOINT}/${environment.MOBILE_PATH}`;

    constructor(private http: HttpClient) {}

    getUserInfo(payload:any=null){//3
        return this.http.post(`${this.SWNW_URL}/info`, payload);
    }
    
    updateUserInfo(payload:any):Observable<any>{//8
        return this.http.post(`${this.SWNW_URL}/update`,payload);
    }

    getProfileImg():Observable<any>{//5
        return this.http.post(`${this.SWNW_URL}/getimage`,null);
    }

    saveProfileImg(payload:any):Observable<any>{//4
        return this.http.post(`${this.SWNW_URL}/saveimage`,payload);
    }

    changePlayerPassword(payload:any):Observable<any>{//40
        return this.http.post(`${this.SWNW_URL}/changeplayerpassword`,payload);
    }

    playerForgetPassword(payload:any):Observable<any>{//39
        return this.http.post(`${this.SWNW_URL}/playerforgetpassword`,payload);
    }
}
