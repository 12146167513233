import { Injectable } from "@angular/core";
import { 
    CanActivate, 
    CanActivateChild,
    ActivatedRouteSnapshot, 
    RouterStateSnapshot, 
    Router,
    UrlTree,
} from "@angular/router";
import PagesRoutingNames from "../helper/pages-routing-names.helper";

import { LocalStorageService } from "./local-storage.service";

@Injectable({providedIn:'root'})
export class RoutesGuardService implements CanActivate, CanActivateChild {
    validRoutes = [
        //`/${PagesRoutingNames.UNDER_MAINTAIN_PAGE}`,

        // comment all when under-maintain
        `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.HOME}`,
        `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.TWO_D}/${PagesRoutingNames.TWO_D_HOME}`,
        `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.THREE_D}/${PagesRoutingNames.THREE_D_HOME}`,
        `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.HOME_WALLET}`,
        `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.PROMOTIONS}`,
        `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.PROMOTION_DETAIL}`,
        `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.CONTACT}`,
        `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.PROFILE}`,
        `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.DOWNLOAD_APP}`,
        `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.MLBB}`,
        `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.DUBAI_TWO_D}/${PagesRoutingNames.DUBAI_TWO_D_HOME}`,
    ];

    constructor(
        private router: Router,
        private localService: LocalStorageService,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): true|UrlTree {
        const url: string = state.url;

        return this.checkLogin(url);
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): true | UrlTree {
        return this.canActivate(route, state);
    }

    checkLogin(url: string): true|UrlTree {
        if(this.localService.getToken() || this.checkRoutesWithParamId(url) || this.includeValidRoute(url)) { return true; }
        //comment when under-maintain "this.localService.getToken() || this.checkRoutesWithParamId(url) ||" !
        
        return this.router.parseUrl('/pages');
    }

    includeValidRoute(url:string): boolean{
        return this.validRoutes.findIndex((x:string)=>url.includes(x)) >= 0;
    }

    checkRoutesWithParamId(url:string): boolean {
        return (url.includes('other-games') || url.includes('tutorials'));
    }
}