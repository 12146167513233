import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "../services";
import { 
    SpinnerService, 
    DialogService,
    SessionStorageService,
    LocalStorageService,
    CryptoJsService,
    RootComponentObserverService,
} from "../utils";

import PagesRoutingNames from "../helper/pages-routing-names.helper";

@Injectable({ providedIn: 'root' })
export class AuthUtilsService implements OnDestroy {
  toid1:any;
  toid2:any;
  toid3:any;
  toid4:any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private spinnerService: SpinnerService,
    private dialogService: DialogService,
    private sessionService: SessionStorageService,
    private localService: LocalStorageService,
    private cryptoService: CryptoJsService,
    private rootCompObserverService: RootComponentObserverService,
  ) {}

  ngOnDestroy(): void {
    if(this.toid1) { clearTimeout(this.toid1)}
    if(this.toid2) { clearTimeout(this.toid2)}
    if(this.toid3) { clearTimeout(this.toid3)}
    if(this.toid4) { clearTimeout(this.toid4)}
  }

  logout(): void {
    if (this.getLocalUserInfo()) {
      this.spinnerService.loading.next(true);

      this.authService.logout().subscribe({
        next: (v) => {
          if (v.status === '200') {
            this.spinnerService.loading.next(false);
            this.dialogService.showSuccess('logout-success-lbl', 1200);

            this.localService.clearAuth();
            this.sessionService.clearSession();

            this.toid1 =  setTimeout(() => {
              this.rootCompObserverService.loadUserProfileInfo.next(true);
            }, 0);
            this.toid2 = setTimeout(() => {
              this.router.navigate([`/${PagesRoutingNames.PAGES}`]);
            }, 1);
          }
        },
        error: (e) => {
          this.spinnerService.loading.next(false);

          this.localService.clearAuth();
          this.sessionService.clearSession();
          
          this.toid3 =  setTimeout(() => {
            this.rootCompObserverService.loadUserProfileInfo.next(true);
          }, 0);
          this.toid4 = setTimeout(() => {
            this.router.navigate([`/${PagesRoutingNames.PAGES}`]);
          }, 1);
        },
      });
    }
  }

  getLocalUserInfo(): any {
    const _t = this.localService.getUserProfileInfo();
    if (_t) {
      return JSON.parse(this.cryptoService.decrypt(_t));
    }
    return null;
  }

  getLocalUserMainGameWallet(): any {
    const _t = this.localService.getUserProfileInfo();
    if (_t) {
      const ui = JSON.parse(this.cryptoService.decrypt(_t));
      const { wallets } = ui;
      return wallets;
    }
    return null;
  }

  swnwErrorHandler(error: any) {
    if (!error.message) {
      this.dialogService.showError({ message: 'something-wrong-lbl' }, 2000);

    } else if (error.status === '5015') {
      this.dialogService.showError(error, 0);
      this.checkUserInfoAndLogout();

    } else if (error.message === 'Not logged in yet.' || error.message === 'Token not found') {
      if(error.message !== 'Token not found') this.dialogService.showError(error, 2000);
      
      this.rootCompObserverService.loadUserProfileInfo.next(true);

      this.localService.clearAuth();
      this.sessionService.clearSession();
      this.router.navigate([`/${PagesRoutingNames.PAGES}`]);
      
    } else if (error.message === 'Old password is wrong.') {
      this.dialogService.showError({ message: 'old-password-wrong-lbl' });

    } else if (error.message === 'No user found.') {
      this.dialogService.showError({ message: 'user-not-found-lbl' });

    } else if (error.message === 'undefined') {
      this.dialogService.showError({ message: 'something-wrong-lbl' });

    } else if (error.status === '5016') {
      this.dialogService.showWarning('game-server-maintenance-lbl', 0);

    } else if (error.status === '5017') {
      this.dialogService.showWarning('access-denie-onoff-vpn-lbl', 0);

    } else {
        this.dialogService.showError(error,0);
    }
  }

  checkUserInfoAndLogout() {
    const _t = this.localService.getUserProfileInfo();
    
    if (_t) {
      this.logout();
    }
  }
}