import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AuthUtilsService,
  AuthInterceptorService,
  CryptoJsService,
  DialogService,
  LocalStorageService,
  NavigatorHelperService,
  PhoneNumDisplayService,
  RootComponentObserverService,
  RoutesGuardService,
  SessionStorageService,
  SpinnerService,
  SWNWDateTimeService,
  SWNWFormService,
  LotteryUtilsServices,
  OtherGamesService,
  GFLanguageService, 
  DeviceInfoDetectorService,
  UIHandlerService,
  RemoteImageDownloadService, 

  //SpinLuckyDrawUtilService,
} from './utils';

import { 
  AuthService,
  ProfileService,
  GamesService,
  PromotionsService,
  WalletService,
  MobileLegendsService,
  InvitationService,
  SpinLuckyDrawService,
  MyTransactionHistoryService, 
  VIPCashbackService,
  SplashScreenService,
  ClearCacheDoneService
} from './services';

export const CORE_PROVIDERS = [
  AuthService,
  ProfileService,
  GamesService,
  PromotionsService,
  WalletService,
  MobileLegendsService,
  InvitationService,
  SpinLuckyDrawService,
  DeviceInfoDetectorService,
  UIHandlerService,
  VIPCashbackService, 
  SplashScreenService, 
  ClearCacheDoneService,
  
  AuthUtilsService,
  AuthInterceptorService,
  CryptoJsService,
  DialogService,
  LocalStorageService,
  NavigatorHelperService,
  PhoneNumDisplayService,
  RootComponentObserverService,
  RoutesGuardService,
  SessionStorageService,
  SpinnerService,
  SWNWDateTimeService,
  SWNWFormService,
  LotteryUtilsServices,
  OtherGamesService,
  GFLanguageService, 
  MyTransactionHistoryService, 
  RemoteImageDownloadService, 
  

  //SpinLuckyDrawUtilService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [DatePipe],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...CORE_PROVIDERS,
      ],
    };
  }
}
