import { Component, Input, OnInit, AfterViewInit } from "@angular/core";
import { Observable, Observer } from "rxjs";

@Component({
    selector:'social-medias-component',
    templateUrl:'./social-medias.component.html',
    styleUrls:['./social-medias.component.scss']
})
export class SocialMediasComponent implements OnInit, AfterViewInit {
    @Input() marginTop:string|any;

    constructor(){}

    ngOnInit(): void {
        if(!this.marginTop) this.marginTop = '8px';
    }

    ngAfterViewInit(): void {
        const divel = <HTMLElement>document.querySelector('.social-medias-div');
        if(divel) divel.style.marginTop = this.marginTop;
    }
}
