<div *ngIf="!isHide" class="d-flex align-items-center swnw-header">
    <div class="p-2 flex-grow-1">
        <img 
        src="../../../../assets/imgs/logo/logo.png" 
        alt="logo" 
        class="swnw-logo" 
        style="cursor: pointer;"
        (click)="gotoHome()">
    </div>

    <ng-container *ngIf="!isHeaderIconsHideAll">

        <div class="p-2" (click)="onHeaderIconEvent('contact')">
            <i class="bi bi-telephone-fill swnw-icons"></i>
        </div>
        <div class="p-2" (click)="onHeaderIconEvent('download-app')">
            <i class="bi bi-download swnw-icons"></i>
            <label class="header-icon-lbl"> App</label>
        </div>
        <div class="p-2">
            <img src="../../../../assets/imgs/logo/reload.svg" alt="re-load"
            class="header-refresh-icon" (click)="onHeaderIconEvent('refresh')">
        </div>
    </ng-container>
</div>

<!-- to comment when Sysytem Maintain! -->
<div *ngIf="isHide && !isSplashScreenShowing" class="back-nav-header" 
[ngStyle]="{'background':isShowHeaderLanguageIcon ? 'unset':'#F5E021'}">
    <div class="d-flex align-items-center">
        <div class="p-2">
            <img src="../../../../assets/imgs/home/v2/header/back-btn.png" 
            class="back-btn-img"
            alt="back-btn" 
            id="back-nav-header-icon"
            (click)="onBackClick()" >
        </div>
        <div class="p-2 flex-grow-1 child-nav-header-text-container">
            <ng-container *ngIf="!isShowHeaderLanguageIcon">
                <ng-container *ngIf="imgTitle;else TextTitleShowing">
                    <img [src]="imgTitle" alt="image-title" width="75%">
                </ng-container>
                <ng-template #TextTitleShowing>
                    <label>{{navTitleOnHiding | translate}}</label>
                </ng-template>
            </ng-container>
        </div>
        <div *ngIf="!isHideRefresh" class="p-2">
            <ng-container *ngIf="isShowHeaderLanguageIcon">
                <div class="lang-refresh-img-container">
                    <img 
                    [src]="selectedLangFlag" 
                    class="profile-panel-language-flag" 
                    alt="selected-language"
                    (click)="onHeaderIconEvent('choose-language')">
                </div>
                
            </ng-container>

            <div class="lang-refresh-img-container">
                <img src="../../../../assets/imgs/logo/reload.svg" alt="re-load"
                class="header-refresh-icon" (click)="onHeaderIconEvent('refresh')">
            </div>
        </div>
    </div>
</div>