import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import PagesRoutingNames from './@core/helper/pages-routing-names.helper';

import { ChooseLanguageComponent } from './choose-language/choose-language.component';
import { VerifyOTPComponent } from './verify-otp/verify-otp.component';
import { TermsConsComponent } from './terms_cons/terms_cons.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';

import { UnderMaintainPageComponent } from './under-maintain-page/under-maintain-page.component';

import { GameMaintainCollectAlertComponent } from './game-maintain-collect-alerts/game-maintain-collect-alerts.component';

const routes: Routes = [
  //** to comment all when Sysytem Maintain! */
  {
    path:PagesRoutingNames.CHOOSE_LANGUAGE,
    component: ChooseLanguageComponent,
  },
  {
    path: PagesRoutingNames.VERIFY_OTP,
    component:VerifyOTPComponent,
  },
  {
    path: PagesRoutingNames.PAGES,
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path:PagesRoutingNames.MY_AUTH,
    loadChildren:()=>import('./my-auth/my-auth.module')
    .then(m=>m.MyAuthModule)
  },
  {
    path:PagesRoutingNames.TERMS_CONS,
    component: TermsConsComponent,
  },
  {
    path:PagesRoutingNames.SPLASH_SCREEN,
    component:SplashScreenComponent,
  },
  {
    path:PagesRoutingNames.GAME_MAINTAIN_COLLECT_ALERT,
    component:GameMaintainCollectAlertComponent,
  },
  { path: '', redirectTo: PagesRoutingNames.SPLASH_SCREEN, pathMatch: 'full' },
  
  /* {
    path:PagesRoutingNames.SPIN_LUCKY_DRAW,
    loadChildren:()=>import('./spin-lucky-draw/spin-lucky-draw.module')
    .then(m=>m.SpinLuckyDrawModule)
  },
   */
  
  /* {
    path:PagesRoutingNames.UNDER_MAINTAIN_PAGE,
    component: UnderMaintainPageComponent,
  },
  { path: '', redirectTo: PagesRoutingNames.UNDER_MAINTAIN_PAGE, pathMatch: 'full' }, */
];

const config:ExtraOptions = {
  useHash:false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
