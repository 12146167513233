<div class="this-container-1">
  <div class="d-flex align-items-center clickable-section" 
  *ngFor="let lan of languages">
    <div class="p-2">
      <label for="lan-{{lan.id}}">
        <img class="cursor-pointer-el" [src]="lan.flag" alt="country-flag" width="32" height="32">
      </label>
    </div>
    <div class="p-2 flex-grow-1" style="text-align: start;">
      <label for="lan-{{lan.id}}" class="cursor-pointer-el lbl-width">{{lan.name}}</label>
    </div>
    <div class="p-2">
      <ng-container *ngIf="lan.value === selectedLangName;else NotSelected">
        <img src="../../assets/imgs/profile-and-language/v2/radio-input-type-imgs/selected.png" 
        alt="selected" 
        (click)="onChangeLanguage(lan.value)"
        class="language-radio-selector">
      </ng-container>

      <ng-template #NotSelected>
        <img src="../../assets/imgs/profile-and-language/v2/radio-input-type-imgs/not-select.png" 
        alt="not-selected"
        (click)="onChangeLanguage(lan.value)"
        class="language-radio-selector">
      </ng-template>
      
    </div>
  </div>
</div>