<div class="winner-stage-container">
    <div class="winner-card-container">
        <div *ngFor="let winner of top3Winners;let i=index;">
            <top-3-winner-card-component *ngIf="winner" [winner]="winner" 
            [topNumber]="i===0 ? '1' : (i===1 ? '2' : '3')"
            [ngClass]="i===0 ? 'card-1' : (i===1 ? 'card-2' : 'card-3')"></top-3-winner-card-component>
        </div>
    </div>
    <div class="stage-container">
        <div class="stage"></div>
    </div>
</div>