import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";

import { LocalStorageService } from "./local-storage.service";
import { CryptoJsService } from "./crypto-js.service";

import { ClosedDays } from "../helper/two-three-d.helper";

@Injectable({providedIn:'root'})
export class SWNWDateTimeService {
    currentTimerTimeout:any;

    constructor(
        private localService:LocalStorageService,
        private cryptoService:CryptoJsService,
        private datePipe: DatePipe,
    ){}

    getTodayStartEnd():any{
        const sd = new Date();
        sd.setHours(0,0,0,0);

        const ed = new Date(sd);
        ed.setHours(23,59,59,999);

        return {start:new Date(sd), end:new Date(ed)};
    }

    initTodayStartEnd():any{
        const {start, end}=this.getTodayStartEnd();

        const _d = this.localService.getTodayStartEnd();
        if(_d){
            const sessionToday = JSON.parse(this.cryptoService.decrypt(_d));
            
            if(sessionToday.start===start && sessionToday.end===end){
                return sessionToday;
            }
            this.localService.saveTodayStartEnd(this.cryptoService.encrypt(JSON.stringify({start:start, end:end})));
            return {start:start, end:end};
        }
        
        this.localService.saveTodayStartEnd(this.cryptoService.encrypt(JSON.stringify({start:start, end:end})));
        return {start:start, end:end};
    }

    checkCurrentDateWithinToday():boolean{
        const todayDateTime=this.initTodayStartEnd();
        const currentDateTime = new Date();
        
        return currentDateTime>=todayDateTime.start && currentDateTime<=todayDateTime.end;
    }

    currentTimer(closedTimeStr:string=''){
        const currtimeEl = document.getElementById('currenttimeId');
        
        if(currtimeEl){
            if(!closedTimeStr){
                const cdt = new Date();
                const cdtStr = this.datePipe.transform(cdt, 'mediumTime');
                currtimeEl.innerHTML = <any>cdtStr;
                this.setCurrentTimeTimer();
            }else{
                const ct = new Date(closedTimeStr);
                const ctStr = this.datePipe.transform(ct, 'shortTime');
                currtimeEl.innerHTML = <any>ctStr;
                this.clearCurrentTimerTimeout();
            }
        }
    }
    setCurrentTimeTimer(){
        this.currentTimerTimeout = setTimeout(() => {
            this.currentTimer()
        }, 1000);
    }
    clearCurrentTimerTimeout(){
        clearTimeout(this.currentTimerTimeout);
    }

    getClosedDate(){
        const todayDate = new Date().setHours(0,0,0,0);
        
        const closedDays = ClosedDays.map((x:any)=>({
            ...x,
            date:new Date(new Date(x.date).setHours(0,0,0,0)).getTime()
        }));
        
        const idx = closedDays.findIndex((x:any)=>x.date===(new Date(todayDate).getTime()));
        if(idx>=0) return closedDays[idx];
        return null;
    }

    //'2022-05-12 04:30 PM'
    createDateForSafariMac(timeinYangon:string){
        const tmp = timeinYangon.split('-');//0>yr 1>month
        const tmp1 = tmp[2].split(' ');//0>day 2>AM or PM
        const hm = tmp1[1].split(':');//0>hrs 1>min

        return new Date(+tmp[0], (+tmp[1])-1, +tmp1[0], (tmp1[2]==='PM' && +hm[0]<12)? ((+hm[0])+12) : +hm[0], +hm[1], 0);
    }

    //'2022-05-05 01:08:36' (createddate/updateddate)
    createDateForSafariMac_2(dateString:string,isNoHMS=false){
        const tmp = dateString.split('-');//0>yr 1>month
        const tmp1 = tmp[2].split(' ');//0>day 1>hr:min:sec
        const hm = tmp1[1].split(':');//0>hrs 1>min 2>sec

        return new Date(+tmp[0], (+tmp[1])-1, +tmp1[0], isNoHMS ? 0:+hm[0], isNoHMS ? 0:+hm[1], isNoHMS ? 0:+hm[2], 0);
    }

    //2022-05-16
    createDateForSafariMac_3(dateString:string){
        const tmp = dateString.split('-');//0>yr 1>month 2>day

        return new Date(+tmp[0], (+tmp[1])-1, +tmp[2]);
    }

    //"1899-11-30T00:00:00.000Z"
    createDateForSafariMac_4(dateString:string){
        const tmp = dateString.split('-');//0>1899 1>11 2>30T00:00:00.000Z
        const tmp1 = tmp[2].split('T');//0>30 1>00:00:00.000Z

        return new Date(+tmp[0], (+tmp[1])-1, +tmp1[0]);
    }


    //"2022-08-01T20:26:06.000Z"
    createDateForSafariMac_5(dateString:string){
        const tmp = dateString.split('-');//0>2022 1>08 2>01T20:26:06.000Z
        const tmp1 = tmp[2].split('T');//0>01 1>20:26:06.000Z
        const tmp2 = tmp1[1].split(':'); //0>20 1>26 2>:06.000Z
        const tmp3 = tmp2[2].split('Z'); //0>06.000 1>empty!

        return new Date(+tmp[0], (+tmp[1])-1, +tmp1[0], +tmp2[0], +tmp2[1], +tmp3[0]);
    }

    getCurrentLocalTimeOnly(){
        return new Date(new Date().getTime()).toLocaleString('en-US',<any>{hour12:false}).slice((new Date().getDate()<=9?10:11), 19);
    }

    getHourAndMinuteString(time:string){
        return time.slice(0,time.lastIndexOf(':'));
    }

    convertDateToNumber(time:string){
        return +time.replace(':','.');
    }
    
    createDateTimeForSafariMac_6(timeOnlyString:string) {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();
        const tmp = timeOnlyString.split(':');
        const hr = +tmp[0];
        const min = +tmp[1];
        const sec = +tmp[2];

        return new Date(year, month, day, hr, min, sec);
    }

    //2022-05-16 //*24/02/2023
    createDateForSafariMac_7(dateString:string){
        const tmp = dateString.split('/');//0>yr 1>month 2>day 
        //*0=day, 1=month, 2=yr

        return new Date(+tmp[2], (+tmp[1])-1, +tmp[0]);
    }
}