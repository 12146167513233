<div>
    <div class="info-body">
        <logo-heading-component [titleDescription]="titleDescription"></logo-heading-component>

        <div class="input-section">
            <ng-container *ngIf="!viaTargetRoute">
                <label for="phone-num">
                    {{'verify-otp-desc-txt' | translate}}
                </label>
            </ng-container>

            <div class="tpa-input-group">
                <ng-container *ngIf="!viaTargetRoute">
                    <label>
                        {{'enter-code-lbl' | translate}}
                    </label>
                </ng-container>

                <ng-container *ngIf="viaTargetRoute">
                    <label>
                        {{'verify-with-your-pin-lbl' | translate}}
                    </label>
                </ng-container>

                <div class="d-flex justify-content-between">
                    <div class="p-2">
                        <input 
                        id="pin-num-1" 
                        type="number" 
                        maxlength="1"
                        (keyup)="onKeyUp($event)"
                        (keypress)="onKeyPress($event)" 
                        autofocus
                        autocomplete="off"
                        class="otp-input">
                    </div>
                    <div class="p-2">
                        <input 
                        id="pin-num-2" 
                        type="number" 
                        maxlength="1" 
                        (keyup)="onKeyUp($event)"
                        (keypress)="onKeyPress($event)"
                        autocomplete="off"
                        class="otp-input">
                    </div>
                    <div class="p-2">
                        <input 
                        id="pin-num-3" 
                        type="number" 
                        maxlength="1" 
                        (keyup)="onKeyUp($event)"
                        (keypress)="onKeyPress($event)"
                        autocomplete="off"
                        class="otp-input">
                    </div>
                    <div class="p-2">
                        <input 
                        id="pin-num-4" 
                        type="number" 
                        maxlength="1" 
                        (keyup)="onKeyUp($event)"
                        (keypress)="onKeyPress($event)"
                        autocomplete="off"
                        class="otp-input">
                    </div>
                    <div class="p-2">
                        <input 
                        id="pin-num-5" 
                        type="number" 
                        maxlength="1" 
                        (keyup)="onKeyUp($event)"
                        (keypress)="onKeyPress($event)"
                        autocomplete="off"
                        class="otp-input">
                    </div>
                    <div class="p-2">
                        <input 
                        id="pin-num-6" 
                        type="number" 
                        maxlength="1" 
                        (keyup)="onKeyUp($event)"
                        (keypress)="onKeyPress($event)"
                        autocomplete="off"
                        class="otp-input">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="p-2">
                    <ng-container *ngIf="!viaTargetRoute && countdown && countdown.status!==0 && !isPinInputDialogShowed">
                        <a class="clickable-a-link" (click)="onResendCode()">
                            {{'resend-code-lbl' | translate}}
                        </a>
                    </ng-container>

                    <ng-container *ngIf="!viaTargetRoute && countdown && countdown.status===0 || isPinInputDialogShowed">
                        <a class="clickable-a-link" style="color:grey !important">
                            {{'resend-code-lbl' | translate}}
                        </a>
                    </ng-container>
                    
                </div>
                <div class="p-2" [ngStyle]="{'display':!viaTargetRoute?'block':'none'}">
                    <div id="countdown">
                        <countdown #cd [config]="countdownConfig"></countdown>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="action-footer">
        <ng-container *ngIf="gotCodes && gotCodes.length===6;else DisableBtn">
            <button class="btn btn-default" (click)="onContinue()">
                {{'continue-lbl' | translate}}
            </button>
        </ng-container>

        <ng-template #DisableBtn>
            <button class="btn btn-default" disabled>
                {{'continue-lbl' | translate}}
            </button>
        </ng-template>
    </div>

    <div>
        <service-and-contact-component></service-and-contact-component>
    </div>
</div>

<div id="pinInputModal" class="swnw-modal">
    <div class="swnw-modal-content" [ngStyle]="{'max-width':deviceInnerWidth>=446?'410px': '300px'}">
        <div class="modal-header">
            <div>{{'enter-pin-num-lbl' | translate}}</div>
        </div>
        <div class="modal-body">
            
            <div class="pin-input-container">
                <div class="d-flex align-items-center">
                    <div class="p-2 flex-grow-1" style="padding-right: 0 !important;">
                        <input 
                        class="pin-input" 
                        name="password" 
                        type="password" 
                        inputmode="numeric"
                        id="input-password" 
                        autocomplete="off" 
                        placeholder="{{'six-digits-txt' | translate}}..." 
                        maxlength="6" 
                        (keypress)="onKeyPressInputPINDialog($event)">
                    </div>
                    <div class="p-2" style="padding-left: 0 !important;">
                        <i [ngClass]="isLookPin ? 'bi bi-eye-fill':'bi bi-eye-slash-fill'" class="pin-look-toggle" (click)="pinLookToggle()"
                        style="margin: 0 !important;"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer" style="text-align:center;">
            <div class="d-flex justify-content-around">
                <div class="p-2">
                    <button class="btn btn-default footer-btn" (click)="onOKPinInput()">
                        {{'ok-lbl' | translate}}
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>