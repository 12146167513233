import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ProfileHeaderPanel } from "./components/profile-header-panel/profile-header-panel.component";
import { SwnwDropDown } from "./components/dropdown/dropdown.component";
import { GameCardComponent } from "./components/game-card/game-card.component";
import { TranslateModule } from "@ngx-translate/core";
import { KeenSliderComponent } from "./components/keen-slider/keen-slider.component";
import { FiltersComponent } from "./components/filters/filters.component";
import { SocialMediasComponent } from "./components/social-medias/social-medias.component";
import { FeaturesPanelComponent } from "./components/features-panel/features-panel.component";
import { WalletInfoComponent } from "./components/wallet-info/wallet-info.component";
import { MarqueenComponent } from "./components/marqueen-component/marqueen.component";
import { UTubeCardComponent } from "./components/utube-card/utube-card.component";
import { ContactPhonesComponent } from "./components/contact-phones/contact-phones.component";
import { ServiceAndContactComponent } from "./components/service-and-contact/service-and-contact.component";
import { ResultNumberPanelComponent } from "./components/result-number-panel/result-number-panel.component";
import { Top3WinnerCardComponent } from "./components/top-3-winner-card/top-3-winner-card.component";
import { Top3WinnersStageComponent } from "./components/top-3-winners-stage/top-3-winners-stage.component";
import { LogoHeadingComponent } from "./components/logo-heading/logo-heading.component";
import { PINCreateCautionComponent } from "./components/pin-create-caution/pin-create-caution.component";

@NgModule({
    imports:[
        CommonModule,
        RouterModule, 
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        NgxDaterangepickerMd.forRoot()
    ],
    declarations:[
        HeaderComponent, 
        FooterComponent, 
        ProfileHeaderPanel, 
        SwnwDropDown,
        GameCardComponent,
        KeenSliderComponent,
        FiltersComponent,
        SocialMediasComponent, 
        FeaturesPanelComponent,
        WalletInfoComponent,
        MarqueenComponent,
        UTubeCardComponent,
        ContactPhonesComponent,
        ServiceAndContactComponent, 
        ResultNumberPanelComponent,
        Top3WinnerCardComponent,
        Top3WinnersStageComponent,
        LogoHeadingComponent,
        PINCreateCautionComponent,
    ],
    exports:[
        HeaderComponent, 
        FooterComponent, 
        ProfileHeaderPanel, 
        SwnwDropDown,
        GameCardComponent,
        KeenSliderComponent,
        FiltersComponent,
        SocialMediasComponent, 
        FeaturesPanelComponent,
        WalletInfoComponent,
        MarqueenComponent,
        UTubeCardComponent,
        ContactPhonesComponent,
        ServiceAndContactComponent,
        ResultNumberPanelComponent,
        Top3WinnerCardComponent,
        Top3WinnersStageComponent,
        LogoHeadingComponent,
        PINCreateCautionComponent,
    ],
    providers:[DatePipe],
})
export class SharedModule{}