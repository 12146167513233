<marquee scrolldelay="80" class="wallet-features-panel" style="margin-bottom: 8px !important;">

    <div class="d-flex align-items-center">
        <div class="p-2">
            <img src="../../../../assets/imgs/home/v2/announcement.svg" alt="announcement">
        </div>

        <div class="p-2 flex-grow-1">
            <ng-container *ngIf="isShowTodayDateTimeText">
                <div class="d-flex align-items-center flex-wrap" style="margin: 0 !important;">
                    <div class="p-2" id="currenttimeId" [ngStyle]="{'text-align':deviceWidth>320?'end':'center'}"></div>
                    <div class="p-2 title-lbl-12" style="font-weight: 500;" [ngStyle]="{'text-align':deviceWidth>320?'start':'center'}">
                        {{today | date:'fullDate'}}
                    </div>
                </div>
            </ng-container>
        
            <ng-container *ngIf="!isShowTodayDateTimeText">
                {{descriptionText ? descriptionText:'Hi Nice To Meet You...'}}
            </ng-container>
        </div>
    </div>
</marquee>