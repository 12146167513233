import { Injectable } from "@angular/core";

import { 
    LocalStorageService, 
    CryptoJsService, 
} from "./index";

@Injectable({providedIn:'root'})
export class GFLanguageService {
    
    constructor(
        private localService:LocalStorageService, 
        private cryptoService:CryptoJsService,
    ){}

    getSelectedLanguage(){
        const t = this.localService.getChooseLangugae();
        if(t){
            const sl = this.cryptoService.decrypt(t);
            
            if(sl==='my'){
                return 'mm';
            }else if(sl==='en'){
                return 'eng';
            }else if(sl==='zh'){
                return 'ch';
            }
            return 'mm';
        }
        return 'mm';
    }
}