import { Injectable } from "@angular/core";

const charPattern = /[A-Za-z]+/g;

@Injectable({providedIn:'root'})
export class SWNWFormService {
    constructor(){}
    
    checkPinStrength(pw:string):boolean{
        const weekPWs = ['000000','111111','123456','456789','567890','121212','222222','333333','212121',
        '654321','343434','454545','565656','676767','787878','898989','909090'];

        return weekPWs.includes(pw);
    }
}