import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { 
    DialogService, 
    SessionStorageService, 
    CryptoJsService, 
} from 'src/app/@core/utils';

import PagesRoutingNames from 'src/app/@core/helper/pages-routing-names.helper';

@Component({
    selector:'wallet-info-component',
    templateUrl: './wallet-info.component.html',
    styleUrls: ['./wallet-info.component.scss']
})
export class WalletInfoComponent {
    @Input() returnTargetComponent:string|any;
    @Input() isLoggedIn:boolean|any;
    @Input() mainWallet:string |any;
    @Input() gameWallet:string |any;
    @Input() mainGameId:string | any;

    constructor(
        private router: Router,
        private cryptoService:CryptoJsService,
        private dialogService:DialogService,
        private sessionService:SessionStorageService,
    ) {}

    onAvailabelPlaces(balanceType:string){
        this.dialogService.isShowWalletAvailable.next(balanceType);
    }

    onWalletFill(_target:string){
        this.sessionService.saveWalletFillClickViaHome(this.cryptoService.encrypt(
            JSON.stringify({target:_target, backUrl: this.returnTargetComponent, maingameid:this.mainGameId})
        ));
        
        this.goTransfer();
    }

    goTransfer(){
        this.router.navigate([
            `/${PagesRoutingNames.PAGES}/${PagesRoutingNames.WALLET_MANAGE}/${PagesRoutingNames.WALLET_MANAGE_TRANSFER}`
        ]);
    }

    goLogin(){
        this.router.navigate([`/${PagesRoutingNames.MY_AUTH}`]);
    }
}