import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({providedIn:'root'})
export class NavigatorHelperService implements OnDestroy{
    public pageName = new Subject();
    public isHideHeaderFooter = new Subject();
    public isHideProfileHeaderPanel = new Subject();

    toid1:any;

    backToHomeRoutes = [
        '/pages/home',
        '/pages/two-d/home',
        '/pages/three-d/home',
        '/pages/other-games/2',
        '/pages/other-games/3',
        '/pages/other-games/4',
        '/pages/home-wallet',
        '/pages/promotions',
        '/pages/contact',
        '/pages/profile',
        '/pages/download-app',
    ];

    backToWalletHomeRoutes = [
        '/pages/wallet/withdraw',
        '/pages/tutorials/1',
        '/pages/tutorials/2',
    ];

    backTo2DHomeRoutes = [
        '/pages/two-d/winners',
        '/pages/two-d/result-history',
        '/pages/two-d/bet-history',
        '/pages/lottery-closed-days',
        '/pages/two-d/normal-bet',
    ];

    backTo3DHomeRoutes = [
        '/pages/three-d/winners',
        '/pages/three-d/result-history',
        '/pages/three-d/bet-history',
        '/pages/three-d/three-d-normal-bet',
    ];
    
    constructor(){
        this.pageName.next('');
        this.isHideHeaderFooter.next(false);
        this.isHideProfileHeaderPanel.next(false);
    }

    ngOnDestroy(): void {
        if(this.toid1) { clearTimeout(this.toid1)}
      }
    

    deviceFullWidthUpdater(isFull:boolean=false){
        const mobileContainer = <HTMLElement>document.getElementsByClassName('swnw-mobile-container')[0];
        mobileContainer.style.maxWidth = isFull?'100%':'480px';

        const swnwBody = <HTMLElement>document.getElementById('swnw-body');
        swnwBody.style.background = isFull?'black':'none';

        this.toid1 = setTimeout(() => {
            (<HTMLElement>swnwBody.firstChild).style.padding='4px 12px 0 12px';
          }, 500);
    }
}