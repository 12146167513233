import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import PagesRoutingNames from "../@core/helper/pages-routing-names.helper";
import { SplashScreenService } from "../@core/services";
import { DialogService } from "../@core/utils";
import { CountdownComponent } from 'ngx-countdown';

@Component({
    templateUrl:'./splash-screen.component.html',
    styleUrls:['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('cd', { static: false }) countdown: CountdownComponent|any;
    countdownConfig = { leftTime: 5, format: 's' };//unit=> sec
    
    splashImgContainer:any;

    splashImgs:Array<any> = [];
    isShow=false;

    toid1:any;
    toid2:any;
    toid3:any;
    toid4:any;
    toid5:any;
    toid6:any;

    constructor(
        private router:Router, 
        private splashscreenService:SplashScreenService, 
        private dialogService:DialogService, 
    ) {}

    ngOnInit(): void {
        this.getSplashImg();
    }

    ngAfterViewInit(): void {
        this.toid1 = setTimeout(() => {
            this.splashImgContainer = <HTMLElement>document.querySelector('#splash-img-container');
            if(this.splashImgContainer) {
                this.splashImgContainer.style.height = `${window.innerHeight}px`;
            }
        }, 10);
    }

    ngOnDestroy(): void {
        if(this.toid1) clearTimeout(this.toid1);
        if(this.toid2) clearTimeout(this.toid2);
        if(this.toid3) clearTimeout(this.toid3);
        if(this.toid4) clearTimeout(this.toid4);
        if(this.toid5) clearTimeout(this.toid5);
        if(this.toid6) clearTimeout(this.toid6);
    }

    onSkipSplashScreen() {
        this.router.navigate([`/${PagesRoutingNames.PAGES}`]);
    }

    getSplashImg() {
        this.splashscreenService.getSplashImg().subscribe({
            next:(res:any) => {
                if(res.status === '200') {
                    this.splashImgs = res.data.splash_image.map((x:any) => ({
                        ...x,
                        path:x.link,
                        actionLink:x.action_link
                    }));

                    this.isShow = true;

                    this.countdownConfig.leftTime = this.splashImgs[0].timer;

                    this.toid2 = setTimeout(() => {
                        this.countdown.begin();
                    }, 0);
                } else {
                    this.dialogService.showError(res);
                }
            },
            error:(e => {
                this.dialogService.showError(e.error);
            }),
            complete:() => {
                if(this.splashImgs.length===1) {
                    this.toid3 = setTimeout(() => {
                        this.splashImgContainer.classList.add('fade-out-image');
                    }, (this.splashImgs[0].timer - 1)*1000);

                    this.toid4 = setTimeout(() => {

                        this.countdown.stop();

                        this.router.navigate([`/${PagesRoutingNames.PAGES}`]);
                        
                    }, (this.splashImgs[0].timer * 1000));
                } else {
                    this.countdownConfig.leftTime = this.splashImgs[0].timer * (this.splashImgs.length);

                    let tto = (this.splashImgs[0].timer * 1000) * this.splashImgs.length;

                    this.toid5 = setTimeout(() => {
                        this.splashImgContainer.classList.add('fade-out-image');
                    }, tto);
                    
                    this.toid6 = setTimeout(() => {

                        this.countdown.stop();

                        this.router.navigate([`/${PagesRoutingNames.PAGES}`]);

                    }, tto);
                }
            }
        });
    }
}