import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import {
  DialogService,
  SpinnerService,
  UIHandlerService,
  GFLanguageService,
  AuthUtilsService,
} from 'src/app/@core/utils';

import { ProfileService, GamesService } from '../@core/services';
import { takeUntil, Subject } from 'rxjs';

@Component({
  templateUrl: './game-maintain-collect-alerts.component.html',
  styleUrls: ['./game-maintain-collect-alerts.component.scss'],
})
export class GameMaintainCollectAlertComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedLanguage:string|any;
  unsubscribe$ = new Subject<void>();

  maintainAlerts:any;

  toid1:any;

  constructor(
    private dialogService: DialogService,
    private spinnerService:SpinnerService,
    private gfLanguageService:GFLanguageService,
    private profileService:ProfileService,
    private authUtilsService:AuthUtilsService,
    private gamesService:GamesService,
    private uiHandlerService:UIHandlerService,
  ) {
    this.selectedLanguage = this.gfLanguageService.getSelectedLanguage();
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  ngAfterViewInit(): void {
    this.toid1 = setTimeout(() => {
      this.uiHandlerService.calMainBodyHeightWithBackHeader()
    }, 100);
  }

  ngOnDestroy(): void {
    if(this.toid1) clearTimeout(this.toid1)
  }

  //
  onReadMore(target$:any) {
    const doc = <HTMLElement>document.getElementById('game-maintain-card-content-text'+target$);
    doc.classList.remove("truncate-2");

    const readmorespan = <HTMLElement>document.getElementById('readmore'+target$);
    const readlessspan = <HTMLElement>document.getElementById('readless'+target$);
    readmorespan.classList.remove("read-more-span-show");
    readlessspan.classList.add("read-more-span-show");
  }
  onReadLess(target$:any) {
      const doc = <HTMLElement>document.getElementById('game-maintain-card-content-text'+target$);
      doc.classList.add("truncate-2");

      const readmorespan = <HTMLElement>document.getElementById('readmore'+target$);
      const readlessspan = <HTMLElement>document.getElementById('readless'+target$);
      readlessspan.classList.remove("read-more-span-show");
      readmorespan.classList.add("read-more-span-show");
  }
  onCollect(maingameid:number) {
    if(maingameid) {
      this.spinnerService.loading.next(true);

      const pl = new URLSearchParams();
      pl.append("maingameid", `${maingameid}`);

      this.gamesService.collectGameMoneyOnMaintain(pl).subscribe({
          next:((v:any) =>{
              this.spinnerService.loading.next(false);
              this.dialogService.showGameMaintainCollectDialog('success-title', 'Collect Success. Please check your wallet.',0);

              this.maintainAlerts = this.maintainAlerts.filter((x:any) => x.main_game_id !== maingameid);
          }),
          error:((error:any) => {
              this.spinnerService.loading.next(false);
              this.authUtilsService.swnwErrorHandler(error.error);
          })
      });
    } else {
        this.dialogService.showWarning('Please insert main-game-id to collect your game money!');
    }
    //this.dialogService.showGameMaintainCollectDialog('success-title', 'Collect Success. Please check your wallet.',0);
    //this.dialogService.showGameMaintainCollectDialog('cannot-collect-title', 'Please check back in a minute.',0);
  }

  getUserInfo(){
    const pl = new URLSearchParams();
    pl.append('language', this.selectedLanguage);
    
    this.profileService.getUserInfo(pl).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next:(v:any)=>{
          if(v.status==='200'){
            this.maintainAlerts = [...v.data.balance_freeze];
          }
          else{
              this.authUtilsService.swnwErrorHandler(v);
          }
      },
      error:(e)=>{
          this.authUtilsService.swnwErrorHandler(e.error);
      }
    });
  }
}
