import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";

@Injectable({providedIn:'root'}) 
export class RemoteImageDownloadService {
    private base64Image: any;
    
    downloadImage(imageUrl$:string) {
        //let imageUrl = 
          //"http://southparkstudios.mtvnimages.com/shared/characters/celebrities/mr-hankey.png?height=165";
          //'https://www.royalhouse2d.tech/royal/imgv3/topupcover.png';
          //'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/440px-QR_code_for_mobile_English_Wikipedia.svg.png';

        //window.open(imageUrl, '_blank');
    
        /* this.getBase64ImageFromURL(imageUrl$).subscribe((base64data:any) => {
          
          this.base64Image = "data:image/jpg;base64," + base64data;
          
          // save image to disk
          var link = document.createElement("a");
    
          document.body.appendChild(link); // for Firefox
    
          link.setAttribute("href", this.base64Image);
          link.setAttribute("download", "download_image.jpg");
          link.click();
        }); */
        var link = document.createElement("a");
    
        document.body.appendChild(link); // for Firefox
    
        link.setAttribute("href", imageUrl$);
        link.setAttribute("download", "download_image.jpg");
        link.click();
    }
    
    private getBase64ImageFromURL(url: string) {
        return Observable.create((observer: Observer<string>) => {
          const img: HTMLImageElement = new Image();
          img.crossOrigin = "Anonymous";
          img.src = url;
          if (!img.complete) {
            img.onload = () => {
              observer.next(this.getBase64Image(img));
              observer.complete();
            };
            img.onerror = err => {
              observer.error(err);
            };
          } else {
            observer.next(this.getBase64Image(img));
            observer.complete();
          }
        });
    }
    
    private getBase64Image(img: HTMLImageElement) {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: CanvasRenderingContext2D|any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL: string = canvas.toDataURL("image/png");
    
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }
}