import { AuthService } from './auth.service';
import { GamesService } from './games.service';
import { ProfileService } from './profile.service';
import { PromotionsService } from './promotions.service';
import { WalletService } from './wallet.service';
import { MobileLegendsService } from './mobile-legends.service';
import { InvitationService } from './invitation.service';
import { SpinLuckyDrawService } from './spin-lucky-draw.service';
import { MyTransactionHistoryService } from './txn-history.service';
import { VIPCashbackService } from './vip-cashback.service';
import { MyCommissionFriendService } from './my-commission-friend.service';
import { SplashScreenService } from './splashscreen.service';
import { ClearCacheDoneService } from './clear-cache-done.service';

export {
    AuthService,
    ProfileService,
    GamesService,
    PromotionsService,
    WalletService,
    MobileLegendsService,
    InvitationService,
    SpinLuckyDrawService, 
    MyTransactionHistoryService, 
    VIPCashbackService, 
    MyCommissionFriendService, 
    SplashScreenService,
    ClearCacheDoneService
};