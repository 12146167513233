import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";

import Languages from "src/app/@core/helper/language.helper";

import { TranslateService } from "@ngx-translate/core";
import { 
    LocalStorageService,
    CryptoJsService, 
    RootComponentObserverService,
    UIHandlerService, 
} from "../@core/utils";

@Component({
    templateUrl: './choose-language.component.html',
    styleUrls: ['./choose-language.component.scss'],
})
export class ChooseLanguageComponent implements OnInit, AfterViewInit, OnDestroy {
    languages:[]|any;
    selectedLanguageId:any;

    selectedLangName:string|any;

    toid1:any;
    toid2:any;

    constructor(
        private translate:TranslateService,
        private localService: LocalStorageService,
        private cryptoService:CryptoJsService,
        private rootCompObserverService:RootComponentObserverService,
        private uiHandlerService:UIHandlerService,
    ){
        this.languages = Languages;
    }

    ngOnInit(): void {
        const _t = this.localService.getChooseLangugae();
        if(_t){
            const lng = this.cryptoService.decrypt(_t);
            switch(lng){
                case 'my':{
                    this.selectedLanguageId = 'lan-1';
                    break;
                }
                case 'en':{
                    this.selectedLanguageId = 'lan-2';
                    break;
                }
                case 'zh':{
                    this.selectedLanguageId = 'lan-3';
                    break;
                }
                default: this.selectedLanguageId = 'lan-1';
            }

            this.selectedLangName = lng;
        }else{
            this.selectedLanguageId = 'lan-1';

            this.selectedLangName = 'my';
        }


        this.toid2 = setTimeout(() => {
            this.checkedOptions(this.selectedLanguageId);
        }, 1200);
    }

    ngAfterViewInit(): void {
        this.toid1 = setTimeout(() => {
            this.uiHandlerService.calMainBodyHeightWithBackHeader();
        }, 100);
    }

    ngOnDestroy(): void {
        if(this.toid1) {clearTimeout(this.toid1)}
        if(this.toid2) {clearTimeout(this.toid2)}
    }

    onChangeLanguage(evt:any){
        this.selectedLangName = evt;
        
        this.translate.use(evt);

        this.localService.saveChooseLangugae(this.cryptoService.encrypt(evt));
        this.rootCompObserverService.loadSelectedLanguage.next(true);
    }

    checkedOptions(_id:string){
        const opt:any = document.getElementById(_id);
        if(opt) opt.checked = true;
    }
}