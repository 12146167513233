<select 
[id]="id" 
[name]="name|translate"
class="selector-control"
(change)="onSelectOption($event)">
  <option *ngFor="let data of list" 
  [value]="data.value" 
  class="swnw-dd-opt">
    {{data.label|translate}}
  </option>
</select>

