import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class MobileLegendsService {
    private DEFAULT_URL = `${environment.API_ENDPOINT}/${environment.DEFAULT_PATH}`;

    constructor(private http: HttpClient) {}

    getDiamondsList(payload:any):Observable<any>{//59
        return this.http.post<any>(`${this.DEFAULT_URL}/mobilelegends/info`, payload);
    }

    buyDiamond(payload:any):Observable<any>{
        return this.http.post<any>(`${this.DEFAULT_URL}/mobilelegends/order`, payload);
    }

    getHistoryList(payload:any):Observable<any>{
        return this.http.post(`${this.DEFAULT_URL}/mobilelegends/orderlist`, payload);
    }

    preOrder(payload:any):Observable<any>{
        return this.http.post(`${this.DEFAULT_URL}/mobilelegends/preorder`, payload);
    }
}
