<div class="social-medias-div">
    <a class="clickable-a-link social-btn-margin" href="viber://contact?number=%2B959255587664" target="_blank">
        <img src="../../../assets/imgs/contact_us/35x35-viber.png" alt="viber" class="social-icon-img">
    </a>
    <a class="clickable-a-link social-btn-margin" href="https://m.me/OneStarMM" target="_blank">
        <img src="../../../assets/imgs/contact_us/35x35-messenger.png" alt="messenger" class="social-icon-img">
    </a>
    <a class="clickable-a-link social-btn-margin" href="https://www.facebook.com/OneStarMM" target="_blank">
        <img src="../../../assets/imgs/contact_us/35x35-facebook.png" alt="facebook" class="social-icon-img">
    </a>
    <a class="clickable-a-link social-btn-margin" href="https://t.me/onestar2dgameservice1" target="_blank">
        <img src="../../../assets/imgs/contact_us/35x35-telegram.png" alt="telegram" class="social-icon-img">
    </a>
    <a class="clickable-a-link social-btn-margin" href="https://youtube.com/@onestar2d3dgaming" target="_blank">
        <img src="../../../assets/imgs/contact_us/utube.png" alt="youtube" class="social-icon-img">
    </a>
</div>