<div id="splash-img-container">
    <ng-container *ngIf="isShow">
        <!-- <button class="btn btn-primary splash-skip-btn"
        (click)="onSkipSplashScreen()">
            Skip 
            <span id="countdown">
                <countdown #cd [config]="countdownConfig"></countdown>
            </span>
        </button> -->
        <label class="skip-splash-screen-label" (click)="onSkipSplashScreen()">
            Skip 
            <span id="countdown">
                <countdown #cd [config]="countdownConfig"></countdown>
            </span>
        </label>

        <ng-container *ngIf="splashImgs.length > 1;else ShowOnlyOne">
            <keen-slider [images]="splashImgs" [targetName]="'splashscreen'"></keen-slider>
        </ng-container>
        <ng-template #ShowOnlyOne>
            <ng-container *ngIf="splashImgs.length>0">
                <img 
                [src]="splashImgs[0].link"
                alt="splash-img" 
                width="100%" height="100%">
            </ng-container>
        </ng-template>
    </ng-container>
    
</div>