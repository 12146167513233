import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class GamesService {
    private SWNW_URL = `${environment.API_ENDPOINT}/${environment.MOBILE_PATH}`;
    private DEFAULT_URL = `${environment.API_ENDPOINT}/${environment.DEFAULT_PATH}`;

    constructor(private http: HttpClient) {}
    
    getBetCloseTime(payload:any):Observable<any>{//37
        return this.http.post(`${this.SWNW_URL}/getbetclosetime`,payload);
    }
    getLatestLotteryResult(payload:any):Observable<any>{//26
        return this.http.post(`${this.DEFAULT_URL}/lottery/latestlotteryresult`,payload);
    }
    getCurrent2D():Observable<any>{//16
        return this.http.post(`${this.DEFAULT_URL}/lottery/getcurrenttwod`,null);
    }
    
    getClosedBetNumber(payload:any):Observable<any>{//27
        return this.http.post(`${this.DEFAULT_URL}/lottery/getclosedbetnumber`,payload);
    }
    getCurrentBetNumbersStatus(payload:any):Observable<any>{//36
        return this.http.post(`${this.SWNW_URL}/getcurrentbetnumberstatus`,payload);
    }
    betting(payload:any):Observable<any>{//21
        return this.http.post(`${this.SWNW_URL}/betting`,payload);
    }
    
    get2DResultHistory():Observable<any>{//19
        return this.http.post(`${this.DEFAULT_URL}/lottery/twodresulthistoryformobile`,null);
    }
    get2DInvoiceHistory(payload?:any):Observable<any>{//22
        return this.http.post(`${this.SWNW_URL}/twodinvoicehistory`,payload?payload:null);
    }
    get2DWinnerList():Observable<any>{//24
        return this.http.post(`${this.DEFAULT_URL}/lottery/twodwinnerlist`,null);
    }
    get3DResultHistory():Observable<any>{//18
        return this.http.post(`${this.DEFAULT_URL}/lottery/threedresulthistory`,null);
    }
    get3DInvoiceHistory(payload?:any):Observable<any>{//23
        return this.http.post(`${this.SWNW_URL}/threedinvoicehistory`,payload?payload:null);
    }
    get3DWinnerList():Observable<any>{//25
        return this.http.post(`${this.DEFAULT_URL}/lottery/threedwinnerlist`,null);
    }
    get3DWinningNums():Observable<any>{//17
        return this.http.post(`${this.DEFAULT_URL}/lottery/getthreedwinningnumber`,null);
    }

    //## 3Rd Party Games APIs
    getMainGameList():Observable<any>{//1
        return this.http.post(`${this.SWNW_URL}/maingamelist`,null);
    }
    getGSCChildGameList(payload:any):Observable<any>{//5
        return this.http.post(`${this.SWNW_URL}/gscchildgamelists`,payload);
    }
    getExGSCChildGameList(payload:any):Observable<any>{//5
        return this.http.post(`${this.SWNW_URL}/externalgscchildgamelists`,payload);
    }
    getGSCLaunchGame(payload:any):Observable<any>{//2
        return this.http.post(`${this.SWNW_URL}/gsclaunchgame`,payload);
    }
    gscGameExits(payload:any):Observable<any>{//3
        return this.http.post(`${this.SWNW_URL}/gscgameexits`,payload);
    }
    checkUserHasOpenedGameSession():Observable<any>{//4
        return this.http.post(`${this.SWNW_URL}/checkuserhasopenedgamesession`,null);
    }
    getHomeImages(payload:any):Observable<any>{//67
        return this.http.post(`${this.SWNW_URL}/gethomeimages`, payload);
    }

    collectGameMoneyOnMaintain(payload:any):Observable<any>{
        return this.http.post(`${this.SWNW_URL}/collectcash`, payload);
    }
}