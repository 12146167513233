import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import {
  DialogService,
  AuthUtilsService,
  SessionStorageService,
  CryptoJsService,
  GFLanguageService,
  SpinnerService, 
  LocalStorageService,
  DeviceInfoDetectorService,
  UIHandlerService,
} from 'src/app/@core/utils';

import PagesRoutingNames from 'src/app/@core/helper/pages-routing-names.helper';

import { AuthService } from '../@core/services';

import { CountdownComponent } from 'ngx-countdown';

import { ProfileService } from '../@core/services';

@Component({
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
})
export class VerifyOTPComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cd', { static: false }) countdown: CountdownComponent|any;
  
  countdownConfig = { leftTime: 300, format: 'mm:ss' }; // for one minute.
  gotCodes: any;
  viaTargetRoute: any;
  confirmationResult:any;
  phoneNumber:string|any;
  selectedLanguage:string|any;
  pinInputModal:any;
  deviceInnerWidth:number|any;

  isLookPin = false;
  isPinInputDialogShowed:boolean|any;

  toid1:any;
  toid2:any;
  toid3:any;
  toid4:any;
  toid5:any;
  toid6:any;
  toid7:any;
  toid8:any;
  toid9:any;
  toid10:any;
  toid11:any;

  titleDescription:string|any;

  constructor(
    private router: Router,
    private cryptoService: CryptoJsService,
    private dialogService: DialogService,
    private sessionService: SessionStorageService,
    private authUtilsService: AuthUtilsService, 
    private gflangService:GFLanguageService,
    private authService:AuthService,
    private spinnerService:SpinnerService,
    private localService:LocalStorageService,
    private profileService:ProfileService,
    private deviceInfoService: DeviceInfoDetectorService,
    private uiHandlerService:UIHandlerService,
  ) {
    this.gotCodes = '';

    this.selectedLanguage = this.gflangService.getSelectedLanguage();
    this.deviceInnerWidth = window.innerWidth;
    this.isPinInputDialogShowed = false;
  }

  ngOnInit(): void {
    const _t = this.sessionService.getVerifyOTPVia();
    
    if (_t) {
      this.viaTargetRoute = this.cryptoService.decrypt(_t);

      this.clearTempSessionStoredData();

      const ui = this.authUtilsService.getLocalUserInfo();
      const { phonenumber } = ui;
      this.phoneNumber = phonenumber;
    } else {
      this.phoneNumber = this.getPhoneNum();
    }
  }

  ngAfterViewInit(): void {
    this.setFocusFirstInputElement();

    if(!this.viaTargetRoute){
      this.countdown.begin();

      this.toid1 = setTimeout(() => {
        this.countdown.stop();
      }, 300000);//5mins!!
      //1000ms=1sec
      //60sec=1min
      //1000*60*5

      this.titleDescription = 'verify-otp-title';
    } else this.titleDescription = 'verify-pin-title';

    this.pinInputModal = <HTMLElement>document.querySelector('#pinInputModal');

    this.toid10 = setTimeout(() => {
      this.uiHandlerService.calMainBodyHeightWithBackHeader();
    }, 100);
  }

  ngOnDestroy(): void {
    if(this.toid1) { clearTimeout(this.toid1)}
    if(this.toid2) { clearTimeout(this.toid2)}
    if(this.toid3) { clearTimeout(this.toid3)}
    if(this.toid4) { clearTimeout(this.toid4)}
    if(this.toid5) { clearTimeout(this.toid5)}
    if(this.toid6) { clearTimeout(this.toid6)}
    if(this.toid7) { clearTimeout(this.toid7)}
    if(this.toid8) { clearTimeout(this.toid8)}
    if(this.toid9) { clearTimeout(this.toid9)}
    if(this.toid10) { clearTimeout(this.toid10)}
    if(this.toid11) { clearTimeout(this.toid11)}
  }

  clearTempSessionStoredData() {
    this.sessionService.clearRegisterData();
    this.sessionService.clearLinData();
    this.sessionService.clearPhoneNumForForgetPin();
  }

  onContinue() {
    if(this.gotCodes && this.gotCodes.length===6) {
      if(this.phoneNumber) {
        const _regData = this.sessionService.getRegisterData();
        if(_regData){//** come from Register process! */
          const { username, invitationCode } = JSON.parse(this.cryptoService.decrypt(_regData));
          
          const pl = new URLSearchParams();
          pl.append('phonenumber', this.phoneNumber);
          pl.append('OTP', this.gotCodes);
          pl.append('username', username);
          pl.append('language', this.selectedLanguage);
          pl.append('deviceInfo', JSON.stringify(this.deviceInfoService.getDeviceInfo()))

          if(invitationCode){
            pl.append('invitation_link', invitationCode);
          }

          this.spinnerService.loading.next(true);

          this.authService.verifyAndRegister(pl).subscribe({
            next:(v)=>{
              this.spinnerService.loading.next(false);
              
              if(v && v.data && v.status==='200'){
                this.localService.saveToken(v.data.accesstoken);

                this.dialogService.showSuccess(`${v.message}`,2500);

                this.toid2 = setTimeout(() => {
                  this.goDefinePin();
                }, 2550);

              }else{
                this.authUtilsService.swnwErrorHandler(v);
              }
              
            },
            error:(e)=>{
              this.spinnerService.loading.next(false);
              this.dialogService.showError(e.error);
            }
          });

        } else if(this.sessionService.getLinData()){//** come from Login process! */
          const pl = new URLSearchParams();
          pl.append('language', this.selectedLanguage);
          pl.append('phonenumber', this.phoneNumber);
          pl.append('OTP', this.gotCodes);
          pl.append('deviceInfo', JSON.stringify(this.deviceInfoService.getDeviceInfo()));

          this.spinnerService.loading.next(true);

          this.authService.verifyForLogin(pl).subscribe({
            next:(v)=>{
              this.spinnerService.loading.next(false);
              
              if(v.status==='200'){
                this.resetPinInputs();
                this.countdown.stop();
                
                this.pinInputModal.style.display = 'block';

                this.toid11 = setTimeout(() => {
                  this.uiHandlerService.calHeightOfPinLookToggleContainer();
                }, 0);

                this.toid3 = setTimeout(() => {
                  this.focusOnPinInput();
                }, 2);

                this.isPinInputDialogShowed = true;
              }else{
                this.authUtilsService.swnwErrorHandler(v);
              }
            },
            error:(e)=>{
              this.spinnerService.loading.next(false);
              this.dialogService.showError(e.error);
            }
          });

        } else if (this.viaTargetRoute && this.viaTargetRoute.includes('withdraw')) {//** come from Withdraw process! */
          const pl = new URLSearchParams();
          pl.append('phonenumber', this.phoneNumber);
          pl.append('deviceInfo', JSON.stringify(this.deviceInfoService.getDeviceInfo()));
          pl.append('password', this.gotCodes);
          pl.append('language', this.selectedLanguage);
          
          this.spinnerService.loading.next(true);

          this.authService.verifyPINForWithdraw(pl).subscribe({
            next:(v)=>{
              this.spinnerService.loading.next(false);
              if(v.status === '200'){
                this.dialogService.showSuccess(`pin-verify-success-lbl`, 1250);

                this.toid4 = setTimeout(() => {
                  this.sessionService.clearVerifyOTPVia();

                  this.sessionService.saveVerifyOTPResultForWithdraw(
                    this.cryptoService.encrypt('1')
                  );

                  this.goViaTarget();

                }, 1700);
              }else if(v.status === '5015'){
                this.authUtilsService.swnwErrorHandler(v);
              }else{
                this.dialogService.showError({message: 'wrong-pin-lbl'});
              }
            },
            error:(e)=>{
              this.spinnerService.loading.next(false);
              this.dialogService.showError(e.error);
            }
          });

        } else if(this.sessionService.getForgetPinStatus() && this.sessionService.getForgetPinStatus()==='1'){//** come from Forget-Pin process! */
          //call verify-forgetPin api
          const pl = new URLSearchParams();
          pl.append('phonenumber', this.phoneNumber);
          pl.append('OTP', this.gotCodes);
          pl.append('language', this.selectedLanguage);
          pl.append('deviceInfo', JSON.stringify(this.deviceInfoService.getDeviceInfo()));

          this.spinnerService.loading.next(true);

          this.authService.verifyOtpForgetPin(pl).subscribe({
            next:(v)=>{
              this.spinnerService.loading.next(false);

              if(v && v.status==='200'){
                this.localService.saveToken(v.data.accesstoken);

                this.dialogService.showSuccess(`${v.message}`,2500);

                this.toid5 = setTimeout(() => {
                  this.goDefinePin();
                }, 2550);

              }else{
                this.authUtilsService.swnwErrorHandler(v);
              }
              
            },
            error:(e)=>{
              this.spinnerService.loading.next(false);
              this.dialogService.showError(e.error);
            },
          });
        }
      } else {
        this.dialogService.showError({message: 'ph-num-require-login-lbl'}, 3000);
      }
      
    } else {
      if (this.viaTargetRoute && this.viaTargetRoute.includes('withdraw')) {
        this.dialogService.showError({message: 'check-pin-again-lbl'});
      } else {
        this.dialogService.showError({message: 'check-otp-again-lbl'});
      }
    }
    
  }

  onOKPinInput(){
    const pin = (<HTMLElement|any>document.querySelector('#input-password')).value;
    
    if(pin.trim()){
      //call api 1. login()->save taken -> go-home!
      const payload = new URLSearchParams();
      payload.append('phonenumber',this.phoneNumber);
      payload.append('password',pin);
      payload.append('deviceInfo', JSON.stringify(this.deviceInfoService.getDeviceInfo()));

      const loginData = JSON.parse(this.cryptoService.decrypt(this.sessionService.getLinData()));
      
      payload.append('lat',`${loginData.lat ?? ''}`);
      payload.append('long',`${loginData.long ?? ''}`);

      this.authService.login(payload).subscribe({
        next:(resp1)=>{
          if(resp1 && resp1.status==='200'){
            this.localService.saveToken(resp1.data);
            
            this.getUserProfileInfo()
            .then(resp2 => {
                if(resp2 && resp2.status==='200'){

                  this.pinInputModal.style.display = 'none';

                    if(resp2.data){
                        this.localService.saveUserProfileInfo(
                            this.cryptoService.encrypt(JSON.stringify(resp2.data))
                        );

                        this.resetPinInputs();

                        this.toid6 = setTimeout(() => {
                          this.goHome();
                        }, 5);
                        
                    }
                }else{
                    this.authUtilsService.swnwErrorHandler(resp2);
                }
            })
            .catch(err2 => {
                this.authUtilsService.swnwErrorHandler(err2.error);
            });
          }else{
              this.authUtilsService.swnwErrorHandler(resp1);
          }

          this.sessionService.clearLinData();
        },
        error:(err)=>{
          this.dialogService.showError(err.error);

          this.sessionService.clearLinData();
        }
      });

    }else{
      this.dialogService.showWarning('please-enter-pin-lbl', 3500);

      this.toid7 = setTimeout(() => {
        const pinInputEl = (<HTMLElement|any>document.querySelector('#input-password'));
        pinInputEl.value = null;
        pinInputEl.focus();
      }, 3501);
    }
  }

  onCancelPinInput(){
    this.pinInputModal.style.display = 'none';
  }

  onKeyUp(evt: any) {
    ////console.log('#onKeyUp(evt: any)/ evt.target.value', evt.target.value);

    let idNum = +evt.target.id.split('-')[2];

    if (!(evt.keyCode === 8 || evt.keyCode === 37)) {
      if (idNum < 6) {
        (<HTMLElement>document.getElementById(`pin-num-${++idNum}`)).focus();
      }

      
    }

    this.gotCodes = this.getInputedCodes();
  }

  onResendCode() {
    this.countdown.restart();
    this.toid8 = setTimeout(() => {
      this.countdown.stop();
    }, 300000);//5mins!!
    //1000ms=1sec
    //60sec=1min
    //1000*60*5

    this.resetPinInputs();
    this.setFocusFirstInputElement();

    this.sendOTP(this.phoneNumber);
  }

  getPhoneNum():string{
    const regData = this.sessionService.getRegisterData();
    
    if (regData) {
      const { phonenumber } = JSON.parse(this.cryptoService.decrypt(regData));
      return phonenumber;

    }else{
      const _linData = this.sessionService.getLinData();
      if(_linData){
        const {phonenumber} = JSON.parse(this.cryptoService.decrypt(_linData));
        return phonenumber;

      }else if(this.sessionService.getPhoneNumForForgetPin()) {
        const t = this.sessionService.getPhoneNumForForgetPin();
        
        const ph = this.cryptoService.decrypt(t);
        return ph;
  
      }else{
        const ui = this.authUtilsService.getLocalUserInfo();
        if(ui){
          const { phonenumber } = ui;
          return phonenumber;
        }
        return '';
      }
    }
  }

  getInputedCodes() {
    const c1 = (<any>document.getElementById('pin-num-1'))?.value;
    const c2 = (<any>document.getElementById('pin-num-2'))?.value;
    const c3 = (<any>document.getElementById('pin-num-3'))?.value;
    const c4 = (<any>document.getElementById('pin-num-4'))?.value;
    const c5 = (<any>document.getElementById('pin-num-5'))?.value;
    const c6 = (<any>document.getElementById('pin-num-6'))?.value;

    if (c1 && c2 && c3 && c4 && c5 && c6) {
      return `${c1}${c2}${c3}${c4}${c5}${c6}`;
    }
    return '';
  }

  goDefinePin() {
    this.router.navigate([
      `/${PagesRoutingNames.MY_AUTH}/${PagesRoutingNames.DEFINE_PIN}/`,
    ]);
  }

  resetPinInputs() {
    (<any>document.getElementById('pin-num-1')).value = null;
    (<any>document.getElementById('pin-num-2')).value = null;
    (<any>document.getElementById('pin-num-3')).value = null;
    (<any>document.getElementById('pin-num-4')).value = null;
    (<any>document.getElementById('pin-num-5')).value = null;
    (<any>document.getElementById('pin-num-6')).value = null;
  }

  setFocusFirstInputElement() {
    this.toid9 = setTimeout(() => {
      const el = <any>document.getElementById('pin-num-1');
      el.focus();
    }, 900);
  }

  goViaTarget() {
    this.router.navigate([this.viaTargetRoute]);
  }

  sendOTP(phonenumber:string){
    if (phonenumber) {
      const pl = new URLSearchParams();
      pl.append('phonenumber', phonenumber);
      pl.append('language', this.selectedLanguage);
      pl.append('deviceInfo', JSON.stringify(this.deviceInfoService.getDeviceInfo()));
      pl.append('smspoh', 'true');

      this.spinnerService.loading.next(true);

      this.authService.sendOtp(pl).subscribe({
        next: (v) => {
          this.spinnerService.loading.next(false);
          
          if (v && v.status === '200') {
            this.dialogService.showInfo(`${v.message}`, 3000);
          } else {
            this.authUtilsService.swnwErrorHandler(v);
          }
        },
        error: (e) => {
          this.spinnerService.loading.next(false);
          this.dialogService.showError(e.error);
        },
      });
      
    } else {
      this.dialogService.showError({
        message: `ph-not-found-join-us-lbl`,
      });
    }
  }

  goHome(){
    this.router.navigate([`/${PagesRoutingNames.PAGES}/${PagesRoutingNames.HOME}`]);
  }

  getUserProfileInfo():Promise<any>{
    return new Promise((resolve, reject)=>{
        this.profileService.getUserInfo().subscribe({
            next:(v)=>{
                resolve(v);
            },
            error:(e)=>{
                reject(e)}
        });
    });
  }
  
  pinLookToggle(){
    this.isLookPin = !this.isLookPin;

    const pinInput = (<HTMLElement>document.querySelector('#input-password'));
    pinInput.removeAttribute('type');

    pinInput && this.isLookPin ? 
    pinInput.setAttribute('type','text') : pinInput.setAttribute('type','password');
  }

  onKeyPress(evt:any){
    if (evt.target.value && evt.target.value.length > 0) {
      evt.preventDefault();
      
    } else if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57){
        evt.preventDefault();
    }
  }

  onKeyPressInputPINDialog(evt:any){
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57){
        evt.preventDefault();
    }
  }

  focusOnPinInput(){
    const piel = <HTMLElement>document.querySelector('#input-password');
    if(piel) piel.focus();
  }
}
