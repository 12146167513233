import { 
    Component, 
    Input, 
    Output, 
    EventEmitter, 
    AfterViewInit, 
    OnChanges, 
    SimpleChanges, 
    OnDestroy , 
} from "@angular/core";

@Component({
    selector:'swnw-dropdown',
    templateUrl:'./dropdown.component.html',
    styleUrls:['./dropdown.component.scss']
})
export class SwnwDropDown implements OnChanges, AfterViewInit, OnDestroy {
    @Input() list:Array<any>|any;
    @Input() id:any;
    @Input() name:any;
    @Input() selectedValue:any;

    @Output() onSelected:EventEmitter<any>=new EventEmitter();

    toid1:any;

    ngOnChanges(changes: SimpleChanges): void {
        if(this.selectedValue){
            this.toid1 = setTimeout(() => {
                const el:any=document.getElementById(this.id);
                el.value = this.selectedValue;  
            }, 400);
        }
    }

    ngAfterViewInit(): void {
        if(this.selectedValue){
            const el:any=document.getElementById(this.id);
            el.value = this.selectedValue;
        }
    }

    ngOnDestroy(): void {
        if(this.toid1) { clearTimeout(this.toid1)}
      }

    onSelectOption(data:any){
        this.onSelected.emit(data.target.value);
    }
}